import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { isEqual } from 'lodash-es';

import { DataProvider, Signal } from '../../providers/data-provider';
import { SignalGroupProvider } from '../../providers/signal-group-provider';

@Component({
    templateUrl: 'add-signal.component.html',
})
export class AddSignalComponent {
    private savedSignals: [string, Signal][] = [];

    constructor(
        private dataProvider: DataProvider,
        private popoverCtrlr: PopoverController,
        private signalGroupProvider: SignalGroupProvider,
    ) { }

    get signals() {
        if (!this.dataProvider.data) {
            console.error('Could not get data from provider');
            return;
        }
        const currentSignals = Object.entries(this.dataProvider.data.signals);
        if (!isEqual(this.savedSignals, currentSignals)) {
            this.savedSignals = currentSignals;
        }
        return this.savedSignals;
    }

    async addSignal(signal: [string, Signal]) {
        const newLayout = this.signalGroupProvider.layout.slice();
        newLayout.unshift({
            type: 'signal',
            key: signal[0],
            name: signal[1].name,
            slot: signal[1].slot,
        });
        this.signalGroupProvider.layout = newLayout;
        await this.popoverCtrlr.dismiss();
    }
}
