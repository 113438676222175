import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import {
    PreloadAllModules,
    RouterModule,
    Routes,
    RouteReuseStrategy,
} from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { ResizableModule } from "angular-resizable-element";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";

import { AppComponent } from "./app.component";
import { ConsoleFileLogger } from "../hgm-setup/src/module/util/console-file-logger";
import { DisplaySlotLocalizer } from "../hgm-setup/src/module/param/services/display-slot-localizer";
import { LOGGER } from "../hgm-setup/src/module/util/logger";
import { ParamDefDisplaySlotLocalizer } from "../hgm-setup/src/module/param/services/param-def-display-slot-localizer";
import { ParamAddrCalculator } from "../hgm-setup/src/module/param/services/param-registry";
import { AppParamAddrCalculator } from "../providers/param-addr-calculator";
import { SignalChartComponent } from "./tab-charts/signal-chart.component";
import { TabChartsPageComponent } from "./tab-charts/tab-charts.page";
import { TabConfigurationsPageComponent } from "./tab-configurations/tab-configurations.page";
import { ConfigurationSaveComponent } from "./tab-configurations/configuration-save.component";
import { TabsPageComponent } from "./tabs/tabs.page";
import { TabSignalsPageComponent } from "./tab-signals/tab-signals.page";
import { TabSettingsPageComponent } from "./tab-settings/tab-settings.page";
import { CategorySelectModalComponent } from "../hgm-setup/src/module/components/entry/category-select-modal";
import { HgmSelectComponent } from "../hgm-setup/src/module/components/core/hgm-select";
import { UnitSelectorComponent } from "../hgm-setup/src/module/param/components/unit-selector";
import { AddSignalComponent } from "./tab-signals/add-signal.component";
import { EditSignalComponent } from "./tab-signals/edit-signal.component";
import { ViewfinderChartComponent } from "./tab-charts/viewfinder-chart.component";
import { LineSampleComponent } from "./line-sample.component";

const routes: Routes = [
    {
        path: "tabs",
        component: TabsPageComponent,
    },
    {
        path: "",
        redirectTo: "/tabs",
        pathMatch: "full",
    },
];

@NgModule({
    declarations: [
        AppComponent,
        AddSignalComponent,
        EditSignalComponent,
        CategorySelectModalComponent,
        HgmSelectComponent,
        LineSampleComponent,
        SignalChartComponent,
        TabChartsPageComponent,
        TabConfigurationsPageComponent,
        ConfigurationSaveComponent,
        TabSettingsPageComponent,
        TabSignalsPageComponent,
        TabsPageComponent,
        UnitSelectorComponent,
        ViewfinderChartComponent,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        IonicModule.forRoot(),
        ResizableModule,
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: environment.production,
        }),
    ],
    providers: [
        { provide: ParamAddrCalculator, useClass: AppParamAddrCalculator },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: DisplaySlotLocalizer,
            useClass: ParamDefDisplaySlotLocalizer,
        },
        {
            provide: LOGGER,
            useFactory: (cfl: ConsoleFileLogger) => cfl.logger,
            deps: [ConsoleFileLogger],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
