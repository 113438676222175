import { Injectable } from '@angular/core';

export type TranslationType = 'controlLabel' | 'encodingValue' | 'traceLabel';

export interface Translator {
    translate: (str: string, type: TranslationType) => string;
    cislate: (str: string, type: TranslationType) => string;
}

@Injectable({ providedIn: 'root' })
export class TranslationManager {
    private translators = new Array<Translator>();

    add(translator: Translator) {
        this.translators.push(translator);
    }

    translate(str: string | undefined, type: TranslationType) {
        let out = str || '';
        for (const translator of this.translators) {
            out = translator.translate(out, type);
        }
        return out;
    }

    cislate(str: string, type: TranslationType) {
        let out = str;
        for (const translator of this.translators) {
            out = translator.cislate(out, type);
        }
        return out;
    }
}
