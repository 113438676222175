import { Observable } from 'rxjs';

export const encodeText = () => (source: Observable<string>) =>
    new Observable<Uint8Array>((observer) => {
        const encoder = new TextEncoder();
        return source.subscribe(
            (value) => {
                observer.next(encoder.encode(value));
            },
            (err: any) => {
                observer.error(err);
            },
            () => {
                observer.complete();
            },
        );
    });

export const decodeText = (label?: string, options?: TextDecoderOptions) => (source: Observable<BufferSource>) =>
    new Observable<string>((observer) => {
        const decoder = new TextDecoder(label, options);
        return source.subscribe(
            (value) => {
                observer.next(decoder.decode(value, { stream: true }));
            },
            (err: any) => {
                observer.error(err);
            },
            () => {
                const finalOutput = decoder.decode(new Uint8Array(), { stream: false });
                if (finalOutput) {
                    observer.next(finalOutput);
                }
                observer.complete();
            },
        );
    });

export const splitLines = () => (source: Observable<string>) =>
    new Observable<string>((observer) => {
        let text = '';
        const re = /\r\n|\n|\r/gm;
        return source.subscribe(
            (value) => {
                text = text + value;
                for (let startIndex = 0; ; ) {
                    const result = re.exec(text);
                    if (result) {
                        observer.next(text.substring(startIndex, result.index));
                        startIndex = re.lastIndex;
                    } else {
                        text = text.substring(startIndex);
                        startIndex = 0;
                        re.lastIndex = 0;
                        break;
                    }
                }
            },
            (err: any) => {
                if (text) {
                    observer.next(text); // emit the last line if file didn't end with newline
                }
                observer.error(err);
            },
            () => {
                if (text) {
                    observer.next(text); // emit the last line if file didn't end with newline
                }
                observer.complete();
            },
        );
    });
