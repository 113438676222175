import { Injectable } from "@angular/core";
import { sum } from "lodash-es";
import { BehaviorSubject } from "rxjs";
import { arrayMax, arrayMin, Crc32 } from "../hgm-setup/src/module/util/core";
import { DataProvider, DataSet } from "./data-provider";

export interface TraceStyle {
    color: number;
    dash: number;
}

export interface TraceRange {
    minY: number | undefined;
    maxY: number | undefined;
    dedicatedY: boolean;
}

export const defaultTraceRange: TraceRange = {
    minY: undefined,
    maxY: undefined,
    dedicatedY: false,
};

export const signalColors = [
    "#e6194b",
    "#3cb44b",
    "#ffe119",
    "#4363d8",
    "#f58231",
    "#42d4f4",
    "#f032e6",
    "#fabed4",
    "#469990",
    "#dcbeff",
    "#9a6324",
    "#fffac8",
    "#800000",
    "#aaffc3",
    "#000075",
    "#a9a9a9",
];

export const signalDashes: number[][] = [
    [],
    [3, 3],
    [10, 10],
    [20, 5],
    [15, 3, 3, 3],
    [20, 3, 3, 3, 3, 3, 3, 3],
    [12, 3, 3, 12, 3, 3],
];

const styleToIndex = (style: TraceStyle | undefined) =>
    style ? style.color * signalDashes.length + style.dash : undefined;

const indexToStyle = (index: number | undefined) => {
    if (index === undefined) {
        return undefined;
    }
    return {
        color: Math.floor(index / signalDashes.length),
        dash: index % signalDashes.length,
    };
};

export const maxSignalDashLength = arrayMax(signalDashes.map((d) => sum(d)));

const stylesStorageKey = "signalStyles";
const rangesStorageKey = "signalRanges";

const defaultStyles: { [key: string]: TraceStyle } = {
    engineSpeed: { color: 0, dash: 0 },
    transmissionInputShaftSpeed: { color: 1, dash: 0 },
    vehicleSpeed: { color: 2, dash: 0 },
    transmissionOutputShaftSpeed: { color: 3, dash: 0 },
    throttlePosition: { color: 4, dash: 0 },
    engineActualPercentTorque: { color: 4, dash: 1 },
    driversDemandPercentTorque: { color: 4, dash: 1 },
    shiftCurrentGear: { color: 6, dash: 0 },
    shiftSelectedGear: { color: 7, dash: 0 },
    transmissionMainLinePressure: { color: 8, dash: 0 },
    dtcActiveCount: { color: 9, dash: 0 },
    shiftControlMode: { color: 10, dash: 0 },
    tccCurrentState: { color: 11, dash: 0 },
    transmissionTemperature: { color: 12, dash: 0 },
    controllerVoltage: { color: 13, dash: 0 },
    transmissionSlip: { color: 14, dash: 0 },
};
const defaultRanges: { [key: string]: TraceRange } = {};

const loadJson = (
    key: string,
    defaultValue: { [key: string]: any }
): { [key: string]: any } => {
    const stored = window.localStorage.getItem(key);
    if (stored) {
        try {
            const value = JSON.parse(stored);
            if (typeof value === "object") {
                return value;
            }
        } catch {}
    }
    return defaultValue;
};

export interface StyleMap {
    [key: string]: TraceStyle;
}
export interface RangeMap {
    [key: string]: TraceRange;
}

@Injectable({ providedIn: "root" })
export class SignalSettingsProvider {
    private stylesSubj = new BehaviorSubject<StyleMap>({});
    private rangesSubj = new BehaviorSubject<RangeMap>({});
    private styledDataSubj = new BehaviorSubject<
        { data: DataSet; styles: StyleMap } | undefined
    >(undefined);

    constructor(private dataProvider: DataProvider) {
        this.stylesSubj.next(loadJson(stylesStorageKey, defaultStyles));
        this.rangesSubj.next(loadJson(rangesStorageKey, defaultRanges));
        this.stylesSubj.subscribe({
            next: (styles) =>
                window.localStorage.setItem(
                    stylesStorageKey,
                    JSON.stringify(styles)
                ),
        });
        this.rangesSubj.subscribe({
            next: (ranges) =>
                window.localStorage.setItem(
                    rangesStorageKey,
                    JSON.stringify(ranges)
                ),
        });

        dataProvider.dataObs.subscribe({ next: this.updateStyles });
        this.stylesSubj.subscribe({ next: this.updateStyles });
    }

    get computedStyles() {
        return this.styledDataSubj.value?.styles || {};
    }

    get styledData() {
        return this.styledDataSubj.value;
    }

    /// Emits after DataProvider has released its data and we have calculated styles
    get styledDataObs() {
        return this.styledDataSubj.asObservable();
    }

    get styles() {
        return this.stylesSubj.value;
    }

    get ranges() {
        return this.rangesSubj.value;
    }

    get rangesObs() {
        return this.rangesSubj.asObservable();
    }

    setStyle(key: string, style: Partial<TraceStyle>) {
        const styles = { ...this.stylesSubj.value };
        styles[key] = {
            color: 0,
            dash: 0,
            ...(styles[key] as TraceStyle | undefined),
            ...style,
        };
        this.stylesSubj.next(styles);
    }

    clearStyle(key: string) {
        const styles = { ...this.stylesSubj.value };
        styles[key] = defaultStyles[key];
        this.stylesSubj.next(styles);
    }

    clearStyles() {
        this.stylesSubj.next({});
    }

    setRange(key: string, range: Partial<TraceRange>) {
        const ranges = { ...this.rangesSubj.value };
        ranges[key] = {
            minY: undefined,
            maxY: undefined,
            dedicatedY: false,
            ...(ranges[key] as TraceRange | undefined),
            ...range,
        };
        this.rangesSubj.next(ranges);
    }

    clearRange(key: string) {
        const ranges = { ...this.rangesSubj.value };
        delete ranges[key];
        this.rangesSubj.next(ranges);
    }

    clearRanges() {
        this.rangesSubj.next({});
    }

    private updateStyles = () => {
        const data = this.dataProvider.data;
        if (!data) {
            this.styledDataSubj.next(undefined);
            return;
        }

        // Start with predefined mappings
        const signals = Object.keys(data.signals).map((key) => ({
            key,
            hash: Crc32.calc(key),
            index: styleToIndex(this.stylesSubj.value[key]),
        }));

        // Sort by hash so we get the same result no matter the ordering of the input
        signals.sort((a, b) => a.hash - b.hash);

        const counts = new Array<number>(
            signalColors.length * signalDashes.length
        ).fill(0);
        for (const signal of signals) {
            if (signal.index !== undefined) {
                ++counts[signal.index];
            }
        }

        const styles: StyleMap = {};
        for (const signal of signals) {
            if (signal.index === undefined) {
                // First try hash-based color and first line style; if less-used options are available, pick the first
                const initIndex =
                    (signal.hash % signalColors.length) * signalDashes.length;
                const minCount = arrayMin(counts);
                const minIndex = counts.slice(initIndex).indexOf(minCount);
                if (minIndex >= 0) {
                    signal.index = minIndex + initIndex;
                } else {
                    signal.index = counts.slice(0, initIndex).indexOf(minCount);
                }
                ++counts[signal.index];
            }
            styles[signal.key] = indexToStyle(signal.index)!;
        }

        this.styledDataSubj.next({ data, styles });
    };
}
