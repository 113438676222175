import { BehaviorSubject, Observable, Subject } from 'rxjs';

export enum DataType {
    uint8 = 0,
    int8,
    uint16,
    int16,
    uint32,
    int32,
    float32,
    float64,
}

export const getDataTypeLength = (type: DataType): number => {
    switch (type) {
        case DataType.uint8:
        case DataType.int8:
            return 1;
        case DataType.uint16:
        case DataType.int16:
            return 2;
        case DataType.uint32:
        case DataType.int32:
        case DataType.float32:
            return 4;
        case DataType.float64:
            return 8;
    }
};

export const getTypedViewData = (dataView: DataView, type: DataType, littleEndian: boolean, offset: number): number => {
    switch (type) {
        case DataType.uint8:
            return dataView.getUint8(offset);
        case DataType.int8:
            return dataView.getInt8(offset);
        case DataType.uint16:
            return dataView.getUint16(offset * 2, littleEndian);
        case DataType.int16:
            return dataView.getInt16(offset * 2, littleEndian);
        case DataType.uint32:
            return dataView.getUint32(offset * 4, littleEndian);
        case DataType.int32:
            return dataView.getInt32(offset * 4, littleEndian);
        case DataType.float32:
            return dataView.getFloat32(offset * 4, littleEndian);
        case DataType.float64:
            return dataView.getFloat64(offset * 8, littleEndian);
    }
};

export const setTypedViewData = (dataView: DataView, type: DataType, littleEndian: boolean, offset: number, value: number) => {
    switch (type) {
        case DataType.uint8:
            return dataView.setUint8(offset, value);
        case DataType.int8:
            return dataView.setInt8(offset, value);
        case DataType.uint16:
            return dataView.setUint16(offset * 2, value, littleEndian);
        case DataType.int16:
            return dataView.setInt16(offset * 2, value, littleEndian);
        case DataType.uint32:
            return dataView.setUint32(offset * 4, value, littleEndian);
        case DataType.int32:
            return dataView.setInt32(offset * 4, value, littleEndian);
        case DataType.float32:
            return dataView.setFloat32(offset * 4, value, littleEndian);
        case DataType.float64:
            return dataView.setFloat64(offset * 8, value, littleEndian);
    }
};

export abstract class Slot {
    static isNumber(value: number | undefined): value is number {
        return !(typeof value === 'undefined' || isNaN(value));
    }
    static isNonEmptyString(value: string | undefined): value is string {
        return !(typeof value === 'undefined' || value.length > 0);
    }

    abstract toEngrFloat: (value: number | undefined) => number | undefined;
    abstract toEngrString: (value: number | undefined) => string | undefined;
    abstract toRawFloat: (value: number | string | undefined) => number | undefined;
    abstract rawInRange: (value: number | undefined) => boolean;
    abstract engrInRange: (value: number | string | undefined) => boolean;
    abstract rawMin: number;
    abstract rawMax: number;
    abstract rawStep: number;
    abstract engrMin: number;
    abstract engrMax: number;
    abstract engrStep: number;
    abstract rawIsInt: boolean;

    private unitSubject: BehaviorSubject<string>;
    private precisionSubject: BehaviorSubject<number>;
    private baseSubject: BehaviorSubject<number>;
    protected valueParamSubject = new Subject<void>();

    constructor(unit: string, precision: number, base: number = 10) {
        this.unitSubject = new BehaviorSubject<string>(unit);
        this.precisionSubject = new BehaviorSubject<number>(precision);
        this.baseSubject = new BehaviorSubject<number>(base);
    }

    engrToEngrString = (value: number | string | undefined): string | undefined => this.toEngrString(this.toRaw(value));

    engrToEngrFloat = (value: number | string | undefined): number | undefined => this.toEngrFloat(this.toRaw(value));
    toRaw = (value: number | string | undefined): number | undefined => {
        const rawFloat = this.toRawFloat(value);
        if (this.rawIsInt) {
            return typeof rawFloat === 'number' ? Math.round(rawFloat) : undefined;
        }
        return rawFloat;
    };

    get unitObs(): Observable<string> {
        return this.unitSubject.asObservable();
    }
    get unit(): string {
        return this.unitSubject.getValue();
    }
    set unit(value: string) {
        this.unitSubject.next(value);
    }

    get precisionObs(): Observable<number> {
        return this.precisionSubject.asObservable();
    }
    get precision(): number {
        return this.precisionSubject.getValue();
    }
    set precision(value: number) {
        if (Number.isInteger(value) && value >= 0) {
            this.precisionSubject.next(value);
        }
    }

    get baseObs(): Observable<number> {
        return this.baseSubject.asObservable();
    }
    get base(): number {
        return this.baseSubject.getValue();
    }
    set base(value: number) {
        if (Number.isInteger(value) && value >= 2) {
            this.baseSubject.next(value);
        }
    }

    get valueParamObs(): Observable<void> {
        return this.valueParamSubject.asObservable();
    }
}
