import { arrayMax } from '../../util/core';
import { Slot } from './slot';

export interface LinearSlotConfig {
    raw: [number, number];
    engr: [number, number];
    unit: string;
    precision: number;
    base?: number;
    rawIsInt?: boolean;
    engrStep?: number;
}

export class LinearSlot extends Slot {
    private static convertRange(value: number | undefined, from: [number, number], to: [number, number]): number | undefined {
        if (Slot.isNumber(value) && value >= from[0] && value <= from[1]) {
            const conv: number = (((value as number) - from[0]) / (from[1] - from[0])) * (to[1] - to[0]) + to[0];
            return Math.min(Math.max(conv, to[0]), to[1]); // clamp to range, to avoid nastiness from floating point error
        }
        return undefined; // out of range
    }

    readonly rawIsInt: boolean;

    // range tuples are min, max
    constructor(private config: LinearSlotConfig) {
        super(config.unit, config.precision, config.base);
        this.rawIsInt = config.rawIsInt ?? true;
    }

    toEngrFloat = (value: number | undefined): number | undefined => {
        if (value === undefined || isNaN(value)) {
            return undefined;
        } else {
            return LinearSlot.convertRange(value, this.config.raw, this.config.engr);
        }
    };

    toEngrString = (value: number | undefined): string | undefined => {
        const floatValue = this.toEngrFloat(value) as number;
        if (isNaN(floatValue)) {
            return undefined;
        } else if (this.base === 10) {
            return floatValue.toFixed(this.precision);
        } else {
            return floatValue.toString(this.base);
        }
    };

    toRawFloat = (value: number | string | undefined): number | undefined => {
        if (typeof value === 'undefined' || (typeof value === 'number' && isNaN(value))) {
            return undefined;
        }
        return LinearSlot.convertRange(this.engrNumOrStringToNum(value), this.config.engr, this.config.raw);
    };

    rawInRange = (value: number | undefined): boolean =>
        typeof value === 'number' && value >= this.config.raw[0] && value <= this.config.raw[1];

    engrInRange = (value: number | string | undefined): boolean => {
        const engrConv: number | undefined = this.engrNumOrStringToNum(value);
        return engrConv !== undefined && engrConv >= this.config.engr[0] && engrConv <= this.config.engr[1];
    };

    engrNumOrStringToNum = (value: number | string | undefined): any => {
        let engrConv: number | undefined;
        if (typeof value === 'string') {
            if (this.base === 10) {
                engrConv = parseFloat(value);
            } else {
                engrConv = parseInt(value, this.base);
            }
        } else if (typeof value === 'number' && !isNaN(value)) {
            engrConv = value;
        }
        return engrConv;
    };

    get rawMin() {
        return this.config.raw[0];
    }
    get rawMax() {
        return this.config.raw[1];
    }
    get rawStep() {
        return this.rawIsInt ? 1 : arrayMax(this.config.raw.map(Math.abs)) * Number.EPSILON;
    }
    get engrMin() {
        return this.config.engr[0];
    }
    get engrMax() {
        return this.config.engr[1];
    }
    get engrStep() {
        if (typeof this.config.engrStep === 'number') {
            return this.config.engrStep;
        }
        const engrLsb = Math.pow(0.1, this.precision);
        if (this.rawIsInt) {
            const engrRawLsb = Math.abs((this.config.engr[1] - this.config.engr[0]) / (this.config.raw[1] - this.config.raw[0]));
            return Math.max(engrRawLsb, engrLsb);
        } else {
            return engrLsb;
        }
    }

    get rawRange(): [number, number] {
        return this.config.raw;
    }
    set rawRange(value: [number, number]) {
        if (this.config.raw !== value) {
            this.config.raw = value;
            this.valueParamSubject.next();
        }
    }

    get engrRange(): [number, number] {
        return this.config.engr;
    }
    set engrRange(value: [number, number]) {
        if (this.config.engr !== value) {
            this.config.engr = value;
            this.valueParamSubject.next();
        }
    }
}
