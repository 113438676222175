import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { isEqual } from "lodash-es";

import { DataProvider } from "../../providers/data-provider";
import {
    SignalGroupProvider,
    SignalLayoutEntry,
} from "../../providers/signal-group-provider";
import {
    SignalSettingsProvider,
    TraceRange,
    defaultTraceRange,
    maxSignalDashLength,
    signalColors,
    signalDashes,
} from "../../providers/signal-settings-provider";

@Component({
    templateUrl: "edit-signal.component.html",
})
export class EditSignalComponent {
    @Input() signal: SignalLayoutEntry;
    colorIndex: number;
    dashIndex: number;

    colors = signalColors;
    dashes = signalDashes;
    sampleWidth = maxSignalDashLength;

    private range: TraceRange = { ...defaultTraceRange };

    constructor(
        private dataProvider: DataProvider,
        private modalCtrlr: ModalController,
        private signalGroupProvider: SignalGroupProvider,
        private signalSettingsProvider: SignalSettingsProvider
    ) {}

    ngOnInit() {
        this.range = {
            ...(this.signalSettingsProvider.ranges[this.signal.key] ||
                defaultTraceRange),
        };
        this.loadStyle();
    }

    ionViewWillLeave() {
        if (
            !isEqual(
                this.signalSettingsProvider.ranges[this.signal.key],
                this.range
            )
        ) {
            this.signalSettingsProvider.setRange(this.signal.key, this.range);
        }
        const style = { color: this.colorIndex, dash: this.dashIndex };
        if (
            !isEqual(
                this.signalSettingsProvider.computedStyles[this.signal.key],
                style
            )
        ) {
            this.signalSettingsProvider.setStyle(this.signal.key, style);
        }
    }

    deleteSignal() {
        this.modalCtrlr.dismiss().catch(console.warn);
        this.signalGroupProvider.layout =
            this.signalGroupProvider.layout.filter((e) => e !== this.signal);
    }

    resetStyle() {
        this.signalSettingsProvider.clearStyle(this.signal.key);
        this.loadStyle();
    }

    resetRange() {
        this.range = { ...defaultTraceRange };
    }

    onBack() {
        this.modalCtrlr.dismiss().catch(console.warn);
    }

    get minY() {
        return this.signal.slot.toEngrString(this.range.minY) ?? "";
    }

    set minY(v) {
        this.range.minY = this.signal.slot.toRawFloat(v);
    }

    get maxY() {
        return this.signal.slot.toEngrString(this.range.maxY) ?? "";
    }

    set maxY(v) {
        this.range.maxY = this.signal.slot.toRawFloat(v);
    }

    get dedicatedY() {
        return this.range.dedicatedY;
    }

    set dedicatedY(v) {
        this.range.dedicatedY = v;
    }

    get unit() {
        return this.dataProvider.data?.signals[this.signal.key]?.unit || "";
    }

    private loadStyle() {
        this.colorIndex =
            this.signalSettingsProvider.computedStyles[this.signal.key].color;
        this.dashIndex =
            this.signalSettingsProvider.computedStyles[this.signal.key].dash;
    }
}
