import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { DisplaySlotLocalizer } from '../hgm-setup/src/module/param/services/display-slot-localizer';
import { ParamDefDisplaySlotLocalizer } from '../hgm-setup/src/module/param/services/param-def-display-slot-localizer';

const slotL10nKey = 'slotL10nSelections';

interface SettingDef<T> {
    storeKey?: string;
    default: T;
}

export type XAxisType = 'relative' | 'time' | 'date';
export type ChartDivPolicy = 'single' | 'perUnit' | 'perSignal';
export type ThemePolicy = 'system' | 'light' | 'dark';

const defs = {
    xAxisType: { default: 'relative' } as SettingDef<XAxisType>,
    preferredFineWidth: { default: 10 * 1000 } as SettingDef<number>,
    preferredCoarseWidth: { default: 5 * 60 * 1000 } as SettingDef<number>,
    alwaysShowCoarse: { default: false } as SettingDef<boolean>,
    chartDivPolicy: { default: 'perUnit' } as SettingDef<ChartDivPolicy>,
    themePolicy: { default: 'system' } as SettingDef<ThemePolicy>,
} as const;

@Injectable({ providedIn: 'root' })
export class GlobalSettingsProvider {
    declare xAxisType: typeof defs.xAxisType.default;
    declare xAxisTypeObs: Observable<typeof defs.xAxisType.default>;
    declare preferredFineWidth: typeof defs.preferredFineWidth.default;
    declare preferredFineWidthObs: Observable<typeof defs.preferredFineWidth.default>;
    declare preferredCoarseWidth: typeof defs.preferredCoarseWidth.default;
    declare preferredCoarseWidthObs: Observable<typeof defs.preferredCoarseWidth.default>;
    declare alwaysShowCoarse: typeof defs.alwaysShowCoarse.default;
    declare alwaysShowCoarseObs: Observable<typeof defs.alwaysShowCoarse.default>;
    declare chartDivPolicy: typeof defs.chartDivPolicy.default;
    declare chartDivPolicyObs: Observable<typeof defs.chartDivPolicy.default>;
    declare themePolicy: typeof defs.themePolicy.default;
    declare themePolicyObs: Observable<typeof defs.themePolicy.default>;

    private subj = new Map<string, any>();

    constructor(
        private dsl: DisplaySlotLocalizer,
    ) {
        this.paramDsl.ready().subscribe({
            next: () => {
                this.paramDsl.indices = this.savedIndices;
            }
        });
        for (const [key, def] of Object.entries(defs)) {
            const stored = window.localStorage.getItem(def.storeKey ?? key);
            let value = def.default;
            if (stored !== null) {
                try {
                    value = JSON.parse(stored) ?? def.default;
                }
                catch { }
            }
            const subj = new BehaviorSubject(value);
            this.subj.set(key, subj);
            Object.defineProperty(this, key, {
                get: () => subj.value,
                set: v => {
                    window.localStorage.setItem(def.storeKey ?? key, JSON.stringify(v));
                    subj.next(v);
                },
            });
            Object.defineProperty(this, key + 'Obs', { get: () => subj.asObservable() });
        }
    }

    onUnitSelectionChange() {
        // keep any indices that don't appear in the presently active log
        const toSave = {
            ...this.savedIndices,
            ...this.paramDsl.indices,
        };
        window.localStorage.setItem(slotL10nKey, JSON.stringify(toSave));
    }

    private get paramDsl() {
        return this.dsl as ParamDefDisplaySlotLocalizer;
    }

    private get savedIndices() {
        const saved = JSON.parse(window.localStorage.getItem(slotL10nKey) || '{}');
        return saved && typeof saved === 'object' ? saved : {};
    }
}
