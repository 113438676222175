import { Tick } from 'chart.js';

export const xTickCallback = (value: number | string, _index: number, ticks: Tick[]): number | string | null => {
    const spanLog = Math.log10(Math.abs(ticks[ticks.length - 1].value - ticks[0].value) / 1000);
    if (!isFinite(spanLog)) {
        return Number(value) / 1000; // fallback to something halfway sane
    }

    const nDigits = Math.min(Math.max(Math.ceil(1 - spanLog), 0), 5);
    return (Number(value) / 1000).toFixed(nDigits);
};
