import { BehaviorSubject, Observable } from 'rxjs';
import { Param, ParamAcl, ParamAdvice } from './param';
import { DataType, Slot, getDataTypeLength } from './slot';

export class ScalarParam extends Param {
    private valueSubject: BehaviorSubject<number | undefined>;

    constructor(key: string, acl: ParamAcl, advice: ParamAdvice | undefined, slot: Slot | void, addr: any, dataType: [DataType, boolean]) {
        // dataType second member is isLittleEndian
        super(key, acl, advice, true, slot, addr, dataType, getDataTypeLength(dataType[0]));
        this.valueSubject = new BehaviorSubject<number | undefined>(undefined);
    }

    get valueObs(): Observable<number | undefined> {
        return this.valueSubject.asObservable();
    }

    get value(): number | undefined {
        return this.valueSubject.value;
    }

    set value(value: number | undefined) {
        if (typeof value === 'number') {
            this.setHostData(value);
        } else {
            this.clearHostDataFrom(0);
        }
    }

    get minSize(): number {
        return this.dataTypeSize;
    }

    get maxSize(): number {
        return this.dataTypeSize;
    }

    get size(): number | undefined {
        return this.dataTypeSize;
    }

    get targetSize(): number | undefined {
        return this.dataTypeSize;
    }

    getSerializableRawValue(): number | undefined {
        return this.getHostData(0);
    }

    getSerializableValue() {
        return this.slot.toEngrFloat(this.getHostData(0));
    }

    getSerializableStringValue() {
        return this.slot.toEngrString(this.getHostData(0));
    }

    setSerializableRawValue(value: any): boolean {
        if (typeof value === 'number') {
            if (this.slot.rawInRange(value)) {
                this.setHostData(value);
                return true;
            } else {
                return false;
            }
        } else if (value == null) {
            this.clearHostDataFrom(0);
            return true;
        } else {
            return false;
        }
    }

    setSerializableValue(value: any): boolean {
        if (typeof value === 'number' || typeof value === 'string') {
            if (this.slot.engrInRange(value)) {
                this.setHostData(this.slot.toRaw(value) as number);
                return true;
            } else {
                return false;
            }
        } else if (value == null) {
            this.clearHostDataFrom(0);
            return true;
        } else {
            return false;
        }
    }

    protected updateFromHostBytes(_begin: number, _end: number) {
        this.valueSubject.next(this.getHostData(0));
    }
}
