import { Injectable } from '@angular/core';

import { DataType } from '../hgm-setup/src/module/param/core/slot';
import { ParamAddrCalculator } from '../hgm-setup/src/module/param/services/param-registry';

@Injectable()
export class AppParamAddrCalculator extends ParamAddrCalculator {
    addr(): any {
        return 0;
    }
    type(): [DataType, boolean] {
        // we are not editing data so we don't need to do any rounding;
        // therefore, maintain compatibility with both int32 and floating point devices.
        return [DataType.float64, true];
    }
}
