import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, skip } from 'rxjs/operators';
import { LogFile } from '../param/core/logging-types';
import { ParamSystemRawDefProvider, ParamSystemRawDefRecord, RawParamDefParseResult, nullMenu } from '../param/util/param-system-def';
import { truthyOnly } from './core';

@Injectable({ providedIn: 'root' })
export class LogFileProvider implements ParamSystemRawDefProvider {
    private fileSubject = new BehaviorSubject<LogFile | undefined>(undefined);
    private parseResultSubject = new BehaviorSubject<RawParamDefParseResult | undefined>(undefined);
    private regReadySubject = new BehaviorSubject<LogFile | undefined>(undefined);

    get record(): Observable<ParamSystemRawDefRecord | undefined> {
        return this.fileSubject.asObservable().pipe(
            map((r) =>
                r
                    ? {
                          raw: {
                              ...r.log.header.paramDef,
                              menus: nullMenu,
                          },
                          metadata: {
                              name: r.file.name,
                              description: r.file.name,
                              data: r.file,
                              url: undefined,
                          },
                      }
                    : undefined,
            ),
        );
    }

    onParsed(result: RawParamDefParseResult) {
        this.parseResultSubject.next(result);
    }

    get file() {
        return this.fileSubject.value;
    }

    get fileObs() {
        return this.fileSubject.asObservable();
    }

    get parseResult() {
        return this.parseResultSubject.value;
    }

    get parseResultObs() {
        return this.parseResultSubject.asObservable();
    }

    get regReady() {
        return this.regReadySubject.value;
    }

    get regReadyObs() {
        return this.regReadySubject.asObservable();
    }

    load = (file: LogFile) =>
        new Promise<void>((res, rej) => {
            this.parseResultSubject.pipe(skip(1), truthyOnly(), first()).subscribe({
                next: (result) => {
                    if (result.err) {
                        this.regReadySubject.next(undefined);
                        rej({ err: result.err, fatal: true });
                    } else {
                        this.regReadySubject.next(file);
                        res();
                    }
                },
            });
            this.fileSubject.next(file);
        });
}
