import { Injectable } from '@angular/core';
import { GlobalSettingsProvider } from './global-settings-provider';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ThemeManager {
    private isDarkSubj = new BehaviorSubject(false);

    constructor(settings: GlobalSettingsProvider) {
        const prefersDarkQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const prefersDarkSubj = new BehaviorSubject(prefersDarkQuery.matches);

        if (prefersDarkQuery.addEventListener) {
            prefersDarkQuery.addEventListener('change', ev => prefersDarkSubj.next(ev.matches));
        }
        else {
            // handle certain backward browsers (cough) Safari (cough)
            prefersDarkQuery.addListener(ev => prefersDarkSubj.next(ev.matches)); // eslint-disable-line import/no-deprecated
        }

        combineLatest([settings.themePolicyObs, prefersDarkSubj])
            .subscribe(t => {
                const dark = { system: t[1], light: false, dark: true }[t[0]];
                const classList = document.documentElement.classList;
                if (dark) {
                    classList.add('dark-mode');
                    classList.remove('light-mode');
                }
                else {
                    classList.add('light-mode');
                    classList.remove('dark-mode');
                }
                this.isDarkSubj.next(dark);
            });
    }

    get isDarkObs() {
        return this.isDarkSubj.asObservable();
    }
}
