import { Injectable } from '@angular/core';
import { escapeRegExp, uniq } from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { truthyOnly } from '../../util/core';
import { TranslationType, Translator } from '../../util/translation-manager';
import { ScalarParam } from '../core/scalar-param';
import { ParamSystemDefRecord, ParamTranslationDef } from '../util/param-system-def';
import { ParamRegistry } from './param-registry';

class SingleTranslator implements Translator {
    private regex: RegExp;
    private reverseRegex: RegExp;
    private replacement: string;
    private reverseReplacement: string;

    constructor(
        key: string,
        private def: ParamTranslationDef,
    ) {
        this.reverseReplacement = `{{${key}}}`;
        this.regex = new RegExp(this.reverseReplacement);
        this.replacement = this.def.default;
    }

    update(value: number) {
        const replacement = this.def.map[value];
        this.replacement = typeof replacement === 'string' ? replacement : this.def.default;
        this.reverseRegex = new RegExp(escapeRegExp(this.replacement));
    }

    translate(str: string, _type: TranslationType) {
        if (this.regex.exec(str)) {
            return str.replace(this.regex, this.replacement);
        } else {
            return str;
        }
    }

    cislate(str: string, _type: TranslationType) {
        if (this.reverseRegex.exec(str)) {
            return str.replace(this.reverseRegex, this.reverseReplacement);
        } else {
            return str;
        }
    }
}

class SingleParamTranslator implements Translator {
    private subscription: Subscription;
    private translators: SingleTranslator[];

    constructor(
        reg: ParamRegistry,
        private defs: { [key: string]: ParamTranslationDef },
    ) {
        const defKeys = Object.keys(defs);
        const defsArray = defKeys.map((key) => defs[key]);
        if (defsArray.some((def) => def.param !== defsArray[0].param)) {
            throw new Error('API violation');
        }
        const param = reg.typedParamByKeyThrow(ScalarParam, defsArray[0].param);
        reg.registerEarlyLoadParam(param);

        this.translators = defKeys.map((key) => new SingleTranslator(key, this.defs[key]));
        this.subscription = param.valueObs.subscribe({ next: this.onValueChange });
    }

    translate(str: string, type: TranslationType) {
        let out = str;
        for (const translator of this.translators) {
            out = translator.translate(out, type);
        }
        return out;
    }

    cislate(str: string, type: TranslationType) {
        let out = str;
        for (const translator of this.translators) {
            out = translator.cislate(out, type);
        }
        return out;
    }

    destroy() {
        this.subscription.unsubscribe();
    }

    private onValueChange = (value: number) => {
        for (const translator of this.translators) {
            translator.update(value);
        }
    };
}

@Injectable({ providedIn: 'root' })
export class ParamKeyedTranslator implements Translator {
    private translators: SingleParamTranslator[] = [];
    private readySubj = new BehaviorSubject<ParamSystemDefRecord | undefined>(undefined);

    constructor(reg: ParamRegistry) {
        reg.ready().subscribe((rec) => {
            for (const translator of this.translators) {
                translator.destroy();
            }
            const def = rec.def.paramTranslations || {};
            const params = Object.keys(def).map((key) => def[key].param);
            this.translators = uniq(params).map((param) => {
                const translationKeys = Object.keys(def).filter((key) => def[key].param === param);
                const defs: { [key: string]: ParamTranslationDef } = {};
                for (const translationKey of translationKeys) {
                    defs[translationKey] = def[translationKey];
                }
                return new SingleParamTranslator(reg, defs);
            });
            this.readySubj.next(rec);
        });
    }

    ready(): Observable<ParamSystemDefRecord> {
        return this.readySubj.pipe(truthyOnly());
    }

    translate(str: string, type: TranslationType) {
        let out = str;
        for (const translator of this.translators) {
            out = translator.translate(out, type);
        }
        return out;
    }

    cislate(str: string, type: TranslationType) {
        let out = str;
        for (const translator of this.translators) {
            out = translator.cislate(out, type);
        }
        return out;
    }
}
