import * as Pako from 'pako';
import { Observable } from 'rxjs';

/** BEGIN PORTION FROM pako/lib/zlib/messages.js */
// (C) 1995-2013 Jean-loup Gailly and Mark Adler
// (C) 2014-2017 Vitaly Puzrin and Andrey Tupitsin
//
// This software is provided 'as-is', without any express or implied
// warranty. In no event will the authors be held liable for any damages
// arising from the use of this software.
//
// Permission is granted to anyone to use this software for any purpose,
// including commercial applications, and to alter it and redistribute it
// freely, subject to the following restrictions:
//
// 1. The origin of this software must not be misrepresented; you must not
//   claim that you wrote the original software. If you use this software
//   in a product, an acknowledgment in the product documentation would be
//   appreciated but is not required.
// 2. Altered source versions must be plainly marked as such, and must not be
//   misrepresented as being the original software.
// 3. This notice may not be removed or altered from any source distribution.

const messages = {
    2: 'need dictionary' /* Z_NEED_DICT       2  */, // eslint-disable-line @typescript-eslint/naming-convention
    1: 'stream end' /* Z_STREAM_END      1  */, // eslint-disable-line @typescript-eslint/naming-convention
    0: '' /* Z_OK              0  */, // eslint-disable-line @typescript-eslint/naming-convention
    '-1': 'file error' /* Z_ERRNO         (-1) */, // eslint-disable-line @typescript-eslint/naming-convention
    '-2': 'stream error' /* Z_STREAM_ERROR  (-2) */, // eslint-disable-line @typescript-eslint/naming-convention
    '-3': 'data error' /* Z_DATA_ERROR    (-3) */, // eslint-disable-line @typescript-eslint/naming-convention
    '-4': 'insufficient memory' /* Z_MEM_ERROR     (-4) */, // eslint-disable-line @typescript-eslint/naming-convention
    '-5': 'buffer error' /* Z_BUF_ERROR     (-5) */, // eslint-disable-line @typescript-eslint/naming-convention
    '-6': 'incompatible version' /* Z_VERSION_ERROR (-6) */, // eslint-disable-line @typescript-eslint/naming-convention
};
/** END PORTION FROM pako/lib/zlib/messages.js */

export const pakoInflate =
    <OutputType = Pako.Data>(options?: Pako.InflateOptions) =>
    (source: Observable<Pako.Data | ArrayBuffer>) =>
        new Observable<OutputType>((observer) => {
            const inflator = new Pako.Inflate(options);
            inflator.onData = (data) => observer.next(data as any as OutputType);
            inflator.onEnd = (status) => {
                if (status) {
                    observer.error({ err: status, msg: messages[status] });
                } else {
                    observer.complete();
                }
                inflator.onEnd = () => {}; // prevent repeated calls
            };
            return source.subscribe(
                (value) => {
                    inflator.push(value);
                },
                (error: any) => {
                    inflator.onEnd = () => {};
                    observer.error(error);
                },
                () => {
                    inflator.onEnd = () => {};
                    observer.complete();
                },
            );
        });

export const pakoDeflate =
    <OutputType = Pako.Data>(options?: Pako.DeflateOptions) =>
    (source: Observable<Pako.Data | ArrayBuffer>) =>
        new Observable<OutputType>((observer) => {
            const deflator = new Pako.Deflate(options);
            let endType: { error?: any };
            deflator.onData = (data) => observer.next(data as any as OutputType);
            deflator.onEnd = (status) => {
                endType = endType || (status ? { error: { err: status, msg: messages[status] } } : {});
                if (endType.error) {
                    observer.error(endType.error);
                } else {
                    observer.complete();
                }
                deflator.onEnd = () => {}; // prevent repeated calls
            };
            return source.subscribe(
                (value) => {
                    deflator.push(value, false);
                },
                (error: any) => {
                    if (!endType) {
                        endType = { error };
                        deflator.push(new Uint8Array(), true);
                    }
                },
                () => {
                    if (!endType) {
                        endType = {};
                        deflator.push(new Uint8Array(), true);
                    }
                },
            );
        });
