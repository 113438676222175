import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CategorySelectModalComponent, CategorySelectModalItem } from '../entry/category-select-modal';

export type HgmSelectItem = CategorySelectModalItem;

@Component({
    selector: 'hgm-select',
    templateUrl: 'hgm-select.html',
})
export class HgmSelectComponent {
    @Input() items: HgmSelectItem[] = [];
    @Input() label: string | undefined;
    @Input() value: any;
    @Input() canSearch: boolean | undefined;
    @Input() disabled: boolean | undefined;
    @Input() itemCover = true;
    @Input() popoverClass: string | undefined;
    @Output() valueChange = new EventEmitter<any>();

    constructor(private modalCtrlr: ModalController) {}

    get item(): HgmSelectItem | undefined {
        return this.items.find((item) => item.key === this.value);
    }

    get hasChoice() {
        return !this.item || this.items.length > 1;
    }

    async onClicked(event: MouseEvent) {
        if (this.disabled || !this.hasChoice) {
            return;
        }
        event.stopPropagation();
        const modal = await this.modalCtrlr.create({
            component: CategorySelectModalComponent,
            componentProps: {
                title: this.label,
                choices: [
                    {
                        name: '',
                        items: this.items,
                        expanded: true,
                    },
                ],
                dismissOnClick: true,
                initialValue: this.value,
                canSearch: this.canSearch,
            },
            cssClass: this.popoverClass,
            id: 'CategorySelectModalComponent',
        });
        await modal.present();
        const detail = await modal.onWillDismiss();
        if (detail.data !== undefined) {
            this.valueChange.emit(detail.data);
        }
    }
}
