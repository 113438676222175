import { Injectable } from "@angular/core";
import { last, uniq } from "lodash-es";
import { BehaviorSubject } from "rxjs";
import { distinctUntilChanged, map, skip } from "rxjs/operators";
import { Slot } from "../hgm-setup/src/module/param/core/slot";
import {
    arrayLikeEqual,
    arrayMax,
    arrayMin,
} from "../hgm-setup/src/module/util/core";
import { DataSet, Signal } from "./data-provider";
import { GlobalSettingsProvider } from "./global-settings-provider";
import { SignalSettingsProvider, StyleMap } from "./signal-settings-provider";
import { LayoutEntryWithoutSlot } from "../app/tab-configurations/tab-configurations.page";
import { SignalNoData } from "./data-provider";

export interface UserSignalRange {
    dedicatedY?: boolean;
    minY?: number;
    maxY?: number;
}

export interface SignalLayoutEntry {
    key: string;
    type: "signal";
    name: string;
    slot: Slot;
}

export interface DividerLayoutEntry {
    key: string;
    type: "divider";
}

export type LayoutEntry = SignalLayoutEntry | DividerLayoutEntry;

export type GroupSignal = Signal & { minY: number; maxY: number };

@Injectable({ providedIn: "root" })
export class SignalGroupProvider {
    private dataSignalKeys: string[] = [];
    private layoutEntries: LayoutEntry[] = [];
    private layoutEntriesWithoutSlot: LayoutEntryWithoutSlot[] = [];
    private styledGroupsSubj = new BehaviorSubject<
        { groups: GroupSignal[][]; styles: StyleMap } | undefined
    >(undefined);
    private styledGroupsNoDataSubj = new BehaviorSubject<
        { groups: SignalNoData[][]; styles: StyleMap } | undefined
    >(undefined);
    private configurationFileName = new BehaviorSubject<string | undefined>(
        undefined
    );

    constructor(
        private signalSetter: SignalSettingsProvider,
        private globalSettings: GlobalSettingsProvider
    ) {
        this.signalSetter.styledDataObs.subscribe({ next: this.onStyledData });
        globalSettings.chartDivPolicyObs
            .pipe(distinctUntilChanged(), skip(1))
            .subscribe({ next: this.reset });
    }

    get layout(): LayoutEntry[] {
        return this.layoutEntries;
    }

    set layout(entries) {
        if (!arrayLikeEqual(entries, this.layoutEntries)) {
            this.layoutEntries = entries;
            this.updateGroups();
        }
    }

    get layoutNoSlot(): LayoutEntryWithoutSlot[] {
        return this.layoutEntriesWithoutSlot;
    }

    set layoutNoSlot(entries) {
        if (!arrayLikeEqual(entries, this.layoutEntriesWithoutSlot)) {
            this.layoutEntriesWithoutSlot = entries;
            this.updateGroupsNoData();
        }
    }

    get appliedConfigurationFileName() {
        return this.configurationFileName.value;
    }

    set appliedConfigurationFileName(value: string | undefined) {
        this.configurationFileName.next(value);
    }

    get styledGroups() {
        return this.styledGroupsSubj.value;
    }

    get styledGroupsObs() {
        return this.styledGroupsSubj.asObservable();
    }

    get styledGroupsNonNullObs() {
        return this.styledGroupsSubj.pipe(
            map((sg) => sg || { groups: [], styles: {} })
        );
    }

    get styledGroupsNoData() {
        return this.styledGroupsNoDataSubj.value;
    }

    get styledGroupsNoDataObs() {
        return this.styledGroupsNoDataSubj.asObservable();
    }

    get styledGroupsNoDataNonNullObs() {
        return this.styledGroupsNoDataSubj.pipe(
            map((sg) => sg || { groups: [], styles: {} })
        );
    }

    reset = () => {
        this.dataSignalKeys = [];
        this.onStyledData(this.signalSetter.styledData);
    };

    updateGroups() {
        const { data, styles } = this.signalSetter.styledData || {
            data: undefined,
            styles: {},
        };
        if (!data) {
            this.styledGroupsSubj.next(undefined);
            return;
        }

        const groups: GroupSignal[][] = [[]];
        for (const entry of this.layoutEntries) {
            if (entry.type === "signal") {
                const signal = data.signals[entry.key];
                const yValues = signal.data
                    .map((d) => d[1])
                    .filter((y) => isFinite(y));
                last(groups)!.push({
                    ...signal,
                    minY: arrayMin(yValues),
                    maxY: arrayMax(yValues),
                });
            } else {
                console.assert(entry.type === "divider");
                groups.push([]);
            }
        }

        this.styledGroupsSubj.next({ groups, styles });
    }

    updateGroupsNoData() {
        const { data, styles } = this.signalSetter.styledData || {
            data: undefined,
            styles: {},
        };
        if (!data) {
            this.styledGroupsNoDataSubj.next(undefined);
            return;
        }

        const groups: SignalNoData[][] = [[]];
        for (const entry of this.layoutEntriesWithoutSlot) {
            if (entry.type === "signal") {
                const signalNoData = {
                    key: entry.key,
                    name: entry.name,
                    unit: "",
                    data: [],
                };
                last(groups)!.push(signalNoData);
            } else {
                console.assert(entry.type === "divider");
                groups.push([]);
            }
        }

        this.styledGroupsNoDataSubj.next({ groups, styles });
    }

    private onStyledData = (
        styledData: { data: DataSet; styles: StyleMap } | undefined
    ) => {
        if (!styledData) {
            this.layoutEntries = [];
            this.styledGroupsSubj.next(undefined);
            this.styledGroupsNoDataSubj.next(undefined);
            return;
        }

        const signalKeys = Object.keys(styledData.data.signals).sort();
        const signalsChanged = !arrayLikeEqual(signalKeys, this.dataSignalKeys);
        this.dataSignalKeys = signalKeys;

        if (signalsChanged) {
            // regenerate layout entries
            this.layoutEntries = [];
            switch (this.globalSettings.chartDivPolicy) {
                case "single":
                    for (const key of signalKeys) {
                        this.layoutEntries.push({
                            key,
                            type: "signal",
                            name: styledData.data.signals[key].name,
                            slot: styledData.data.signals[key].slot,
                        });
                    }
                    break;
                case "perUnit":
                    const units = uniq(
                        Object.values(styledData.data.signals).map(
                            (s) => s.unit
                        )
                    ).sort();
                    for (const [i, unit] of units.entries()) {
                        const signalEntries = Object.entries(
                            styledData.data.signals
                        )
                            .filter((e) => e[1].unit === unit)
                            .sort();
                        for (const signalEntry of signalEntries) {
                            this.layoutEntries.push({
                                key: signalEntry[0],
                                type: "signal",
                                name: signalEntry[1].name,
                                slot: styledData.data.signals[signalEntry[0]]
                                    .slot,
                            });
                        }
                        if (i < units.length - 1) {
                            this.layoutEntries.push({
                                key: "divider" + i,
                                type: "divider",
                            });
                        }
                    }
                    break;
                case "perSignal":
                    for (const [i, key] of signalKeys.entries()) {
                        this.layoutEntries.push({
                            key,
                            type: "signal",
                            name: styledData.data.signals[key].name,
                            slot: styledData.data.signals[key].slot,
                        });
                        if (i < signalKeys.length - 1) {
                            this.layoutEntries.push({
                                key: "divider" + i,
                                type: "divider",
                            });
                        }
                    }
                    break;
            }
        }

        this.updateGroups();
    };
}
