import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';

@Component({
    selector: 'line-sample',
    templateUrl: 'line-sample.component.html',
})
export class LineSampleComponent {
    @Input() color: string;
    @Input() dash: number[] = [];
    @Input() lineWidth = 3;
    @Input() scale = 1;
    @Input() width = 36;
    @Input() height = 12;

    @ViewChild('canvas', { static: true }) private canvas: ElementRef<HTMLCanvasElement>;

    ngOnInit() {
        this.draw();
    }

    ngOnChanges() {
        this.draw();
    }

    ngOnDestroy() {
        this.canvas.nativeElement?.parentElement?.removeChild(this.canvas.nativeElement);
    }

    private draw() {
        const canvas = this.canvas.nativeElement;
        const ctx = canvas?.getContext('2d');
        if (!ctx) {
            console.error('Could not get canvas context');
            return;
        }
        canvas.width = this.width;
        canvas.height = this.height;
        ctx.scale(
            canvas.width / canvas.getBoundingClientRect().width,
            canvas.height / canvas.getBoundingClientRect().height,
        );
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.strokeStyle = this.color;
        ctx.setLineDash(this.dash.map(l => l * this.scale));
        ctx.lineWidth = this.lineWidth;
        ctx.beginPath();
        ctx.moveTo(0, canvas.height / 2);
        ctx.lineTo(canvas.width, canvas.height / 2);
        ctx.stroke();
    }
}
