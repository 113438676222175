import { Component } from '@angular/core';

import { LogFileProvider } from '../hgm-setup/src/module/util/log-file-provider';
import { ParamKeyedTranslator } from '../hgm-setup/src/module/param/services/param-keyed-translator';
import { ParamSystemDefProvider } from '../hgm-setup/src/module/param/util/param-system-def';
import { TranslationManager } from '../hgm-setup/src/module/util/translation-manager';
import { NullParamDefProvider } from '../hgm-setup/src/module/util/null-param-def-provider';

import { GlobalSettingsProvider } from '../providers/global-settings-provider';
import { SignalGroupProvider } from '../providers/signal-group-provider';
import { ThemeManager } from '../providers/theme-manager';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
})
export class AppComponent {
    constructor(
        logFileProvider: LogFileProvider,
        nullDefProvider: NullParamDefProvider,
        paramKeyedTranslator: ParamKeyedTranslator,
        paramSystemDefProvider: ParamSystemDefProvider,
        public globalSettings: GlobalSettingsProvider, // force instantiation
        public signalGroupProvider: SignalGroupProvider, // force instantiation
        public themeManager: ThemeManager, // force instantiation
        translationManager: TranslationManager,
    ) {
        paramSystemDefProvider.setProviders([logFileProvider, nullDefProvider]);
        translationManager.add(paramKeyedTranslator);
    }
}
