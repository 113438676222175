import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { range } from 'lodash';
import { Subscription } from 'rxjs';
import { HgmSelectItem } from '../../components/core/hgm-select';
import { valueOr } from '../../util/core';
import { DisplaySlotLocalizer } from '../services/display-slot-localizer';
import { ParamDefDisplaySlotLocalizer } from '../services/param-def-display-slot-localizer';

export class L10nBinding {
    readonly entries: HgmSelectItem[];

    constructor(
        private localizer: ParamDefDisplaySlotLocalizer,
        private l10nArrayIndex: number,
        private userChange: EventEmitter<void>,
    ) {
        this.entries = range(this.member.entries.length).map((i) => ({ key: i, name: this.member.entries[i] }));
    }

    private get member() {
        return this.localizer.localizations[this.l10nArrayIndex];
    }

    get name() {
        return this.member.name;
    }

    get selectionIndex(): number {
        return this.member.index;
    }

    set selectionIndex(value: number) {
        if (value !== this.selectionIndex) {
            this.localizer.setIndex(this.member.name, value);
            this.userChange.emit();
        }
    }
}

@Component({
    selector: 'unit-selector',
    templateUrl: 'unit-selector.html',
})
export class UnitSelectorComponent implements OnDestroy {
    @Output() userChange = new EventEmitter<void>();

    private localizer: ParamDefDisplaySlotLocalizer;
    private setName: string;
    private setNameSubscription: Subscription;
    private l10nBindingsInt = new Array<L10nBinding>();

    constructor(localizer: DisplaySlotLocalizer) {
        if (!(localizer instanceof ParamDefDisplaySlotLocalizer)) {
            throw new Error('Logic error');
        }
        this.localizer = localizer as ParamDefDisplaySlotLocalizer;
        this.localizer.ready().subscribe({ next: this.onL10nChange });
        this.setNameSubscription = this.localizer.localizationsChangeObs.subscribe({ next: this.onL10nChange });
    }

    ngOnDestroy() {
        if (this.setNameSubscription) {
            this.setNameSubscription.unsubscribe();
        }
    }

    get l10nSets(): HgmSelectItem[] {
        return this.localizer.localizationSets
            .map((s) => ({ key: s, name: s }) as HgmSelectItem)
            .concat([{ key: 'Custom', name: 'Custom', disabled: true }]);
    }

    set l10nSets(_) {}

    get l10nSetName(): string {
        return this.setName;
    }

    set l10nSetName(name: string) {
        if (name) {
            this.localizer.localizationSet = name;
            this.userChange.emit();
        }
    }

    get l10nBindings(): L10nBinding[] {
        return this.l10nBindingsInt;
    }

    set l10nBindings(_) {}

    setL10nIndex(name: string, index: number) {
        this.localizer.setIndex(name, index);
        this.userChange.emit();
    }

    private onL10nChange = () => {
        this.setName = valueOr(this.localizer.localizationSet, 'Custom');
        this.l10nBindingsInt = range(this.localizer.localizations.length).map(
            (index) => new L10nBinding(this.localizer, index, this.userChange),
        );
    };
}
