import { Injectable } from '@angular/core';
import { Observable, never } from 'rxjs';
import { Slot } from '../core/slot';

@Injectable()
export abstract class DisplaySlotLocalizer {
    abstract localize(canonicalSlot: Slot): Slot;
    abstract changeObs(canonicalSlot: Slot): Observable<void>;
    // must fire both when the SLOT's identity changes and when its value params change
}

@Injectable()
export class NullDisplaySlotLocalizer extends DisplaySlotLocalizer {
    localize(canonicalSlot: Slot): Slot {
        return canonicalSlot;
    }
    changeObs(canonicalSlot: Slot): Observable<void> {
        return never();
    }
}
