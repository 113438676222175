import { distinctUntilChanged, map } from 'rxjs/operators';
import { ParamRegistry } from '../services/param-registry';
import { ScalarParam } from './scalar-param';
import { VectorParam } from './vector-param';

const scalarRegex = new RegExp(/^(\w+)$/);
const indexedScalarRegex = new RegExp(/^(\w+\[\d+\])$/);
const vectorRegex = new RegExp(/^(\w+)\[(\d+)\]$/);
const indexedVectorRegex = new RegExp(/^(\w+\[\d+\])\[(\d+)\]$/);

export class ScalarWrapper {
    static getParam(registry: ParamRegistry, spec: string) {
        try {
            return new ScalarWrapper(registry, spec).param;
        } catch {
            return undefined;
        }
    }

    private inner: { param: ScalarParam } | { param: VectorParam; index: number };

    constructor(registry: ParamRegistry, spec: string) {
        this.inner = ScalarWrapper.getInner(registry, spec);
    }

    private static getInner(registry: ParamRegistry, spec: string) {
        const scalarMatch = scalarRegex.exec(spec);
        if (scalarMatch) {
            try {
                return { param: registry.typedParamByKeyThrow(ScalarParam, scalarMatch[1]) };
            } catch {} // move on and try another
        }

        const indexedScalarMatch = indexedScalarRegex.exec(spec);
        if (indexedScalarMatch) {
            try {
                return { param: registry.typedParamByKeyThrow(ScalarParam, indexedScalarMatch[1]) };
            } catch {}
        }

        const vectorMatch = vectorRegex.exec(spec);
        if (vectorMatch) {
            try {
                return {
                    param: registry.typedParamByKeyThrow(VectorParam, vectorMatch[1]),
                    index: parseInt(vectorMatch[2], 10),
                };
            } catch {}
        }

        const indexedVectorMatch = indexedVectorRegex.exec(spec);
        if (indexedVectorMatch) {
            try {
                return {
                    param: registry.typedParamByKeyThrow(VectorParam, indexedVectorMatch[1]),
                    index: parseInt(indexedVectorMatch[2], 10),
                };
            } catch {}
        }

        throw new Error('Could not make a scalar wrapper for param spec ' + spec);
    }

    get key() {
        return this.param.key;
    }

    get slot() {
        return this.param.slot;
    }

    get existsOnTarget() {
        return this.param.existsOnTarget;
    }

    get existsOnTargetObs() {
        return this.param.existsOnTargetObs;
    }

    get param() {
        return this.inner.param;
    }

    get value(): number | undefined {
        if (!('index' in this.inner)) {
            return this.inner.param.value;
        } else {
            return this.inner.param.getMember(this.inner.index);
        }
    }

    set value(v: number | undefined) {
        if (!('index' in this.inner)) {
            this.param.value = v;
        } else {
            this.inner.param.setMember(this.inner.index, v);
        }
    }

    get valueObs() {
        if (!('index' in this.inner)) {
            return this.inner.param.valueObs;
        } else {
            const index = this.inner.index;
            return this.inner.param.valueObs.pipe(
                map((v) => v[index]),
                distinctUntilChanged(),
            );
        }
    }
}
