import { Injectable } from '@angular/core';
import inspect from 'browser-util-inspect';
import { sum } from 'lodash';
import { gzip } from 'pako';
import * as Pino from 'pino';
import { TextEncoder } from 'text-encoding-shim';
import { delay, safeErrorString } from './core';
import { FileIo, FileIoWriter } from './file-io';

interface FileRecord {
    filePath: string;
    size: number;
}

class WriterState {
    startDate: Date;
    fileSize: number;
    filePath: string;
    idle = false;

    private encoder = new TextEncoder();
    private writer: FileIoWriter;

    constructor(private fileIo: FileIo) {}

    get millisecondsOpen() {
        return new Date().getTime() - this.startDate.getTime();
    }

    async start() {
        this.writer = await this.fileIo.createWriter();
        try {
            this.startDate = new Date();
            this.fileSize = 0;
            const dateString = this.startDate.toISOString().replace(/:/g, '_'); // remove colons (for Win32 compatibility)
            this.filePath = `${this.fileIo.logDir}/${dateString}.log`;
            await this.writer.open(this.filePath);
            this.idle = true;
        } catch (err) {
            await this.writer.close();
            this.idle = false;
            throw err;
        }
    }

    async write(text: string) {
        this.idle = false;
        const bytes = this.encoder.encode(text);
        await this.writer.writeBinary(bytes.buffer);
        this.fileSize += bytes.byteLength;
        this.idle = true;
    }

    async close() {
        await this.writer.close();
    }
}

const startWriter = async (fileIo: FileIo) => {
    const writer = new WriterState(fileIo);
    await writer.start();
    return writer;
};

@Injectable({ providedIn: 'root' })
export class ConsoleFileLogger {
    fileRotateThreshold = 1048576;
    fileRotateTime = 24 * 60 * 60 * 1000;
    deleteSizeThreshold = 8388608;
    deleteCountThreshold = 128;
    backgroundYieldTime = 100;
    backgroundDelay = 30 * 60 * 1000;

    private buffer: string[] = [];
    private writer: WriterState | undefined;
    private oldWriter: WriterState | undefined;
    private otherFiles: FileRecord[] = [];
    private pino: Pino.Logger;

    constructor(private fileIo: FileIo) {
        this.pino = Pino.pino({ browser: { transmit: { send: this.onPinoSend } } });
        this.setup().catch(this.pino.error);
    }

    get logger() {
        return this.pino;
    }

    private onPinoSend = (level: Pino.Level, event: Pino.LogEvent) => {
        const text = event.messages.map(inspect).join(' ');
        this.buffer.push(`${new Date(event.ts).toISOString()} ${level.toUpperCase()}: ${text}`);
        this.flush().catch(console.error);
    };

    private setup = async () => {
        await this.fileIo.waitReady();
        await this.fileIo.createDirRecursive(this.fileIo.logDir);
        this.writer = await startWriter(this.fileIo);
        await this.flush();
        for (;;) {
            const otherFiles = await this.getOtherFiles();
            await this.gzipUncompressedFiles(otherFiles);
            await this.purge(otherFiles);
            await delay(this.backgroundDelay);
        }
    };

    private async flush() {
        if (this.writer?.idle) {
            const text = this.buffer.join('\n') + '\n';
            this.buffer = [];
            await this.writer.write(text);
            await this.rotate();
        }
    }

    private async rotate() {
        if (!this.writer?.idle || (this.writer.fileSize < this.fileRotateThreshold && this.writer.millisecondsOpen < this.fileRotateTime)) {
            return;
        }
        this.oldWriter = this.writer;
        [this.writer] = await Promise.all([startWriter(this.fileIo), this.oldWriter.close()]);
        this.otherFiles.push({ filePath: this.oldWriter.filePath, size: this.oldWriter.fileSize });
        this.oldWriter = undefined;
    }

    private async getOtherFiles() {
        // get the directory contents before the new file is created
        const fileNames = await this.fileIo.readDirectory(this.fileIo.logDir);

        // get size of each log file
        const files: FileRecord[] = [];
        for (const fileName of fileNames.sort()) {
            const filePath = this.fileIo.logDir + '/' + fileName;
            if (
                filePath !== this.writer?.filePath &&
                filePath !== this.oldWriter?.filePath &&
                filePath.match(/\d{4}-\d{2}-\d{2}T\d{2}_\d{2}_\d{2}\.?\d{0,3}[A-Z]\.log/) &&
                (await this.fileIo.isFile(filePath))
            ) {
                const metadata = await this.fileIo.getMetadata(filePath);
                files.push({ filePath, size: metadata.size });
                await this.backgroundYield();
            }
        }
        files.reverse();
        return files; // newest first
    }

    private async gzipUncompressedFiles(files: FileRecord[]) {
        // gzip any uncompressed log files
        for (const record of files.filter((file) => !file.filePath.endsWith('.gz'))) {
            try {
                const uncompressed = await this.fileIo.readBinary(record.filePath);
                const gzipped = gzip(new Uint8Array(uncompressed));
                const gzippedPath = record.filePath + '.gz';
                await this.fileIo.writeBinary(gzippedPath, gzipped.buffer);
                await this.fileIo.delete(record.filePath);
                record.filePath = gzippedPath;
                record.size = gzipped.byteLength;
                await this.backgroundYield();
            } catch (err) {
                this.pino.error(`Failed to compress log file ${record.filePath}: ${safeErrorString(err)}`);
            }
        }
    }

    private async purge(files: FileRecord[]) {
        const toDelete = files.splice(this.deleteCountThreshold);
        let totalSize = sum(files.map((rec) => rec.size));
        while (totalSize > this.deleteSizeThreshold) {
            const file = files.pop()!;
            totalSize -= file.size;
            toDelete.push(file);
        }
        for (const file of toDelete) {
            try {
                await this.fileIo.delete(file.filePath);
                await this.backgroundYield();
            } catch (err) {
                this.pino.error(`Failed to delete log file ${file.filePath}: ${safeErrorString(err)}`);
            }
        }
    }

    private backgroundYield() {
        // allow time for foreground tasks
        return this.backgroundYieldTime > 0 ? delay(this.backgroundYieldTime) : Promise.resolve();
    }
}
