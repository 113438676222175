import { InjectionToken } from '@angular/core';
import { LogFn } from 'pino';

export interface Logger {
    error: LogFn;
    warn: LogFn;
    info: LogFn;
    debug: LogFn;
}

export const LOGGER = new InjectionToken<Logger>('Logger instance');
