import { ParamRegistry } from '../services/param-registry';
import { LinearSlot } from './linear-slot';
import { ScalarParam } from './scalar-param';
import { Slot } from './slot';

export interface ParameterScalingTerm {
    param: string;
    exponent: number;
}

export type ScalingTerm = ParameterScalingTerm | number;

abstract class Scaler {
    abstract toScaledMultiplier: number | undefined;

    abstract bind(reg: ParamRegistry): void;
}

class ParameterScaler implements Scaler {
    private param: ScalarParam;

    constructor(private term: ParameterScalingTerm) {}

    get toScaledMultiplier(): number | undefined {
        const value = this.param ? this.param.slot.toEngrFloat(this.param.value) : undefined;
        if (typeof value === 'number') {
            return Math.pow(value, this.term.exponent);
        } else {
            return undefined;
        }
    }

    bind(reg: ParamRegistry) {
        this.param = reg.typedParamByKeyThrow(ScalarParam, this.term.param);
        reg.registerEarlyLoadParam(this.param);
    }
}

class ConstScaler implements Scaler {
    constructor(public toScaledMultiplier: number) {}

    bind(reg: ParamRegistry) {}
}

const makeScaler = (term: ScalingTerm): Scaler => {
    if (typeof term === 'number') {
        return new ConstScaler(term);
    } else {
        return new ParameterScaler(term);
    }
};

export class ScaledLinearSlot extends Slot {
    private scalers: Scaler[];

    // range tuples are min, max
    constructor(
        scalingTerms: ScalingTerm[],
        private linear: LinearSlot,
    ) {
        super(linear.unit, linear.precision, linear.base);
        this.scalers = scalingTerms.map((term) => makeScaler(term));
    }

    bind(reg: ParamRegistry) {
        this.scalers.map((scaler) => scaler.bind(reg));
    }

    toEngrFloat = (value: number | undefined): number | undefined => this.toScaled(this.linear.toEngrFloat(value));

    toEngrString = (value: number | undefined): string | undefined => {
        const floatValue = this.toEngrFloat(value) as number;
        if (isNaN(floatValue)) {
            return undefined;
        }
        if (this.base === 10) {
            return floatValue.toFixed(this.precision);
        } else {
            return floatValue.toString(this.base);
        }
    };

    toRawFloat = (value: number | string | undefined): number | undefined => {
        const engrValue = this.toBase(this.linear.engrNumOrStringToNum(value));
        if (engrValue === undefined || isNaN(engrValue)) {
            return undefined;
        }
        return this.linear.toRawFloat(engrValue);
    };

    rawInRange = (value: number | undefined): boolean => this.linear.rawInRange(value);

    engrInRange = (value: number | string | undefined): boolean => {
        const engrValue = this.toBase(this.linear.engrNumOrStringToNum(value));
        return this.linear.engrInRange(engrValue);
    };

    get rawMin() {
        return this.linear.rawMin;
    }
    get rawMax() {
        return this.linear.rawMax;
    }
    get rawStep() {
        return this.linear.rawStep;
    }
    get engrMin() {
        return this.toScaled(this.linear.engrMin) as number;
    }
    get engrMax() {
        return this.toScaled(this.linear.engrMax) as number;
    }
    get engrStep() {
        return this.linear.engrStep;
    }

    get rawRange(): [number, number] {
        return this.linear.rawRange;
    }
    set rawRange(value: [number, number]) {
        this.linear.rawRange = value;
    }

    get engrRange(): [number, number] {
        return this.linear.engrRange.map(this.toScaled) as [number, number];
    }
    set engrRange(value: [number, number]) {
        this.linear.engrRange = value.map(this.toBase) as [number, number];
    }

    private toScaled = (value: number | undefined): number | undefined => {
        if (value === undefined || isNaN(value)) {
            return undefined;
        }
        let scaled = value;
        for (const scaler of this.scalers) {
            const mult = scaler.toScaledMultiplier;
            if (mult === undefined) {
                return undefined;
            }
            scaled *= mult;
        }
        return isNaN(scaled) ? undefined : scaled;
    };

    private toBase = (value: number | undefined): number | undefined => {
        if (value === undefined || isNaN(value)) {
            return undefined;
        }
        let base = value;
        for (const scaler of this.scalers) {
            const mult = scaler.toScaledMultiplier;
            if (mult === undefined) {
                return undefined;
            }
            base /= mult;
        }
        return isNaN(base) ? undefined : base;
    };

    get rawIsInt() {
        return this.linear.rawIsInt;
    }
}
