import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { last } from "lodash-es";
import { DataProvider } from "../../providers/data-provider";
import {
    DividerLayoutEntry,
    LayoutEntry,
    SignalGroupProvider,
    SignalLayoutEntry,
} from "../../providers/signal-group-provider";
import {
    RangeMap,
    SignalSettingsProvider,
    StyleMap,
} from "../../providers/signal-settings-provider";
import { ConfigurationSaveComponent } from "./configuration-save.component";

export type SignalLayoutEntryWithoutSlot = Omit<SignalLayoutEntry, "slot">;

export type LayoutEntryWithoutSlot =
    | SignalLayoutEntryWithoutSlot
    | DividerLayoutEntry;

type StoredChartConfiguration = {
    [key: string]: {
        signalStyles: StyleMap;
        signalRanges: RangeMap;
        layoutEntriesWithoutSlot: LayoutEntryWithoutSlot[];
    };
};

@Component({
    selector: "app-tab-configurations",
    templateUrl: "tab-configurations.page.html",
})
export class TabConfigurationsPageComponent implements OnInit {
    fileNameToSave = "";
    configurationFileName = "";
    storedChartConfigurations: StoredChartConfiguration;
    chartConfigurationNames: string[] = [];
    @Output() applyConfiguration = new EventEmitter<void>();

    constructor(
        private alertCtrlr: AlertController,
        private dataProvider: DataProvider,
        private modalCtrlr: ModalController,
        private signalGroupProvider: SignalGroupProvider,
        private signalSettingsProvider: SignalSettingsProvider
    ) {}

    ngOnInit() {
        const chartConfigs = window.localStorage.getItem("chartConfigs");
        if (chartConfigs) {
            try {
                const value = JSON.parse(chartConfigs);
                if (typeof value === "object") {
                    this.chartConfigurationNames =
                        typeof value === "object" ? Object.keys(value) : [];
                    this.storedChartConfigurations = value;
                }
            } catch {}
        }
    }

    get isLoaded() {
        return !!this.dataProvider.data;
    }

    async openSaveModal() {
        const modal = await this.modalCtrlr.create({
            component: ConfigurationSaveComponent,
            componentProps: {
                chartConfigurationNames: this.chartConfigurationNames,
            },
        });
        modal.onDidDismiss().then((data) => {
            if (data.data?.filename) {
                this.fileNameToSave = data.data.filename;
                this.saveConfiguration();
            }
        });
        await modal.present();
    }

    saveConfiguration() {
        if (this.fileNameToSave) {
            const layoutEntries: LayoutEntry[] =
                this.signalGroupProvider.layout;
            const layoutEntriesWithoutSlot: LayoutEntryWithoutSlot[] =
                layoutEntries.map((entry) => {
                    if (entry.type === "signal") {
                        const { slot, ...rest } = entry;
                        return rest as SignalLayoutEntryWithoutSlot;
                    }

                    return entry;
                });

            const signalRanges = this.signalSettingsProvider.ranges;
            const signalStyles = this.signalSettingsProvider.computedStyles;
            const newConfig = {
                layoutEntriesWithoutSlot,
                signalRanges,
                signalStyles,
            };
            if (this.storedChartConfigurations) {
                this.storedChartConfigurations[this.fileNameToSave] = newConfig;
            } else {
                this.storedChartConfigurations = {
                    [this.fileNameToSave]: newConfig,
                };
            }
            this.writeConfigToFile();
        }
    }

    async openChartConfiguration(key: string) {
        this.signalGroupProvider.appliedConfigurationFileName = key;
        const selectedConfig = Object.assign(
            this.storedChartConfigurations[key]
        );
        const layoutEntries: LayoutEntry[] = [
            ...this.signalGroupProvider.layout,
        ];
        const newLayoutEntries: LayoutEntry[] = [];
        const newLayoutEntriesWithNoSlot: LayoutEntry[] = [];

        selectedConfig.layoutEntriesWithoutSlot.forEach(
            (layoutEntryWithoutSlot) => {
                if (layoutEntryWithoutSlot.type === "signal") {
                    const signalInLogDataIndex = layoutEntries.findIndex(
                        (e) => e.key === layoutEntryWithoutSlot.key
                    );
                    if (signalInLogDataIndex > -1) {
                        newLayoutEntries.push(
                            layoutEntries[signalInLogDataIndex]
                        );
                        layoutEntries.splice(signalInLogDataIndex, 1);
                    } else {
                        newLayoutEntriesWithNoSlot.push({
                            ...layoutEntryWithoutSlot,
                            slot: null,
                            data: [[0, 0]],
                        });
                    }
                } else {
                    newLayoutEntries.push(layoutEntryWithoutSlot);
                    newLayoutEntriesWithNoSlot.push(layoutEntryWithoutSlot);
                }
            }
        );

        const dividersOnly = newLayoutEntries.filter(
            (entry) => entry.type === "divider"
        );
        let lastDividerIndex = -1;
        if (dividersOnly.length) {
            lastDividerIndex = parseInt(
                dividersOnly[dividersOnly.length - 1].key.slice(-1)
            );
        }

        if (layoutEntries.length) {
            if (last(newLayoutEntries)?.type !== "divider") {
                lastDividerIndex += 1;
                newLayoutEntries.push({
                    key: `divider${lastDividerIndex}`,
                    type: "divider",
                });
            }
            let lastEntryIsDivider = false;
            layoutEntries.forEach((entry) => {
                if (entry.type === "signal") {
                    newLayoutEntries.push(entry);
                    lastEntryIsDivider = false;
                } else {
                    if (!lastEntryIsDivider) {
                        lastDividerIndex += 1;
                        newLayoutEntries.push({
                            key: `divider${lastDividerIndex}`,
                            type: "divider",
                        });
                        lastEntryIsDivider = true;
                    }
                }
            });
        }
        this.removeLastEntryIfDivider(newLayoutEntries);
        this.removeLastEntryIfDivider(newLayoutEntriesWithNoSlot);

        this.applyChartConfiguration(
            newLayoutEntries,
            newLayoutEntriesWithNoSlot,
            selectedConfig
        );
    }

    removeLastEntryIfDivider(entries: LayoutEntry[]) {
        do {
            entries.pop();
        } while (last(entries)?.type === "divider");
    }

    applyChartConfiguration(
        newLayoutEntries: LayoutEntry[],
        newLayoutEntriesWithNoSlot: LayoutEntry[],
        selectedConfig: {
            signalStyles: StyleMap;
            signalRanges: RangeMap;
            layoutEntriesWithoutSlot: LayoutEntryWithoutSlot[];
        }
    ) {
        this.signalGroupProvider.layout = newLayoutEntries;
        this.signalGroupProvider.layoutNoSlot = newLayoutEntriesWithNoSlot;
        const signalRangesKeys = Object.keys(selectedConfig.signalRanges);
        for (const key of signalRangesKeys) {
            this.signalSettingsProvider.setRange(
                key,
                selectedConfig.signalRanges[key]
            );
        }
        const signalStylesKeys = Object.keys(selectedConfig.signalStyles);
        for (const key of signalStylesKeys) {
            this.signalSettingsProvider.setStyle(
                key,
                selectedConfig.signalStyles[key]
            );
        }

        this.applyConfiguration.emit();
    }

    writeConfigToFile() {
        const newConfigStr = JSON.stringify(this.storedChartConfigurations);
        window.localStorage.setItem("chartConfigs", newConfigStr);
        this.chartConfigurationNames = Object.keys(
            this.storedChartConfigurations
        );
        this.fileNameToSave = "";
    }

    async confirmDeleteChartConfiguration(key: string) {
        const alert = await this.alertCtrlr.create({
            header: "Confirm Delete Configuration",
            message: `File name: ${key}`,
            buttons: [
                {
                    text: "Cancel",
                    role: "cancel",
                    handler: () => {},
                },
                {
                    text: "OK",
                    role: "confirm",
                    handler: () => {
                        this.deleteChartConfiguration(key);
                    },
                },
            ],
        });
        await alert.present();
    }

    deleteChartConfiguration(key: string) {
        delete this.storedChartConfigurations[key];
        this.writeConfigToFile();
    }
}
