import { Component } from "@angular/core";
import { ModalController, PopoverController } from "@ionic/angular";
import { arrayMax } from "../../hgm-setup/src/module/util/core";
import { DataProvider } from "../../providers/data-provider";
import {
    LayoutEntry,
    SignalGroupProvider,
} from "../../providers/signal-group-provider";
import { AddSignalComponent } from "./add-signal.component";
import { EditSignalComponent } from "./edit-signal.component";

const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.includes("safari") && !ua.includes("chrom");
};

@Component({
    selector: "app-tab-signals",
    templateUrl: "tab-signals.page.html",
})
export class TabSignalsPageComponent {
    constructor(
        private dataProvider: DataProvider,
        private modalCtrlr: ModalController,
        private popoverCtrlr: PopoverController,
        private signalGroupProvider: SignalGroupProvider
    ) {}

    get dataError() {
        return this.dataProvider.result?.error;
    }

    get isLoaded() {
        return !!this.dataProvider.data;
    }

    get layout() {
        return this.signalGroupProvider.layout;
    }

    async addSignal(event: MouseEvent | TouchEvent) {
        const popover = await this.popoverCtrlr.create({
            component: AddSignalComponent,
            event,
        });
        await popover.present();
    }

    async editSignal(signal: LayoutEntry) {
        const popover = await this.modalCtrlr.create({
            component: EditSignalComponent,
            componentProps: { signal },
            cssClass: isSafari() ? undefined : "responsive-modal",
        });
        await popover.present();
    }

    async addDivider() {
        const existingKeyIndices = this.signalGroupProvider.layout
            .filter((i) => i.type === "divider")
            .map((i) => i.key)
            .map((key) => parseInt(key.replace("divider", ""), 10));
        const newIndex = arrayMax(existingKeyIndices) + 1;
        const newLayout = this.signalGroupProvider.layout.slice();
        newLayout.unshift({ type: "divider", key: "divider" + newIndex });
        this.signalGroupProvider.layout = newLayout;
    }

    removeEntry(entry: LayoutEntry) {
        this.signalGroupProvider.layout =
            this.signalGroupProvider.layout.filter((e) => e !== entry);
    }

    reset() {
        this.signalGroupProvider.reset();
    }

    onReorder(event: any) {
        const newEntries = this.signalGroupProvider.layout.slice();
        newEntries.splice(
            event.detail.to,
            0,
            ...newEntries.splice(event.detail.from, 1)
        );
        this.signalGroupProvider.layout = newEntries;
        event.detail.complete();
    }
}
