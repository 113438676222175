import { Injectable } from "@angular/core";
import { uniq } from "lodash-es";
import { BehaviorSubject, combineLatest } from "rxjs";
import { EncodingSlot } from "../hgm-setup/src/module/param/core/encoding-slot";
import {
    Log,
    LogFile,
    LogHeader,
} from "../hgm-setup/src/module/param/core/logging-types";
import { ScalarWrapper } from "../hgm-setup/src/module/param/core/scalar-wrapper";
import { Slot } from "../hgm-setup/src/module/param/core/slot";
import { VectorParam } from "../hgm-setup/src/module/param/core/vector-param";
import { DisplaySlotLocalizer } from "../hgm-setup/src/module/param/services/display-slot-localizer";
import { ParamDefDisplaySlotLocalizer } from "../hgm-setup/src/module/param/services/param-def-display-slot-localizer";
import { ParamRegistry } from "../hgm-setup/src/module/param/services/param-registry";
import { LoggingParamDef } from "../hgm-setup/src/module/param/util/param-system-def-types";
import { LogFileProvider } from "../hgm-setup/src/module/util/log-file-provider";
import { TranslationManager } from "../hgm-setup/src/module/util/translation-manager";

export interface Signal {
    key: string;
    name: string;
    unit: string;
    data: [number, number][]; // localized units
    slot: Slot;
}

export interface SignalNoData {
    key: string;
    name: string;
    unit: string;
    data: [number, number][]; // localized units
    hidden?: boolean;
}

export interface DataSet {
    zeroDate: Date;
    signals: { [key: string]: Signal };
}

interface ParamSignalParam {
    key: string;
    slot: Slot;
}

interface ParamSignal {
    param: ParamSignalParam;
    name: string;
    data: [number, number][]; // raw units
}

interface ParamDataSet {
    zeroDate: Date;
    signals: { [key: string]: ParamSignal };
}

const dtcRegex = new RegExp(/^([PCBU])([0-3][0-9A-F]{3})$/);
const dtcSlot = new EncodingSlot(
    [
        [0, "Clear"],
        [1, "Set"],
    ],
    undefined,
    undefined
);

export interface OkResult {
    paramSet: ParamDataSet;
    file: LogFile;
    error?: undefined;
}

export interface ErrResult<T> {
    paramSet?: undefined;
    file: LogFile;
    error: any;
}

type Kvt = Log["kvt"][0];
type ValueTime = Pick<Kvt, "value" | "time">;

const makeKvtMap = (kvts: Kvt[]) => {
    const map = new Map<string, ValueTime[]>();

    for (const kvt of kvts) {
        if (!map.has(kvt.key)) {
            map.set(kvt.key, []);
        }
        map.get(kvt.key)!.push({ value: kvt.value, time: kvt.time });
    }

    return map;
};

const parseValueTime = (
    slot: Slot,
    vts: ValueTime[],
    logTime: number
): [number, number][] =>
    vts.map((vt) => [
        parseFloat(vt.time as string) - logTime,
        slot.toRaw(vt.value) ?? NaN,
    ]);

@Injectable({ providedIn: "root" })
export class DataProvider {
    private resultSubject = new BehaviorSubject<
        OkResult | ErrResult<unknown> | undefined
    >(undefined);
    private dataSubject = new BehaviorSubject<DataSet | undefined>(undefined);

    constructor(
        private dsl: DisplaySlotLocalizer,
        logProvider: LogFileProvider,
        private paramRegistry: ParamRegistry,
        private translationManager: TranslationManager
    ) {
        logProvider.regReadyObs.subscribe({ next: this.onFile });
        combineLatest([
            this.resultSubject,
            this.paramDsl.localizationsChangeObs,
        ]).subscribe({ next: this.update });
    }

    get result() {
        return this.resultSubject.value;
    }

    get resultObs() {
        return this.resultSubject.asObservable();
    }

    get data() {
        return this.dataSubject.value;
    }

    get dataObs() {
        return this.dataSubject.asObservable();
    }

    update = () => {
        if (!this.resultSubject.value?.paramSet) {
            this.dataSubject.next(undefined);
            return;
        }

        const paramSet = this.resultSubject.value.paramSet;
        const set: DataSet = { zeroDate: paramSet.zeroDate, signals: {} };
        for (const key of Object.keys(paramSet.signals)) {
            const paramSignal = paramSet.signals[key];
            const slot = this.dsl.localize(paramSignal.param.slot);
            set.signals[key] = {
                key,
                name: paramSignal.name,
                unit: slot.unit,
                data: paramSignal.data.map((d) => [
                    d[0],
                    slot.toEngrFloat(d[1]) ?? NaN,
                ]),
                slot,
            };
        }
        this.dataSubject.next(set);
    };

    onFile = (file: LogFile) => {
        if (!file) {
            this.resultSubject.next(undefined);
            return;
        }

        try {
            this.loadEarlyParams(file.log.header.earlyLoadParams);

            const baseLogDate = new Date(file.log.header.logDate);
            const zeroDate = new Date(
                baseLogDate.getTime() - file.log.header.logTime
            );

            const paramSet = { zeroDate, signals: this.getSignals(file.log) };

            this.resultSubject.next({ file, paramSet });
        } catch (error) {
            this.resultSubject.next({ file, error });
        }
    };

    private get paramDsl() {
        return this.dsl as ParamDefDisplaySlotLocalizer;
    }

    private loadEarlyParams(params: LogHeader["earlyLoadParams"]) {
        for (const [key, value] of Object.entries(params)) {
            const param = this.paramRegistry.paramByKeyWarn(key);
            if (!param) {
                continue;
            }
            param.setSerializableValue(value);
        }
    }

    private getSignals(log: Log) {
        const kvtMap = makeKvtMap(log.kvt);
        if (log.kvt.length === 0) {
            return {};
        }
        const signals: ParamDataSet["signals"] = {};

        // handle regular scalar params and string params
        for (const def of log.header.logDefs) {
            try {
                const vts = kvtMap.get(def.param) || [];
                const param =
                    this.parseStringParam(def, vts) ||
                    new ScalarWrapper(this.paramRegistry, def.param);
                const name = this.translationManager.translate(
                    def.label,
                    "traceLabel"
                );
                const data = parseValueTime(
                    param.slot,
                    vts,
                    log.header.logTime
                );
                signals[def.param] = { param, name, data };
            } catch (err) {
                console.warn(
                    `Could not make scalar/string wrapper for ${def.param}`
                );
            }
        }

        // now look for any DTCs that need a fake param
        const dataStartTime = log.kvt.length
            ? parseFloat(log.kvt[0].time as string) - log.header.logTime
            : NaN;
        for (const [key, vts] of kvtMap.entries()) {
            if (!signals[key] && key.match(dtcRegex)) {
                let data = parseValueTime(dtcSlot, vts, log.header.logTime);

                const approxPeriod =
                    data.length > 1 ? data[1][0] - data[0][0] : 1e-6;
                const dtcLastClearTime = data[0][0] - approxPeriod;
                if (dataStartTime < dtcLastClearTime) {
                    // create clear state from log start to when DTC was first recorded
                    data = [[dataStartTime, 0], [dtcLastClearTime, 0], ...data];
                }

                signals[key] = {
                    param: { key, slot: dtcSlot },
                    name: key,
                    data,
                };
            }
        }

        return signals;
    }

    private parseStringParam(
        def: LoggingParamDef,
        vts: ValueTime[]
    ): ParamSignalParam | undefined {
        const vector = this.paramRegistry.typedParamByKeyTry(
            VectorParam,
            def.param
        );
        if (vector && vts.every((vt) => typeof vt.value === "string")) {
            // looks like a string param, generate an encoding SLOT for it
            const strings = uniq(vts.map((vt) => vt.value)).sort();
            const encoding = Array.from(strings.entries());
            const slot = new EncodingSlot(encoding, undefined, undefined);

            return { key: def.param, slot };
        }
        return undefined;
    }
}
