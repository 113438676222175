import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { ConstructorType, truthyOnly, valueOr } from '../../util/core';
import { LOGGER, Logger } from '../../util/logger';
import { Param, ParamAcl } from '../core/param';
import { ScalarParam } from '../core/scalar-param';
import { DataType, Slot } from '../core/slot';
import { VectorParam } from '../core/vector-param';
import { ParamDef, ParamEffectiveAcl, ParamSystemDefProvider, ParamSystemDefRecord, flattenParamDefs } from '../util/param-system-def';
import { SlotRegistry } from './slot-registry';

@Injectable()
export abstract class ParamAddrCalculator {
    abstract addr(def: ParamDef): any;
    abstract type(def: ParamDef): [DataType, boolean];
}

export class StandardParamAddrCalculator extends ParamAddrCalculator {
    private static hexRegex = /^0[xX]/;

    addr(def: ParamDef): any {
        return StandardParamAddrCalculator.numberId(def) * 0x10000;
    }

    type(_def: ParamDef): [DataType, boolean] {
        return [DataType.int32, true]; // signed 32 bit little endian
    }

    private static numberId(def: ParamDef) {
        if (typeof def.id === 'number') {
            return def.id;
        } else {
            const radix = StandardParamAddrCalculator.hexRegex.test(def.id) ? 16 : 10;
            return parseInt(def.id, radix);
        }
    }
}

export interface ParamRegPageLike {
    registerParam: (param: Param | undefined, autorefresh: boolean, forDisplay?: boolean) => void;
}

@Injectable({ providedIn: 'root' })
export class ParamRegistry {
    accessLevel = 1; // app has responsibility to know target policy and update this accordingly

    private readySubj = new BehaviorSubject<ParamSystemDefRecord | undefined>(undefined);
    private slotRegistry: SlotRegistry;

    private paramsMap = new Map<string, Param>();
    private resetAdvisedParamsInt = new Array<Param>();
    private personalityParamsInt = new Array<Param>();
    private earlyLoadParamsSet = new Set<Param>();
    private writeCacheDirtyParamsSet = new Set<Param>();
    private writeCacheDirtySubject = new BehaviorSubject<boolean>(false);
    private paramNames = new Map<Param, string>();

    constructor(
        @Inject(LOGGER) private logger: Logger,
        private addrCalc: ParamAddrCalculator,
        private defProvider: ParamSystemDefProvider,
    ) {}

    // Scaled linear slots mean SLOT reg depends on param reg, but param reg depends on SLOT reg to set up.
    // This creates a circular dependency, which the injector cannot handle; break the loop with a runtime call.
    init(slotReg: SlotRegistry) {
        this.slotRegistry = slotReg;
    }

    onSlotRegReady(rec: ParamSystemDefRecord) {
        this.paramsMap = new Map<string, Param>();
        this.resetAdvisedParamsInt = new Array<Param>();
        this.personalityParamsInt = new Array<Param>();
        this.earlyLoadParamsSet = new Set<Param>();
        this.writeCacheDirtyParamsSet = new Set<Param>();
        this.paramNames = new Map<Param, string>();
        this.writeCacheDirtySubject.next(false);

        Object.entries(flattenParamDefs(rec.def.params)).map((e) => this.addParam(e[0], e[1]));

        this.readySubj.next(rec);
    }

    ready(): Observable<ParamSystemDefRecord> {
        return this.readySubj.pipe(truthyOnly());
    }

    get readyAndMatchesProvider(): Promise<void> {
        return this.readySubj
            .pipe(
                filter((rec) => rec === this.defProvider.value),
                map(() => {}),
                first(),
            )
            .toPromise();
    }

    registerEarlyLoadParam(param: Param) {
        this.earlyLoadParamsSet.add(param);
    }

    isReadable(param: { acl: ParamAcl }): boolean {
        return param.acl.read[this.accessLevel];
    }

    isWritable(param: { acl: ParamAcl }): boolean {
        return param.acl.write[this.accessLevel];
    }

    getEffectiveAcl(param: { acl: ParamAcl }): ParamEffectiveAcl {
        return {
            ...param.acl,
            read: this.isReadable(param),
            write: this.isWritable(param),
        };
    }

    paramByKeyTry = (key: string): Param | undefined => this.paramsMap.get(key);

    paramByKeyWarn = (key: string): Param | undefined => {
        const param = this.paramsMap.get(key);
        if (!param) {
            this.logger.warn(`Parameter with key ${key} does not exist`);
        }
        return param;
    };

    paramByKeyThrow = (key: string): Param => {
        const param = this.paramsMap.get(key);
        if (!param) {
            throw `Parameter with key ${key} does not exist`;
        }
        return param;
    };

    get params(): IterableIterator<Param> {
        return this.paramsMap.values();
    }

    get paramsArray(): Array<Param> {
        return Array.from(this.paramsMap.values());
    }

    get earlyLoadParams(): Array<Param> {
        return Array.from(this.earlyLoadParamsSet.values());
    }

    get setupParams(): Array<Param> {
        return this.paramsArray.filter((param) => valueOr<boolean>(param.acl.saveWithCalib, param.acl.save) && this.isReadable(param));
    }

    get unreadSetupParams(): Array<Param> {
        const paramsToRead: Param[] = [];
        for (const param of this.setupParams) {
            if (param.existsOnTarget === undefined) {
                paramsToRead.push(param);
            }
        }
        return paramsToRead;
    }

    get resetAdvisedParams(): Array<Param> {
        return this.resetAdvisedParamsInt;
    }

    get personalityParams(): Array<Param> {
        return this.personalityParamsInt;
    }

    get writeCacheDirtyParams(): IterableIterator<Param> {
        return this.writeCacheDirtyParamsSet.values();
    }

    get writeCacheDirtyObs(): Observable<boolean> {
        return this.writeCacheDirtySubject.asObservable();
    }

    get writeCacheDirty(): boolean {
        return this.writeCacheDirtySubject.getValue();
    }

    clearTargetParamValues(): void {
        for (const param of this.params) {
            param.clearTargetBytes(true);
        }
    }

    setParamName(param: Param, name: string) {
        this.paramNames.set(param, name);
    }

    getParamName = (param: Param) => {
        const name = this.paramNames.get(param);
        if (typeof name === 'string') {
            return name;
        } else {
            return param.key;
        }
    };

    getParamAndSlotFromArgs<ParamType>(
        paramCtor: ConstructorType<ParamType>,
        args: { param: string; slot?: string; label: string },
    ): [ParamType, Slot] {
        const param = this.paramByKeyTry(args.param);
        if (!param || !(param instanceof paramCtor)) {
            throw new Error('Key ' + args.param + ' does not map to an appropriate param');
        }
        this.setParamName(param, args.label);

        const slot = args.slot ? this.slotRegistry.slotByKey(args.slot) : param.slot;
        if (!slot) {
            throw new Error(`Args ${args} does not find a SLOT`);
        }

        return [param, slot];
    }

    typedParamByKeyThrow<ParamType>(paramCtor: ConstructorType<ParamType>, key: string): ParamType {
        const param = this.paramByKeyTry(key);
        if (!(param instanceof paramCtor)) {
            throw new Error('Key ' + key + ' does not map to an appropriate param');
        }
        return param;
    }

    typedParamByKeyTry<ParamType>(paramCtor: ConstructorType<ParamType>, key: string): ParamType | undefined {
        try {
            return this.typedParamByKeyThrow(paramCtor, key);
        } catch {
            return undefined;
        }
    }

    typedParamByKeyWarn<ParamType>(paramCtor: ConstructorType<ParamType>, key: string): ParamType | undefined {
        try {
            return this.typedParamByKeyThrow(paramCtor, key);
        } catch {
            this.logger.warn(`Parameter with key ${key} does not exist`);
            return undefined;
        }
    }

    private addParam(key: string, def: ParamDef) {
        if (!def) {
            throw new Error('');
        }
        const slot = def.slot ? this.slotRegistry.slotByKey(def.slot) : undefined;
        let param: Param;
        if (def.length) {
            const isFullReload = valueOr(def.fullReload, false);
            param = new VectorParam(
                key,
                def.acl,
                def.advice,
                isFullReload,
                slot,
                this.addrCalc.addr(def),
                this.addrCalc.type(def),
                def.minLength || def.length,
                def.length,
            );
        } else {
            param = new ScalarParam(key, def.acl, def.advice, slot, this.addrCalc.addr(def), this.addrCalc.type(def));
        }
        this.paramsMap.set(key, param);
        param.writeCacheDirtyObs.subscribe({ next: () => this.onParamWriteCacheDirtyChanged(param) });
        if (param.isResetAdvised) {
            this.resetAdvisedParamsInt.push(param);
        }
        if (param.isPersonalityParam) {
            this.personalityParamsInt.push(param);
        }
    }

    private onParamWriteCacheDirtyChanged(param: Param) {
        if (param.writeCacheDirty) {
            this.writeCacheDirtyParamsSet.add(param);
        } else {
            this.writeCacheDirtyParamsSet.delete(param);
        }
        const dirty: boolean = this.writeCacheDirtyParamsSet.size > 0;
        if (dirty !== this.writeCacheDirtySubject.getValue()) {
            this.writeCacheDirtySubject.next(dirty);
        }
    }
}
