import { Injectable } from '@angular/core';
import { camelCase, range } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { truthyOnly } from '../../util/core';
import { EncodingSlot } from '../core/encoding-slot';
import { J2012Slot } from '../core/j2012-slot';
import { LinearSlot } from '../core/linear-slot';
import { RadixSlot } from '../core/radix-slot';
import { ScaledLinearSlot } from '../core/scaled-linear-slot';
import { Slot } from '../core/slot';
import {
    EncodingSlotDef,
    HexSlotDef,
    J2012SlotDef,
    LinearSlotDef,
    ParamSystemDefProvider,
    ParamSystemDefRecord,
    SlotDef,
} from '../util/param-system-def';
import { ParamRegistry } from './param-registry';

@Injectable({ providedIn: 'root' })
export class SlotRegistry {
    private slots = new Map<string, Slot>();
    private readySubj = new BehaviorSubject<ParamSystemDefRecord | undefined>(undefined);
    private paramRegIsBusy = false;
    private defQueue: ParamSystemDefRecord[] = [];

    constructor(defProvider: ParamSystemDefProvider, paramReg: ParamRegistry) {
        // Scaled linear slots mean SLOT reg depends on param reg, but param reg depends on SLOT reg to set up.
        // This creates a circular dependency, which the injector cannot handle; break the loop with a runtime call.
        paramReg.init(this);

        defProvider.obs.subscribe({
            next: (r: ParamSystemDefRecord) => {
                this.defQueue.push(r);
                if (this.paramRegIsBusy) {
                    return;
                }
                while (this.defQueue.length) {
                    const rec = this.defQueue.shift() as ParamSystemDefRecord;
                    this.paramRegIsBusy = true;
                    try {
                        this.slots = new Map<string, Slot>();
                        for (const key of Object.keys(rec.def.slots)) {
                            this.addSlot(key, rec.def.slots[key]);
                        }
                        for (const l10n of rec.def.slotLocalizations || []) {
                            const key = camelCase('display slot selection' + l10n.name);
                            const encoding = range(l10n.selections.length).map((i) => [i, l10n.selections[i].name] as [number, string]);
                            this.slots.set(key, new EncodingSlot(encoding, undefined, undefined));
                        }
                        paramReg.onSlotRegReady(rec);
                        for (const slot of this.slots.values()) {
                            if (slot instanceof ScaledLinearSlot) {
                                slot.bind(paramReg);
                            }
                        }
                        defProvider.onParsed({ err: null, def: rec });
                        this.readySubj.next(rec);
                    } catch (err) {
                        defProvider.onParsed({ err, def: rec });
                    } finally {
                        this.paramRegIsBusy = false;
                    }
                }
            },
        });
    }

    ready(): Observable<ParamSystemDefRecord> {
        return this.readySubj.pipe(truthyOnly());
    }

    slotByKey = (key: string | undefined): Slot => {
        const slot = this.slots.get(key || '');
        if (!slot) {
            throw new Error('Key ' + key + ' does not map to a SLOT');
        }
        return slot;
    };

    keyFromSlot = (slot: Slot): string | undefined => {
        for (const entry of this.slots.entries()) {
            if (entry[1] === slot) {
                return entry[0];
            }
        }
        return undefined;
    };

    private addSlot(key: string, def: SlotDef) {
        const linear = (def as LinearSlotDef).linear;
        if (linear) {
            let slot: Slot = new LinearSlot(linear);
            if (linear.scalers) {
                slot = new ScaledLinearSlot(linear.scalers, slot as LinearSlot);
            }
            this.slots.set(key, slot);
            return;
        }

        const encoding = (def as EncodingSlotDef).encoding;
        if (encoding) {
            this.slots.set(key, new EncodingSlot(encoding, undefined, undefined));
            return;
        }

        const hex = (def as HexSlotDef).hex;
        if (hex) {
            this.slots.set(key, new RadixSlot(hex.digits, 0xffffffff, 16));
            return;
        }

        const j2012 = (def as J2012SlotDef).j2012;
        if (j2012) {
            this.slots.set(key, new J2012Slot());
            return;
        }
    }
}
