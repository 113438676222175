import { padStart } from 'lodash';
import { Slot } from './slot';

const parseRegex = new RegExp(/^([PCBU])([0-3][0-9A-F]{3})$/);
const highMap = ['P', 'C', 'B', 'U'];

export class J2012Slot extends Slot {
    constructor() {
        super('', 0, 16);
    }

    toEngrFloat = (value: number | undefined): number | undefined => {
        if (typeof value === 'number') {
            return value;
        } else {
            return undefined;
        }
    };

    toEngrString = (value: number | undefined): string | undefined => {
        if (typeof value === 'number' && this.rawInRange(value)) {
            return highMap[value >> 14] + padStart((value & 0x3fff).toString(16).toUpperCase(), 4, '0');
        } else {
            return undefined;
        }
    };

    toRawFloat = (value: number | string | undefined): number | undefined => {
        if (typeof value === 'string') {
            const match = parseRegex.exec(value.toUpperCase()) || [];
            if (match[1] && match[2]) {
                const low = parseInt(match[2], 16);
                const high = highMap.indexOf(match[1]);
                if (low >= 0 && low <= 0x3fff && high >= 0 && high <= 3) {
                    return low | (high << 14);
                }
            }
        } else if (value !== undefined && !isNaN(+value)) {
            return +value;
        }
        return undefined;
    };

    rawInRange = (value: number | undefined): boolean => {
        if (typeof value === 'number') {
            return value >= 0 && value <= 0xffff;
        } else {
            return false;
        }
    };

    engrInRange = (value: number | string | undefined): boolean => {
        if (typeof value === 'number') {
            return this.rawInRange(value);
        } else if (typeof value === 'string') {
            return this.rawInRange(+value);
        } else {
            return false;
        }
    };

    get rawMin() {
        return 0;
    }
    get rawMax() {
        return 0xffff;
    }
    get rawStep() {
        return 1;
    }
    get engrMin() {
        return 0;
    }
    get engrMax() {
        return 0xffff;
    }
    get engrStep() {
        return 1;
    }
    get rawIsInt() {
        return true;
    }
}
