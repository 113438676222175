import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Param, ParamAcl, ParamAdvice } from './param';
import { DataType, Slot, getDataTypeLength } from './slot';
import { VectorModel } from './vector-model';

export class VectorParam extends Param implements VectorModel {
    private valueSubject: BehaviorSubject<Array<number>> = new BehaviorSubject([]);

    constructor(
        key: string,
        acl: ParamAcl,
        advice: ParamAdvice | undefined,
        fullReload: boolean,
        slot: Slot | void,
        addr: any,
        dataType: [DataType, boolean], // dataType second member is isLittleEndian
        public minCount: number,
        public maxCount: number,
    ) {
        super(key, acl, advice, fullReload, slot, addr, dataType, getDataTypeLength(dataType[0]) * maxCount);
    }

    get valueObs(): Observable<Array<number>> {
        return this.valueSubject.asObservable();
    }

    get value(): Array<number> {
        return this.valueSubject.value;
    }

    set value(value: Array<number>) {
        this.setHostDataArray(value);
        this.clearHostDataFrom(value.length);
    }

    getMember(index: number): number | undefined {
        return this.valueSubject.value[index];
    }

    setMember(index: number, value: number | undefined) {
        if (typeof value === 'number') {
            return this.setHostData(value, index);
        } else {
            return this.clearHostDataFrom(index);
        }
    }

    get minSize(): number {
        return this.dataTypeSize * this.minCount;
    }

    get maxSize(): number {
        return this.dataTypeSize * this.maxCount;
    }

    get size(): number {
        return this.count * this.dataTypeSize;
    }

    get targetSize(): number {
        return this.acl.mutating ? 0 : Math.floor(this.numTargetBytesLoaded / this.dataTypeSize) * this.dataTypeSize;
    }

    get count(): number {
        return Math.floor(this.numHostBytesLoaded / this.dataTypeSize);
    }

    get countObs(): Observable<number> {
        return this.numHostBytesLoadedObs.pipe(map((bytesLoaded) => Math.floor(bytesLoaded / this.dataTypeSize)));
    }

    getSerializableRawValue(): Array<number> {
        if (!this.existsOnHost) {
            return [];
        }
        const value = Array<number>(this.count);
        for (let i = 0; i < this.count; ++i) {
            value[i] = this.getHostData(i) as number;
        }
        return value;
    }

    getSerializableValue() {
        return this.getSerializableRawValue().map(this.slot.toEngrFloat);
    }

    getSerializableStringValue() {
        return this.getSerializableRawValue().map(this.slot.toEngrString);
    }

    setSerializableRawValue(value: any): boolean {
        if (value instanceof Array) {
            for (let i = 0; i < value.length; ++i) {
                if (this.slot.rawInRange(value[i])) {
                    this.setHostData(value[i], i);
                } else {
                    return false;
                }
            }
            this.clearHostDataFrom(value.length);
            return true;
        } else {
            return false;
        }
    }

    setSerializableValue(value: any): boolean {
        if (value instanceof Array) {
            for (let i = 0; i < value.length; ++i) {
                if ((typeof value[i] === 'number' || typeof value[i] === 'string') && this.slot.rawInRange(value[i])) {
                    this.setHostData(this.slot.toRaw(value[i]) as number, i);
                } else {
                    return false;
                }
            }
            this.clearHostDataFrom(value.length);
            return true;
        } else {
            return false;
        }
    }

    protected updateFromHostBytes(begin: number, end: number) {
        this.updateValues(Math.floor(begin / this.dataTypeSize), Math.ceil(end / this.dataTypeSize));
    }

    private updateValues(begin: number, end: number): void {
        const valueArray = this.valueSubject.value.slice();
        for (let i = begin; i < end; ++i) {
            const rawValue: number | undefined = this.getHostData(i);
            if (typeof rawValue === 'undefined') {
                valueArray.splice(i);
                break;
            }
            valueArray[i] = rawValue;
        }
        this.valueSubject.next(valueArray);
    }
}
