import {
    AfterContentInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Platform } from '@ionic/angular';
import { Chart, LinearScaleOptions } from 'chart.js';
import { DeepPartial } from 'chart.js/types/utils';
import { BehaviorSubject, Subscription } from 'rxjs';
import { colorToRgb } from '../../hgm-setup/src/module/util/core';
import { GlobalSettingsProvider } from '../../providers/global-settings-provider';
import { ThemeManager } from '../../providers/theme-manager';
import { xTickCallback } from '../../providers/util';
import { VfParams, XAxisManager, XLimits } from '../../providers/x-axis-manager';

type LinearScaleFullOptions = DeepPartial<LinearScaleOptions> & { type: 'linear' };
interface LinearScales { [key: string]: LinearScaleFullOptions };

interface Styles {
    backgroundColor: string;
    backgroundColorRgb: string;
    fontFamily: string;
    textColor: string;
    step600Color: string;
    textColorRgb: string;
    gridlineColor: string;
    zeroGridlineColor: string;
    overlayColor: string;
}

const mouseTouch = Symbol();

interface DraggerParams {
    startValue: number;
    handleHitWidth: number;
    minTimeSpan: number;
    dragSpan: [number, number];
    origLimits: [number, number];
    setLimits: (limits: [number, number]) => void;
};

class Dragger {
    isValid = true;

    private target: [boolean, boolean];
    private deltaRange: [number, number];

    constructor(private p: DraggerParams) {
        if (p.startValue >= p.origLimits[0] - p.handleHitWidth && p.startValue < p.origLimits[0]) {
            this.target = [true, false];
            this.deltaRange = [
                p.dragSpan[0] - p.origLimits[0],
                p.origLimits[1] - p.minTimeSpan - p.origLimits[0],
            ];
        }
        else if (p.startValue > p.origLimits[1] && p.startValue <= p.origLimits[1] + p.handleHitWidth) {
            this.target = [false, true];
            this.deltaRange = [
                p.origLimits[0] + p.minTimeSpan - p.origLimits[1],
                p.dragSpan[1] - p.origLimits[1],
            ];
        }
        else if (p.startValue >= p.origLimits[0] && p.startValue <= p.origLimits[1]) {
            this.target = [true, true];
            this.deltaRange = [
                p.dragSpan[0] - p.origLimits[0],
                p.dragSpan[1] - p.origLimits[1],
            ];
        }
        else {
            this.isValid = false;
        }
    }

    onMove(value: number) {
        const delta = Math.min(Math.max(value - this.p.startValue, this.deltaRange[0]), this.deltaRange[1]);
        this.p.setLimits([
            this.p.origLimits[0] + (this.target[0] ? delta : 0),
            this.p.origLimits[1] + (this.target[1] ? delta : 0),
        ]);
    }

    conflictsWith(other: Dragger) {
        return (this.target[0] && other.target[0]) || (this.target[1] && other.target[1]);
    }
}

const strokeLine = (
    ctx: CanvasRenderingContext2D,
    beginX: number,
    beginY: number,
    endX: number,
    endY: number,
    cap?: CanvasLineCap
) => {
    const oldCap = ctx.lineCap;
    ctx.lineCap = cap ?? oldCap;
    ctx.beginPath();
    ctx.moveTo(beginX, beginY);
    ctx.lineTo(endX, endY);
    ctx.stroke();
    ctx.lineCap = oldCap;
};

@Component({
    selector: 'viewfinder-chart',
    templateUrl: 'viewfinder-chart.component.html',
})
export class ViewfinderChartComponent implements OnInit, AfterContentInit, OnDestroy {
    @Input() shrink = false;
    @Output() isUpdating = new EventEmitter<boolean>();

    styles: Styles;

    private subscriptions: Subscription[] = [];
    private vf: Chart | undefined;
    @ViewChild('main', { static: true }) private main: ElementRef<HTMLElement>;
    @ViewChild('vfCanvas', { static: true }) private vfCanvas: ElementRef<HTMLCanvasElement>;
    @ViewChild('overlayCanvas', { static: true }) private overlayCanvas: ElementRef<HTMLCanvasElement>;
    private dragVf = new Map<number | typeof mouseTouch, Dragger>();
    private dragCoarse = new Map<number | typeof mouseTouch, Dragger>();
    private isMobile = false;
    private frameHandlerMousemove: MouseEvent | undefined;
    private frameHandlerTouchmoves = new Map<number, Touch>();
    private coarseLimitsLiveSubj = new BehaviorSubject<XLimits | undefined>(undefined);
    private viewfinderHeight: number;
    private coarseAdjustHeight: number;
    private resizeObserver: ResizeObserver;

    constructor(
        private elementRef: ElementRef,
        private settings: GlobalSettingsProvider,
        zone: NgZone,
        platform: Platform,
        private themer: ThemeManager,
        private xAxisMgr: XAxisManager,
    ) {
        this.isMobile = platform.is('mobile');
        this.resizeObserver = new ResizeObserver(() => zone.run(this.updateHeights));
    }

    ngOnInit() {
        this.updateHeights();
        this.resizeObserver.observe(this.main.nativeElement);
        this.resizeObserver.observe(this.vfCanvas.nativeElement);
    }

    ngAfterContentInit() {
        this.getStyles();

        this.subscriptions.push(this.themer.isDarkObs.subscribe({ next: this.onDarkModeChange }));
        this.subscriptions.push(this.xAxisMgr.userFineLimitsObs.subscribe({ next: this.drawOverlay }));
        this.subscriptions.push(this.xAxisMgr.userCoarseLimitsObs.subscribe({ next: this.onUserCoarseLimitsUpdate }));
        this.subscriptions.push(this.coarseLimitsLiveSubj.subscribe({ next: this.drawOverlay }));
        this.subscriptions.push(this.xAxisMgr.vfParamsObs.subscribe({ next: this.onVfParamsUpdate }));
    }

    ngOnDestroy() {
        this.resizeObserver.disconnect();
        this.subscriptions.map(sub => sub.unsubscribe());
        this.subscriptions = [];
        this.vf?.destroy();
        this.vfCanvas.nativeElement?.parentElement?.removeChild(this.vfCanvas.nativeElement);
        this.overlayCanvas.nativeElement?.parentElement?.removeChild(this.overlayCanvas.nativeElement);
    }

    onVfParamsUpdate = (p: VfParams | undefined) => {
        if (!p) {
            this.vf?.destroy();
        }
        else if (!this.vf) {
            const vfCanvas = this.vfCanvas.nativeElement;
            const vfContext = vfCanvas.getContext('2d');
            if (!vfContext) {
                console.error('Could not get canvas context');
                return;
            }
            this.vf = new Chart(vfContext, {
                type: 'scatter',
                data: {
                    datasets: p.datasets,
                },
                options: {
                    animation: false,
                    elements: {
                        line: { borderJoinStyle: 'round' },
                    },
                    layout: {
                        padding: { top: 0, right: this.sidePad, bottom: 0, left: this.sidePad },
                    },
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: false,
                        },
                    },
                    responsive: true,
                    scales: this.makeScales(p.yAxes),
                    spanGaps: true,
                },
                plugins: [{
                    id: 'viewfinderChartPlugin',
                    beforeUpdate: () => this.isUpdating.emit(true),
                    afterDraw: () => {
                        this.drawOverlay();
                        this.isUpdating.emit(false);
                    },
                }]
            });
            this.drawOverlay();
        }
        else {
            this.vf.data.datasets = p.datasets;
            this.vf.options.scales = this.makeScales(p.yAxes);
            this.vf.update();
        }
    };

    offsetXToAxisX(offsetX: number, limits: XLimits) {
        if (!this.vf) {
            console.error('Could not get viewfinder');
            return NaN;
        }
        const area = this.vf.chartArea; // coarse adjuster and viewfinder have same left/right
        return (offsetX - area.left) * (limits[1] - limits[0]) / (area.right - area.left) + limits[0];
    }

    offsetXToCoarseX = (offsetX: number) => this.offsetXToAxisX(offsetX, this.xAxisMgr.dataLimits);

    offsetXToVfX = (offsetX: number) => this.offsetXToAxisX(offsetX, this.xAxisMgr.userCoarseLimits);
    // works because we set min/max instead of suggestedMin/Max

    axisXToOffsetX(axisX: number, limits: XLimits) {
        if (!this.vf) {
            console.error('Could not get viewfinder');
            return NaN;
        }
        const area = this.vf.chartArea; // coarse adjuster and viewfinder have same left/right
        return (axisX - limits[0]) * (area.right - area.left) / (limits[1] - limits[0]) + area.left;
    }

    coarseXToOffsetX = (axisX: number) => this.axisXToOffsetX(axisX, this.xAxisMgr.dataLimits);

    vfXToOffsetX = (axisX: number) => this.axisXToOffsetX(axisX, this.xAxisMgr.userCoarseLimits);
    // works because we set min/max instead of suggestedMin/Max

    @HostListener('window:mousemove', ['$event'])
    onMainMousemove(event: MouseEvent) {
        const prev = this.frameHandlerMousemove;
        this.frameHandlerMousemove = event;
        if (!prev) {
            requestAnimationFrame(this.onMainMousemoveFrame);
        }
        // if we're not handling a drag at the moment, prevent default
        return !(this.dragCoarse.has(mouseTouch) || this.dragVf.has(mouseTouch));
    }

    onMainMousemoveFrame = () => {
        if (this.frameHandlerMousemove && this.vf?.canvas) {
            const offsetX = this.frameHandlerMousemove.clientX - this.vf.canvas.getBoundingClientRect().x;

            this.dragCoarse.get(mouseTouch)?.onMove(this.offsetXToCoarseX(offsetX));
            this.dragVf.get(mouseTouch)?.onMove(this.offsetXToVfX(offsetX));
        }
        this.frameHandlerMousemove = undefined;
    };

    @HostListener('window:mouseup', [])
    onMainMouseup() {
        this.endCoarseDrag(mouseTouch);
        this.dragVf.delete(mouseTouch);
    }

    @HostListener('window:mouseleave', [])
    onMainMouseleave() {
        this.endCoarseDrag(mouseTouch);
        this.dragVf.delete(mouseTouch);
    }

    onMousedown(event: MouseEvent) {
        event.preventDefault();
        if (!this.vf?.canvas) {
            return;
        }
        const xOffset = this.vf.canvas.getBoundingClientRect().x;
        if (event.offsetY < this.coarseAdjustHeight) {
            const drag = this.makeCoarseDrag(event.clientX - xOffset);
            if (drag.isValid) {
                this.dragCoarse.set(mouseTouch, drag);
                drag.onMove(this.offsetXToCoarseX(event.clientX - xOffset));
            }
        }
        else {
            const drag = this.makeVfDrag(event.clientX - xOffset);
            if (drag.isValid) {
                this.dragVf.set(mouseTouch, drag);
                drag.onMove(this.offsetXToVfX(event.clientX - xOffset));
            }
        }
    }

    onTouchstart(event: TouchEvent) {
        const vfCanvas = this.vfCanvas.nativeElement;
        const overlayCanvas = this.overlayCanvas.nativeElement;
        if (!vfCanvas || !overlayCanvas) {
            console.error('Could not get canvas');
            return;
        }
        const vfBcr = vfCanvas.getBoundingClientRect();
        for (const touch of Array.from(event.changedTouches)) {
            if (touch.clientY < vfBcr.top) {
                const drag = this.makeCoarseDrag(touch.clientX - vfBcr.x);
                // make sure there isn't already a drag for that target in process
                if (drag.isValid && !Array.from(this.dragCoarse.values()).some(d => d.conflictsWith(drag))) {
                    this.dragCoarse.set(touch.identifier, drag);
                    drag.onMove(this.offsetXToCoarseX(touch.clientX - vfBcr.x));
                }
            }
            else {
                const drag = this.makeVfDrag(touch.clientX - vfBcr.x);
                if (drag.isValid && !Array.from(this.dragVf.values()).some(d => d.conflictsWith(drag))) {
                    this.dragVf.set(touch.identifier, drag);
                    drag.onMove(this.offsetXToVfX(touch.clientX - vfBcr.x));
                }
            }
        }
    }

    onTouchmove(event: TouchEvent) {
        const prev = this.frameHandlerTouchmoves.size;
        for (const touch of Array.from(event.changedTouches)) {
            this.frameHandlerTouchmoves.set(touch.identifier, touch);
        }
        if (!prev) {
            requestAnimationFrame(this.onTouchmoveFrame);
        }
    }

    onTouchmoveFrame = () => {
        if (!this.vf?.canvas) {
            console.error('Could not get viewfinder canvas');
            return;
        }
        const xOffset = this.vf.canvas.getBoundingClientRect().x;
        for (const touch of this.frameHandlerTouchmoves.values()) {
            const offsetX = touch.clientX - xOffset;

            this.dragCoarse.get(touch.identifier)?.onMove(this.offsetXToCoarseX(offsetX));
            this.dragVf.get(touch.identifier)?.onMove(this.offsetXToVfX(offsetX));
        }
        this.frameHandlerTouchmoves.clear();
    };

    onTouchend(event: TouchEvent) {
        for (const touch of Array.from(event.changedTouches)) {
            this.endCoarseDrag(touch.identifier);
        }
        for (const touch of Array.from(event.changedTouches)) {
            this.dragVf.delete(touch.identifier);
        }
    }

    get shrunk() {
        const dataLength = this.xAxisMgr.dataLimits[1] - this.xAxisMgr.dataLimits[0];
        const isShortData = dataLength <= this.settings.preferredCoarseWidth;
        return this.shrink || (isShortData && !this.settings.alwaysShowCoarse);
    }

    private updateHeights = () => {
        this.viewfinderHeight = this.vfCanvas.nativeElement.clientHeight;
        const coarseAdjustHeight = this.main.nativeElement.clientHeight - this.viewfinderHeight;
        if (coarseAdjustHeight !== this.coarseAdjustHeight) {
            this.coarseAdjustHeight = coarseAdjustHeight;
            this.drawOverlay();
        }
    };

    private onUserCoarseLimitsUpdate = (limits: XLimits) => {
        if (this.vf?.options.scales?.x) {
            this.vf.options.scales.x.min = limits[0];
            this.vf.options.scales.x.max = limits[1];
            this.vf.update();
        }
    };

    private getStyles() {
        const style = getComputedStyle(this.elementRef.nativeElement);
        const textColorRgb = style.getPropertyValue('--ion-text-color-rgb').replace(/ /g, '');
        const backgroundColor = (style.getPropertyValue('--background-color') || style.getPropertyValue('--ion-background-color'))
            .replace(/ /g, '');
        this.styles = {
            backgroundColor,
            backgroundColorRgb: colorToRgb(backgroundColor),
            fontFamily: style.getPropertyValue('--ion-font-family').replace(/^ /, '').replace(/ $/, ''),
            textColor: style.getPropertyValue('--ion-text-color').replace(/ /g, ''),
            step600Color: style.getPropertyValue('--ion-color-step-600').replace(/ /g, ''),
            textColorRgb,
            gridlineColor: `rgba(${textColorRgb},0.1)`,
            zeroGridlineColor: `rgba(${textColorRgb},0.25)`,
            overlayColor: style.getPropertyValue('--ion-text-color').replace(/ /g, ''),
        };
    }

    private makeScales = (yAxes: LinearScales): LinearScales => ({
        x: {
            position: 'bottom',
            grid: {
                color: (ctx) => ctx.tick.value === 0 ? this.styles.zeroGridlineColor : this.styles.gridlineColor,
                tickLength: 4,
            },
            min: this.xAxisMgr.userCoarseLimits[0],
            max: this.xAxisMgr.userCoarseLimits[1],
            title: {
                font: {
                    family: this.styles.fontFamily,
                },
                color: this.styles.step600Color,
                padding: { top: -4, bottom: 0 },
            },
            ticks: {
                callback: xTickCallback,
                color: this.styles.step600Color,
            },
            type: 'linear',
        },
        'y-axis-box': {
            display: false,
            min: 0, max: 1,
            type: 'linear',
            ticks: { display: false, },
            grid: { display: false },
            title: { display: false },
        },
        ...yAxes,
    });

    // X axis milliseconds per pixel
    private get coarseXPixelRatio() {
        if (!this.vf) {
            console.error('Could not get viewfinder');
            return NaN;
        }
        const area = this.vf.chartArea;
        const limits = this.xAxisMgr.dataLimits;
        return (limits[1] - limits[0]) / (area.right - area.left);
    }

    // X axis milliseconds per pixel
    private get vfXPixelRatio() {
        if (!this.vf) {
            console.error('Could not get viewfinder');
            return NaN;
        }
        const area = this.vf.chartArea;
        const limits = this.xAxisMgr.userCoarseLimits;
        return (limits[1] - limits[0]) / (area.right - area.left);
    }

    private makeCoarseDrag = (offsetX: number) => new Dragger({
        startValue: this.offsetXToCoarseX(offsetX),
        handleHitWidth: this.handleHitHalfwidth * 2 * this.coarseXPixelRatio,
        minTimeSpan: 0.1,
        dragSpan: this.xAxisMgr.dataLimits,
        origLimits: this.userCoarseLimits,
        setLimits: limits => this.coarseLimitsLiveSubj.next(limits),
    });

    private makeVfDrag = (offsetX: number) => new Dragger({
        startValue: this.offsetXToVfX(offsetX),
        handleHitWidth: this.handleHitHalfwidth * 2 * this.vfXPixelRatio,
        minTimeSpan: 0.1,
        dragSpan: this.xAxisMgr.userCoarseLimits,
        origLimits: this.xAxisMgr.userFineLimits,
        setLimits: limits => this.xAxisMgr.userFineLimits = limits,
    });

    private endCoarseDrag(id: number | typeof mouseTouch) {
        this.dragCoarse.delete(id);
        if (this.dragCoarse.size === 0 && this.coarseLimitsLiveSubj.value) {
            const fineAsOffset = this.xAxisMgr.userFineLimits.map(this.vfXToOffsetX) as [number, number];
            this.xAxisMgr.userCoarseLimits = this.coarseLimitsLiveSubj.value;
            this.coarseLimitsLiveSubj.next(undefined);
            const adjFine = fineAsOffset.map(this.offsetXToVfX) as [number, number];
            adjFine[0] = Math.max(adjFine[0], this.xAxisMgr.userCoarseLimits[0]);
            adjFine[1] = Math.min(adjFine[1], this.xAxisMgr.userCoarseLimits[1]);
            this.xAxisMgr.userFineLimits = adjFine;
        }
    }

    private get handleHitHalfwidth() {
        return this.isMobile ? 20 : 5;
    }

    private get handleVisualHalfwidth() {
        return this.isMobile ? 10 : 2;
    }

    private get sidePad() {
        return this.isMobile ? 25 : 10;
    }

    private get userCoarseLimits() {
        return this.coarseLimitsLiveSubj.value || this.xAxisMgr.userCoarseLimits;
    }

    private drawHandle(
        ctx: CanvasRenderingContext2D,
        x: number,
        yCenter: number,
        height: number,
        extent: -1 | 1,
        opacity: number
    ) {
        ctx.fillStyle = `rgba(${this.styles.backgroundColorRgb},${opacity * 0.6})`;
        ctx.strokeStyle = `rgba(${this.styles.textColorRgb},${opacity})`;
        ctx.lineWidth = 2;

        const radius = Math.min(height / 2, this.handleVisualHalfwidth);
        const topBottomClearance = 4;
        const deltaY = Math.max(0, height / 2 - radius - topBottomClearance);
        const deltaX = (this.handleVisualHalfwidth * 2 - radius) * extent;
        const outsideAngle = Math.PI * (1 - extent) / 2;
        const isCcw = extent > 0;

        ctx.beginPath();
        ctx.moveTo(x, yCenter + deltaY + radius);
        ctx.lineTo(x + deltaX, yCenter + deltaY + radius);
        ctx.arc(x + deltaX, yCenter + deltaY, radius, Math.PI / 2, outsideAngle, isCcw);
        ctx.lineTo(x + deltaX + radius * extent, yCenter - deltaY);
        ctx.arc(x + deltaX, yCenter - deltaY, radius, outsideAngle, Math.PI * 3 / 2, isCcw);
        ctx.lineTo(x, yCenter - deltaY - radius);
        ctx.fill();
        ctx.stroke();
        strokeLine(ctx, x, yCenter - height / 2, x, yCenter + height / 2, 'butt');
    }

    private drawOverlay = () => {
        if (!this.vf) {
            return;
        }
        const canvas = this.overlayCanvas.nativeElement;
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            console.error('Could not get overlay canvas context');
            return;
        }

        const height = this.viewfinderHeight + this.coarseAdjustHeight;
        const width = this.vfCanvas.nativeElement.width;
        canvas.height = height * window.devicePixelRatio;
        canvas.width = width * window.devicePixelRatio;
        canvas.style.height = height + 'px';
        canvas.style.width = width + 'px';
        ctx.scale(window.devicePixelRatio, window.devicePixelRatio);

        const coarseLimits = this.xAxisMgr.userCoarseLimits;
        const fineLimits = this.xAxisMgr.userFineLimits;
        const vfArea = this.vf.chartArea;
        const vfWidth = vfArea.right - vfArea.left;
        const vfXMin = vfArea.left + (fineLimits[0] - coarseLimits[0]) * vfWidth / (coarseLimits[1] - coarseLimits[0]);
        const vfXMax = vfArea.left + (fineLimits[1] - coarseLimits[0]) * vfWidth / (coarseLimits[1] - coarseLimits[0]);

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const opacity = this.coarseLimitsLiveSubj.value ? 0.5 : 1;
        if (this.coarseLimitsLiveSubj.value) {
            ctx.fillStyle = `rgba(${this.styles.backgroundColorRgb},0.5)`;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
        }

        this.drawCoarseRect();

        ctx.strokeStyle = `rgba(${this.styles.textColorRgb},${opacity * 0.5})`;
        ctx.fillStyle = `rgba(${this.styles.textColorRgb},${opacity * 0.25})`;
        ctx.lineWidth = 1;

        ctx.strokeRect(vfArea.left, vfArea.top + this.coarseAdjustHeight,
            vfArea.right - vfArea.left, vfArea.bottom - vfArea.top);

        ctx.beginPath();
        ctx.rect(vfXMin, vfArea.top + this.coarseAdjustHeight, vfXMax - vfXMin, vfArea.bottom - vfArea.top);
        ctx.fill();
        ctx.stroke();

        const vfHandleYCenter = (vfArea.bottom + vfArea.top) / 2 + this.coarseAdjustHeight;
        const vfHandleHeight = vfArea.bottom - vfArea.top;
        this.drawHandle(ctx, vfXMin, vfHandleYCenter, vfHandleHeight, -1, opacity);
        this.drawHandle(ctx, vfXMax, vfHandleYCenter, vfHandleHeight, 1, opacity);
    };

    private drawCoarseRect = () => {
        const coarseRectHeight = this.coarseAdjustHeight * 0.8 - 8;
        if (coarseRectHeight <= 0) {
            return;
        }
        const canvas = this.overlayCanvas.nativeElement;
        const ctx = canvas.getContext('2d')!;
        const vfArea = this.vf!.chartArea;
        const vfWidth = vfArea.right - vfArea.left;
        const dataLimits = this.xAxisMgr.dataLimits;
        const coarseLimits = this.userCoarseLimits;

        ctx.strokeStyle = `rgba(${this.styles.textColorRgb},0.5)`;
        ctx.fillStyle = this.styles.zeroGridlineColor;
        ctx.lineWidth = 1;
        const coarseRectTop = 4.5;
        const coarseXMin = vfArea.left + (coarseLimits[0] - dataLimits[0]) * vfWidth / (dataLimits[1] - dataLimits[0]);
        const coarseXMax = vfArea.left + (coarseLimits[1] - dataLimits[0]) * vfWidth / (dataLimits[1] - dataLimits[0]);
        const coarseHandleYCenter = 4 + coarseRectHeight / 2;
        const coarseBottom = coarseRectTop + coarseRectHeight;

        ctx.beginPath();
        ctx.rect(vfArea.left, coarseRectTop, vfWidth, coarseRectHeight);
        ctx.stroke();

        ctx.beginPath();
        ctx.rect(coarseXMin, coarseRectTop, coarseXMax - coarseXMin, coarseRectHeight);
        ctx.fill();
        ctx.stroke();

        const tangentLength = (vfArea.top + this.coarseAdjustHeight - coarseBottom) * 0.75;
        const leftTangentLength = Math.abs(coarseXMin - vfArea.left) > 0.5 ? tangentLength : tangentLength / 4;
        ctx.beginPath();
        ctx.moveTo(coarseXMin, coarseBottom);
        ctx.bezierCurveTo(
            coarseXMin, coarseBottom + leftTangentLength,
            vfArea.left, vfArea.top + this.coarseAdjustHeight - leftTangentLength,
            vfArea.left, vfArea.top + this.coarseAdjustHeight
        );
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(coarseXMax, coarseBottom);
        const rightTangentLength = Math.abs(coarseXMax - vfArea.right) > 0.5 ? tangentLength : tangentLength / 4;
        ctx.bezierCurveTo(
            coarseXMax, coarseBottom + rightTangentLength,
            vfArea.right, vfArea.top + this.coarseAdjustHeight - rightTangentLength,
            vfArea.right, vfArea.top + this.coarseAdjustHeight,
        );
        ctx.stroke();

        this.drawHandle(ctx, coarseXMin, coarseHandleYCenter, coarseRectHeight, -1, 1);
        this.drawHandle(ctx, coarseXMax, coarseHandleYCenter, coarseRectHeight, 1, 1);
    };

    private onDarkModeChange = () => {
        this.getStyles();
        if (this.vf) {
            this.vf.update();
        }
    };
}
