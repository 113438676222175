import { Injectable } from '@angular/core';
import * as md5 from 'md5';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ObservableGate, truthyOnly } from '../../util/core';
import { hostifyItemDef } from './param-system-def-processing';
import { ParamSystemDef } from './param-system-def-types';
export * from './param-system-def-types';

export interface ParamSystemDefMetadata {
    name: string;
    description: string;
    data: any;
    url: string | undefined;
}

export interface ParamSystemRawDefRecord {
    raw: any;
    metadata: ParamSystemDefMetadata;
}

export interface ParamSystemDefRecord {
    def: ParamSystemDef;
    raw: any;
    metadata: ParamSystemDefMetadata;
    provider: ParamSystemRawDefProvider;
}

export interface ParamDefParseResult {
    err: any;
    def: ParamSystemDefRecord;
}

export interface RawParamDefParseResult {
    err: any;
    def: ParamSystemRawDefRecord;
}

export abstract class ParamSystemRawDefProvider {
    abstract record: Observable<ParamSystemRawDefRecord | undefined>;
    abstract onParsed: (result: RawParamDefParseResult) => void;
}

const paramDefFromRaw = (raw: any, validTypes: string[]): ParamSystemDef => ({
    ...raw,
    menus: hostifyItemDef(raw.menus, validTypes),
});

class ParamSystemRawDefProviderRecord {
    last: ParamSystemRawDefRecord | undefined;
    parsed: ParamSystemDef | undefined;

    private subscription: Subscription;

    constructor(
        public provider: ParamSystemRawDefProvider,
        onUpdate: () => void,
    ) {
        this.subscription = provider.record.subscribe((record) => {
            this.last = record;
            onUpdate();
        });
    }

    unsubscribe() {
        this.subscription.unsubscribe();
    }
}

@Injectable({ providedIn: 'root' })
export class ParamSystemDefProvider {
    private providers = new Array<ParamSystemRawDefProviderRecord>();
    private record = new BehaviorSubject<ParamSystemDefRecord | undefined>(undefined);
    private gate = new ObservableGate(this.record.asObservable());
    private isBuilding = false;
    private providerIndexInt = -1;
    // hash blacklist stores errors that happened while processing param defs, by the MD5 of JSON.stringify() of their raw form
    private hashBlacklist = new Map<string, any>();
    private validItemTypes = new Array<string>();

    addValidItemType(...types: string[]) {
        this.validItemTypes = this.validItemTypes.concat(types);
    }

    setProviders(providers: ParamSystemRawDefProvider[]) {
        if (this.providers.length) {
            for (const provider of this.providers) {
                provider.unsubscribe();
            }
        }

        this.isBuilding = true;
        this.providers = providers.map((provider) => new ParamSystemRawDefProviderRecord(provider, this.onUpdate));
        this.isBuilding = false;
        this.onUpdate();
    }

    // hold output, for example when connected to a device
    block() {
        this.gate.block();
    }

    // release previously held output (last record only)
    unblock() {
        this.gate.unblock();
    }

    close() {
        this.record.complete();
    }

    onParsed(result: ParamDefParseResult) {
        result.def.provider.onParsed(result);
        if (result.err) {
            this.hashBlacklist.set(md5(JSON.stringify(result.def.raw)), result.err);
            this.onUpdate(); // re-run and hopefully find a record that works
        }
    }

    // Get an Observable that returns only valid ParamSystemDefRecords.
    get obs(): Observable<ParamSystemDefRecord> {
        return this.gate.obs.pipe(truthyOnly());
    }

    // Get whatever value is currently present. Undefined until first param def is loaded.
    get value(): ParamSystemDefRecord | undefined {
        return this.gate.value;
    }

    get providerIndex(): number {
        return this.providerIndexInt;
    }

    private onUpdate = () => {
        if (this.isBuilding) {
            return;
        }
        this.providerIndexInt = -1;
        for (let i = 0; i < this.providers.length; ++i) {
            const providerRecord = this.providers[i];
            providerRecord.parsed = undefined;
            if (providerRecord.last) {
                const hash = md5(JSON.stringify(providerRecord.last.raw));
                if (this.hashBlacklist.has(hash)) {
                    // it's not going to work, tell the provider immediately
                    providerRecord.provider.onParsed({ err: this.hashBlacklist.get(hash), def: providerRecord.last });
                } else {
                    try {
                        // store it so we can do identity comparison in onParsed
                        providerRecord.parsed = paramDefFromRaw(providerRecord.last.raw, this.validItemTypes);
                        this.providerIndexInt = i;
                        this.record.next({
                            def: providerRecord.parsed,
                            metadata: providerRecord.last.metadata,
                            raw: providerRecord.last.raw,
                            provider: providerRecord.provider,
                        });
                        break;
                    } catch (err) {
                        this.hashBlacklist.set(hash, err);
                        providerRecord.provider.onParsed({ err, def: providerRecord.last }); // pass it up the chain
                    }
                }
            }
        }
    };
}
