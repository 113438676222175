import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ParamSystemRawDefProvider, ParamSystemRawDefRecord, nullMenu } from '../param/util/param-system-def';

@Injectable({ providedIn: 'root' })
export class NullParamDefProvider implements ParamSystemRawDefProvider {
    static nameValue = 'null';
    get record(): Observable<ParamSystemRawDefRecord | undefined> {
        return of({
            raw: {
                params: {},
                slots: {},
                slotLocalizations: [],
                slotLocalizationSets: [],
                menus: nullMenu,
            },
            metadata: {
                name: NullParamDefProvider.nameValue,
                description: NullParamDefProvider.nameValue,
                data: undefined,
                url: undefined,
            },
        });
    }

    onParsed() {}
}
