import {
    AfterContentInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { Platform } from "@ionic/angular";
import {
    Chart,
    ChartArea,
    ChartDataset,
    Decimation,
    Filler,
    Legend,
    LinearScale,
    LinearScaleOptions,
    LineElement,
    PointElement,
    ScatterController,
    ScatterDataPoint,
    Title,
    Tooltip,
} from "chart.js";
import { DeepPartial } from "chart.js/types/utils";
import { range, sortedIndex, sum, uniq } from "lodash-es";
import { Subscription } from "rxjs";
import {
    arrayMax,
    arrayMin,
    colorToRgb,
} from "../../hgm-setup/src/module/util/core";
import { GroupSignal } from "../../providers/signal-group-provider";
import {
    maxSignalDashLength,
    RangeMap,
    signalColors,
    signalDashes,
    StyleMap,
} from "../../providers/signal-settings-provider";
import { ThemeManager } from "../../providers/theme-manager";
import { xTickCallback } from "../../providers/util";
import { XAxisManager, XLimits } from "../../providers/x-axis-manager";
import { SignalNoData } from "src/providers/data-provider";
import { SignalSettingsProvider } from "src/providers/signal-settings-provider";

interface Styles {
    backgroundColor: string;
    backgroundColorRgb: string;
    fontFamily: string;
    textColor: string;
    step600Color: string;
    textColorRgb: string;
    gridlineColor: string;
    zeroGridlineColor: string;
    cursorColor: string;
}

const mouseTouch = Symbol();

interface ChartDrag {} // eslint-disable-line @typescript-eslint/no-empty-interface

interface LegendSignal {
    name: string;
    color: string;
    dash: number[];
    yAxisID: string;
    unit: string;
    hidden?: boolean;
}

interface CursorPoint {
    signal: LegendSignal;
    pixelX: number;
    pixelY: number;
    labelValue: string;
}

const getBounds = (signals: GroupSignal[], ranges: RangeMap) => {
    const dataMin = arrayMin(signals.map((s) => s.minY).filter(isFinite));
    const dataMax = arrayMax(signals.map((s) => s.maxY).filter(isFinite));
    const userMin = arrayMin(
        signals
            .map((s) => s.slot.engrToEngrFloat(ranges[s.key]?.minY))
            .filter(isFinite) as number[]
    );
    const userMax = arrayMax(
        signals
            .map((s) => s.slot.engrToEngrFloat(ranges[s.key]?.maxY))
            .filter(isFinite) as number[]
    );
    return [Math.min(dataMin, userMin), Math.max(dataMax, userMax)];
};

Chart.register(
    LineElement,
    PointElement,
    ScatterController,
    LinearScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
);

type LinearScaleFullOptions = DeepPartial<LinearScaleOptions> & {
    type: "linear";
};
interface LinearScales {
    [key: string]: LinearScaleFullOptions;
}
type MainDataset = ChartDataset<"scatter", ScatterDataPoint[]> & {
    unit: string;
};
type VfDataset = ChartDataset<"scatter", ScatterDataPoint[]>;

@Component({
    selector: "signal-chart",
    templateUrl: "signal-chart.component.html",
})
export class SignalChartComponent
    implements AfterContentInit, OnChanges, OnDestroy
{
    @Input() signals:
        | { group: GroupSignal[]; styles: StyleMap; ranges: RangeMap }
        | undefined;
    @Input() signalsNoData:
        | { group: SignalNoData[]; styles: StyleMap; ranges: RangeMap }
        | undefined;
    @Input() id: number;
    @Output() isUpdating = new EventEmitter<boolean>();

    legendSignals: LegendSignal[] = [];
    signalLabelBoxes: {
        left: string;
        right: string;
        top: string;
        bottom: string;
        onClicked: () => void;
    }[] = [];

    private subscriptions: Subscription[] = [];
    private chart: Chart | undefined;
    @ViewChild("chartCanvas", { static: true })
    private chartCanvas: ElementRef<HTMLCanvasElement>;
    @ViewChild("cursorCanvas", { static: true })
    private cursorCanvas: ElementRef<HTMLCanvasElement>;

    private dragChart = new Map<number | typeof mouseTouch, ChartDrag>();
    private isMobile = false;
    private frameHandlerMousemove: MouseEvent | undefined;
    private frameHandlerChartTouchmoves = new Map<number, Touch>();
    private vfDatasets: ChartDataset<"scatter", ScatterDataPoint[]>[];
    private vfYAxes: LinearScales;
    private styles: Styles;
    private isChartUpdating = false;
    private isChartUpdateRequested = false;

    constructor(
        platform: Platform,
        private themer: ThemeManager,
        private xAxisMgr: XAxisManager,
        private signalSettingsProvider: SignalSettingsProvider
    ) {
        this.isMobile = platform.is("mobile");
    }

    ngOnInit() {
        this.subscriptions.push(
            this.themer.isDarkObs.subscribe({ next: this.onDarkModeChange })
        );
        this.subscriptions.push(
            this.xAxisMgr.userCoarseLimitsObs.subscribe({
                next: this.onCoarseLimitsUpdate,
            })
        );
        this.subscriptions.push(
            this.xAxisMgr.userFineLimitsObs.subscribe({
                next: this.onFineLimitsUpdate,
            })
        );
        this.subscriptions.push(
            this.xAxisMgr.cursorPosObs.subscribe({
                next: this.onCursorPosUpdate,
            })
        );
        this.subscriptions.push(
            this.xAxisMgr.chartInsetObs.subscribe({ next: this.updateXInset })
        );
    }

    ngAfterContentInit() {
        this.getStyles();

        const { datasets, labels, scales, vfDatasets, vfYAxes, legendSignals } =
            this.makeDatasets();
        this.vfDatasets = vfDatasets;
        this.vfYAxes = vfYAxes;
        this.legendSignals = legendSignals;
        const chartContext = this.chartCanvas.nativeElement.getContext("2d");
        if (!chartContext) {
            console.error("Could not get chart context");
            return;
        }
        this.chart = new Chart(chartContext, {
            type: "scatter",
            data: {
                datasets,
                labels,
            },
            options: {
                animation: false,
                elements: {
                    line: { borderJoinStyle: "round" },
                    point: { hitRadius: 100 },
                },
                layout: {
                    padding: this.makeChartPadding(),
                },
                maintainAspectRatio: false,
                responsive: true,
                scales,
                plugins: {
                    legend: {
                        display: false,
                        labels: {},
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                spanGaps: true,
            },
            plugins: [
                {
                    id: "signalChartPlugin",
                    beforeUpdate: this.chartBeforeUpdate,
                    afterLayout: this.chartAfterLayout,
                    afterDraw: this.chartAfterDraw,
                },
            ],
        });
        this.updateXInset(); // make.*Padding can't do its job properly until chart exists
        this.xAxisMgr.setVf(this.id, {
            datasets: this.vfDatasets,
            yAxes: this.vfYAxes,
        });
    }

    ngOnDestroy() {
        this.subscriptions.map((sub) => sub.unsubscribe());
        this.subscriptions = [];
        this.xAxisMgr.clearChart(this.id);
        this.chart?.destroy();
        this.chartCanvas.nativeElement?.parentElement?.removeChild(
            this.chartCanvas.nativeElement
        );
        this.cursorCanvas.nativeElement?.parentElement?.removeChild(
            this.cursorCanvas.nativeElement
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.group) {
            this.updateDatasets();
        }
    }

    getChartX(offsetX: number) {
        if (!this.chart) {
            return NaN;
        }
        const area = this.chart.chartArea;
        const limits = this.xAxisMgr.userFineLimits; // works because we set min/max instead of suggestedMin/Max
        return (
            ((offsetX - area.left) * (limits[1] - limits[0])) /
                (area.right - area.left) +
            limits[0]
        );
    }

    getOffsetX(chartX: number) {
        if (!this.chart) {
            return NaN;
        }
        const area = this.chart.chartArea;
        const limits = this.xAxisMgr.userFineLimits; // works because we set min/max instead of suggestedMin/Max
        return (
            ((chartX - limits[0]) * (area.right - area.left)) /
                (limits[1] - limits[0]) +
            area.left
        );
    }

    @HostListener("window:mousemove", ["$event"])
    onMainMousemove(event: MouseEvent) {
        const prev = this.frameHandlerMousemove;
        this.frameHandlerMousemove = event;
        if (!prev) {
            requestAnimationFrame(this.onMainMousemoveFrame);
        }
    }

    onMainMousemoveFrame = () => {
        if (
            this.frameHandlerMousemove &&
            this.chart?.canvas &&
            this.dragChart.has(mouseTouch)
        ) {
            const area = this.chart.chartArea;
            const xOffset = this.chart.canvas.getBoundingClientRect().x;
            this.xAxisMgr.cursorPos = this.getChartX(
                Math.min(
                    Math.max(
                        this.frameHandlerMousemove.clientX - xOffset,
                        area.left
                    ),
                    area.right
                )
            );
        }
        this.frameHandlerMousemove = undefined;
    };

    @HostListener("window:mouseup", [])
    onMainMouseup() {
        this.dragChart.delete(mouseTouch);
    }

    @HostListener("window:mouseleave", [])
    onMainMouseleave() {
        this.dragChart.delete(mouseTouch);
    }

    onChartMousedown(event: MouseEvent) {
        this.dragChart.set(mouseTouch, {});
        this.onMainMousemove(event);
    }

    onChartTouchstart(event: TouchEvent) {
        for (const touch of Array.from(event.changedTouches)) {
            if (this.dragChart.size) {
                break; // multitouch on the chart is right out, for now
            }
            this.dragChart.set(touch.identifier, {});
        }
        this.onChartTouchmove(event);
    }

    onChartTouchmove(event: TouchEvent) {
        const prev = this.frameHandlerChartTouchmoves.size;
        for (const touch of Array.from(event.changedTouches)) {
            this.frameHandlerChartTouchmoves.set(touch.identifier, touch);
        }
        if (!prev) {
            requestAnimationFrame(this.onChartTouchmoveFrame);
        }
    }

    onChartTouchmoveFrame = () => {
        if (!this.chart?.canvas) {
            console.error("Could not get chart canvas");
            return;
        }
        const xOffset = this.chart.canvas.getBoundingClientRect().x;
        const area = this.chart.chartArea;
        for (const touch of this.frameHandlerChartTouchmoves.values()) {
            if (this.dragChart.has(touch.identifier)) {
                this.xAxisMgr.cursorPos = this.getChartX(
                    Math.min(
                        Math.max(touch.clientX - xOffset, area.left),
                        area.right
                    )
                );
            }
        }
        this.frameHandlerChartTouchmoves.clear();
    };

    onChartTouchend(event: TouchEvent) {
        for (const touch of Array.from(event.changedTouches)) {
            this.dragChart.delete(touch.identifier);
        }
    }

    onSignalToggled(signal: LegendSignal) {
        if (!this.chart?.data?.datasets) {
            console.error("Could not get chart datasets");
            return;
        }
        const index = this.legendSignals.indexOf(signal);
        if (index < 0 || index >= this.chart.data.datasets.length) {
            console.warn("Signal toggled but not found in list", index, signal);
            return;
        }
        signal.hidden = !signal.hidden;
        this.chart.data.datasets[index].hidden = signal.hidden;
        this.vfDatasets[index].hidden = signal.hidden;
        this.updateChart();
        this.xAxisMgr.setVf(this.id, {
            datasets: this.vfDatasets,
            yAxes: this.vfYAxes,
        });
    }

    onNoDataSignalToggled(signal: LegendSignal) {
        signal.hidden = !signal.hidden;
        this.updateChart();
    }

    private updateDatasets = () => {
        if (this.chart?.options.scales) {
            const {
                datasets,
                labels,
                scales,
                vfDatasets,
                vfYAxes,
                legendSignals,
            } = this.makeDatasets();
            this.vfDatasets = vfDatasets;
            this.vfYAxes = vfYAxes;
            this.legendSignals = legendSignals;
            this.chart.data.datasets = datasets;
            this.chart.data.labels = labels;
            this.chart.options.scales = scales;
            this.updateChart();
            this.xAxisMgr.setVf(this.id, {
                datasets: this.vfDatasets,
                yAxes: this.vfYAxes,
            });
        }
    };

    private updateXInset = () => {
        if (this.chart?.options.layout) {
            this.chart.options.layout.padding = this.makeChartPadding();
            this.updateChart();
        }
    };

    private onCoarseLimitsUpdate = () => {
        this.updateDatasets();
    };

    private onFineLimitsUpdate = (limits: XLimits) => {
        if (this.chart?.options.scales?.x) {
            this.chart.options.scales.x.min = limits[0];
            this.chart.options.scales.x.max = limits[1];
            const { datasets, labels, scales } = this.makeDatasets();
            this.chart.data.datasets = datasets;
            this.chart.data.labels = labels;
            this.chart.options.scales = scales;
            this.updateChart();
        }
    };

    private onCursorPosUpdate = () => {
        if (this.chart) {
            this.redrawCursor(this.chart);
        }
    };

    private makeChartPadding(): DeepPartial<ChartArea> {
        const top = 0;
        const bottom = -6;
        if (!this.chart?.options.layout?.padding || !this.chart.canvas) {
            return {
                top,
                right: this.minRightPad,
                bottom,
                left: this.minLeftPad,
            };
        }

        const desired = this.xAxisMgr.chartInset;
        const padding = this.chart.options.layout.padding as ChartArea;
        return {
            top,
            right: Math.max(
                this.minRightPad,
                desired.right -
                    (this.chart.canvas.width -
                        this.chart.chartArea.right -
                        padding.right!)
            ),
            bottom,
            left: Math.max(
                this.minLeftPad,
                desired.left - (this.chart.chartArea.left - padding.left!)
            ),
        };
    }

    isGroupSignal = (object: any): object is GroupSignal => {
        return "slot" in object;
    };

    private makeDatasets(): {
        datasets: ChartDataset<"scatter", ScatterDataPoint[]>[];
        labels: string[];
        scales: LinearScales;
        vfDatasets: ChartDataset<"scatter", ScatterDataPoint[]>[];
        vfYAxes: LinearScales;
        legendSignals: LegendSignal[];
    } {
        if (!this.signals) {
            return {
                datasets: [],
                labels: [],
                scales: {},
                vfDatasets: [],
                vfYAxes: {},
                legendSignals: [],
            };
        }
        const { group, styles, ranges } = this.signals;
        const units = uniq(group.map((s) => s.unit));

        const signalYAxes: {
            unit: string;
            signals: GroupSignal[];
            dedicated: boolean | null;
        }[] = units.map((unit) => ({
            unit,
            signals: group.filter((s) => s.unit === unit),
            dedicated: null,
        }));
        for (let i = signalYAxes.length - 1; i >= 0; --i) {
            const existing = signalYAxes[i];
            const nonDedicated = existing.signals.filter(
                (s) => !ranges[s.key]?.dedicatedY
            );
            const dedicated = existing.signals.filter(
                (s) => ranges[s.key]?.dedicatedY
            );
            const split = [
                {
                    unit: existing.unit,
                    signals: nonDedicated,
                    dedicated: false,
                },
                ...dedicated.map((s) => ({
                    unit: existing.unit,
                    signals: [s],
                    dedicated: true,
                })),
            ];
            signalYAxes.splice(i, 1, ...split);
        }
        const scales: LinearScales = {
            x: {
                position: "bottom",
                grid: {
                    color: (ctx) =>
                        ctx.tick.value === 0
                            ? this.styles.zeroGridlineColor
                            : this.styles.gridlineColor,
                },
                min: this.xAxisMgr.userFineLimits[0],
                max: this.xAxisMgr.userFineLimits[1],
                title: {
                    font: {
                        family: this.styles.fontFamily,
                    },
                    color: this.styles.step600Color,
                    padding: { top: -4, bottom: 0 },
                },
                ticks: {
                    callback: xTickCallback,
                    color: this.styles.step600Color,
                },
                type: "linear",
            },
        };
        for (const [i, signalYAxis] of signalYAxes.entries()) {
            const bounds = getBounds(signalYAxis.signals, ranges);
            const precision = arrayMax(
                signalYAxis.signals.map((s) => s.slot.precision)
            );
            scales[`y-axis-${i}`] = {
                position: "left",
                type: "linear",
                display: "auto",
                suggestedMin: bounds[0],
                suggestedMax: bounds[1],
                ticks: {
                    callback: (value) =>
                        Number(value).toFixed(precision) + signalYAxis.unit,
                    precision,
                    textStrokeColor: this.styles.step600Color,
                },
                grid: {
                    color: (ctx) =>
                        ctx.tick.value === 0
                            ? this.styles.zeroGridlineColor
                            : this.styles.gridlineColor,
                },
                title: {
                    color: this.styles.step600Color,
                    display: signalYAxis.dedicated || false,
                    font: {
                        family: this.styles.fontFamily,
                    },
                    padding: 0,
                    text: signalYAxis.signals[0].name,
                },
            };
        }

        const mixedGroup: (GroupSignal | SignalNoData)[] = [...group];
        if (this.signalsNoData) {
            for (const signalGroupNoData of this.signalsNoData.group) {
                if (signalGroupNoData) {
                    mixedGroup.push(signalGroupNoData);
                }
            }
        }
        const stylesFromSignalSettings = this.signalSettingsProvider.styles;
        const labels = mixedGroup.map((s) => s.name);
        let style;
        const datasets = Array.from(mixedGroup.entries()).map(
            ([i, signal]): MainDataset => {
                const isGroupSignal = this.isGroupSignal(signal);
                style = styles[signal.key];
                let yAxisIndex = 0;
                let unit;
                let data;
                if (isGroupSignal) {
                    yAxisIndex = signalYAxes.findIndex((a) =>
                        a.signals.includes(signal)
                    );
                    unit =
                        signalYAxes.find((a) => a.signals.includes(signal))
                            ?.unit || "";
                    const xCoords = signal.data.map((d) => d[0]);
                    const beginIndex = Math.max(
                        sortedIndex(xCoords, this.xAxisMgr.userFineLimits[0]) -
                            1,
                        0
                    );
                    const endIndex = Math.min(
                        sortedIndex(xCoords, this.xAxisMgr.userFineLimits[1]) +
                            1,
                        xCoords.length
                    );
                    data = signal.data
                        .slice(beginIndex, endIndex)
                        .map((d) => ({ x: d[0], y: d[1] }));
                } else {
                    yAxisIndex = 0;
                    unit = "";
                    data = [];
                    style = stylesFromSignalSettings
                        ? stylesFromSignalSettings[signal.key]
                        : undefined;
                }
                return {
                    yAxisID: `y-axis-${yAxisIndex}`,
                    borderColor: signalColors[style.color],
                    pointBackgroundColor: signalColors[style.color],
                    borderDash: signalDashes[style.dash],
                    borderWidth: 1.5,
                    pointBorderWidth: 0,
                    pointHoverBorderWidth: 2,
                    pointRadius: 0,
                    pointHoverRadius: 2,
                    data,
                    fill: false,
                    label: mixedGroup[i].name,
                    showLine: true,
                    unit,
                    hidden: this.legendSignals[i]?.hidden,
                    parsing: false,
                    normalized: true,
                };
            }
        );

        const vfYAxes: LinearScales = {};
        for (const i of signalYAxes.keys()) {
            vfYAxes[`y-axis-${this.id}-${i}`] = {
                display: false,
                position: "left",
                type: "linear",
                ticks: {
                    display: false,
                },
                suggestedMin: scales[`y-axis-${i}`].suggestedMin,
                suggestedMax: scales[`y-axis-${i}`].suggestedMax,
                grid: { display: false },
                title: { display: false },
            };
        }

        const vfDatasets = Array.from(group.entries()).map(
            ([i, signal]): VfDataset => {
                const yAxisIndex = signalYAxes.findIndex((a) =>
                    a.signals.includes(group[i])
                );
                const xCoords = group[i].data.map((d) => d[0]);
                const beginIndex = sortedIndex(
                    xCoords,
                    this.xAxisMgr.userCoarseLimits[0]
                );
                const endIndex = sortedIndex(
                    xCoords,
                    this.xAxisMgr.userCoarseLimits[1]
                );
                const data = group[i].data
                    .slice(beginIndex, endIndex)
                    .map((d) => ({ x: d[0], y: d[1] }));
                const style = styles[signal.key];
                return {
                    yAxisID: `y-axis-${this.id}-${yAxisIndex}`,
                    borderColor: signalColors[style.color],
                    pointBackgroundColor: signalColors[style.color],
                    borderDash: signalDashes[style.dash],
                    borderWidth: 1.5,
                    pointBorderWidth: 0,
                    pointHoverBorderWidth: 0,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    data,
                    fill: false,
                    showLine: true,
                    hidden: this.legendSignals[i]?.hidden,
                    parsing: false,
                    normalized: true,
                };
            }
        );

        const legendSignals = Array.from(datasets.entries()).map(
            ([i, ds]): LegendSignal => ({
                name: ds.label || "",
                color: ds.borderColor as string,
                dash: (ds.borderDash as number[]) || [],
                yAxisID: ds.yAxisID || "",
                unit: ds.unit,
                hidden: this.legendSignals[i]?.hidden,
            })
        );

        return { datasets, labels, scales, vfDatasets, vfYAxes, legendSignals };
    }

    private getStyles() {
        const style = getComputedStyle(
            document.getElementsByTagName("app-root")[0]
        );
        const textColorRgb = style
            .getPropertyValue("--ion-text-color-rgb")
            .replace(/ /g, "");
        this.styles = {
            backgroundColor: style
                .getPropertyValue("--ion-background-color")
                .replace(/ /g, ""),
            backgroundColorRgb: style
                .getPropertyValue("--ion-background-color-rgb")
                .replace(/ /g, ""),
            fontFamily: style
                .getPropertyValue("--ion-font-family")
                .replace(/^ /, "")
                .replace(/ $/, ""),
            textColor: style
                .getPropertyValue("--ion-text-color")
                .replace(/ /g, ""),
            step600Color: style
                .getPropertyValue("--ion-color-step-600")
                .replace(/ /g, ""),
            textColorRgb,
            gridlineColor: `rgba(${textColorRgb},0.1)`,
            zeroGridlineColor: `rgba(${textColorRgb},0.25)`,
            cursorColor: style
                .getPropertyValue("--ion-text-color")
                .replace(/ /g, ""),
        };
    }

    private get minLeftPad() {
        return 4;
    }

    private get minRightPad() {
        return this.isMobile ? 20 : 10;
    }

    private chartBeforeUpdate = () => {
        this.isChartUpdating = true;
        this.isUpdating.emit(true);
    };

    private chartAfterLayout = (chart: Chart) => {
        if (!chart.options.layout?.padding || !chart.canvas) {
            console.error("Could not get chart options or canvas");
            return;
        }
        const padding = chart.options.layout.padding as ChartArea;
        this.xAxisMgr.setMinInset(this.id, {
            left: chart.chartArea.left - padding.left! + this.minLeftPad,
            right:
                chart.canvas.width -
                chart.chartArea.right -
                padding.right! +
                this.minRightPad,
        });
        this.cursorCanvas.nativeElement.width = chart.canvas.width;
        this.cursorCanvas.nativeElement.height = chart.canvas.height;
        const cursorCanvasContext =
            this.cursorCanvas.nativeElement.getContext("2d");
        if (!cursorCanvasContext) {
            console.error("Could not get cursor canvas");
            return;
        }
        cursorCanvasContext.scale(
            chart.canvas.width / chart.canvas.getBoundingClientRect().width,
            chart.canvas.height / chart.canvas.getBoundingClientRect().height
        );
    };

    private chartAfterDraw = (chart: Chart) => {
        this.redrawCursor(chart);
        if (this.isChartUpdateRequested) {
            this.isChartUpdateRequested = false;
            chart.update();
        } else {
            this.isChartUpdating = false;
            this.isUpdating.emit(false);
        }
    };

    private redrawCursor(chart: Chart) {
        if (this.xAxisMgr.cursorPos === undefined) {
            return;
        }
        let cursorX = this.getOffsetX(this.xAxisMgr.cursorPos);
        if (cursorX === undefined || !isFinite(cursorX)) {
            return;
        }
        const area = chart.chartArea;
        cursorX = Math.min(Math.max(cursorX, area.left), area.right);
        const canvas = this.cursorCanvas.nativeElement;
        const ctx = canvas.getContext("2d");
        if (!ctx) {
            console.error("Could not get cursor canvas context");
            return;
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.strokeStyle = this.styles.cursorColor;
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(cursorX, area.top - 5);
        ctx.lineTo(cursorX, area.bottom + 5);
        ctx.stroke();

        // find the most recent point for each signal (or the next if no previous)
        const points = this.getCursorPoints(chart, this.xAxisMgr.cursorPos);

        if (!points.length && !this.signalsNoData) {
            return;
        }

        // draw a circle around each point
        ctx.strokeStyle = this.styles.cursorColor;
        ctx.lineWidth = 2;
        for (const point of points.filter((p) => !p.signal.hidden)) {
            ctx.beginPath();
            ctx.arc(point.pixelX, point.pixelY, 3, 0, Math.PI * 2);
            ctx.stroke();
        }

        // figure out where to draw the label: set up the font/size, figure out width, then see which side it fits on
        const fontSize = 12;
        const font = `${fontSize}px ${this.styles.fontFamily}`;
        ctx.font = font;
        const outerPadding = 6;
        const innerHPadding = 6;
        const innerHColPadding = 12;
        const innerVPadding = 4;
        const margin = 5;
        const lineSampleRatio = 2 / 3;
        const lineSampleLength = maxSignalDashLength * lineSampleRatio;
        const headerPadding = 10;
        const borderRadius = 5;
        const cursorLabelClearance = 6;
        const chartHeight = chart.chartArea.bottom - chart.chartArea.top;
        const maxRows = Math.floor(
            (chartHeight -
                outerPadding -
                headerPadding -
                fontSize +
                innerVPadding -
                outerPadding -
                2 * cursorLabelClearance) /
                (fontSize + innerVPadding)
        );
        const nColumns = Math.ceil(
            (points.length +
                (this.signalsNoData ? this.signalsNoData.group?.length : 0)) /
                maxRows
        );
        const nRows = Math.ceil(
            (points.length +
                (this.signalsNoData ? this.signalsNoData.group?.length : 0)) /
                nColumns
        );
        const pointColumns = range(nColumns).map((i) => {
            const colPoints = points.slice(i * nRows, i * nRows + nRows);
            const maxNameWidth = arrayMax(
                colPoints
                    .map((p) => ctx.measureText(p.signal.name).width)
                    .concat(
                        this.signalsNoData
                            ? this.signalsNoData.group.map(
                                  (p) => ctx.measureText(p.name).width
                              )
                            : []
                    )
            );
            ctx.font = `bold ${font}`;
            const maxValueWidth = arrayMax(
                colPoints
                    .map((p) => ctx.measureText(p.labelValue).width)
                    .concat([0])
            );
            ctx.font = font;
            return { points: colPoints, maxNameWidth, maxValueWidth };
        });

        const columnsWidth = sum(
            pointColumns.map(
                (c) =>
                    c.maxNameWidth +
                    innerHPadding +
                    lineSampleLength +
                    innerHPadding +
                    c.maxValueWidth +
                    innerHColPadding
            )
        );
        const totalWidth =
            outerPadding + columnsWidth - innerHColPadding + outerPadding;
        const totalHeight =
            outerPadding +
            headerPadding +
            fontSize * (nRows + 1) +
            innerVPadding * (nRows - 1) +
            outerPadding;

        const canvasRect = canvas.getBoundingClientRect();
        const labelLeft =
            cursorX + totalWidth + margin * 2 < canvasRect.width
                ? cursorX + margin
                : cursorX - totalWidth - margin;
        const labelRight = labelLeft + totalWidth;
        const middleY = (area.top + area.bottom) / 2;
        const labelTop = middleY - totalHeight / 2;
        const labelBottom = middleY + totalHeight / 2;

        // draw the label: signal names + colors, values, round rect border
        ctx.strokeStyle = this.styles.cursorColor;
        ctx.lineWidth = 1;
        ctx.fillStyle = `rgba(${this.styles.backgroundColorRgb}, 0.8)`;
        ctx.beginPath();
        ctx.moveTo(labelLeft, labelTop + borderRadius);
        ctx.arcTo(
            labelLeft,
            labelTop,
            labelLeft + borderRadius,
            labelTop,
            borderRadius
        );
        ctx.lineTo(labelRight - borderRadius, labelTop);
        ctx.arcTo(
            labelRight,
            labelTop,
            labelRight,
            labelTop + borderRadius,
            borderRadius
        );
        ctx.lineTo(labelRight, labelBottom - borderRadius);
        ctx.arcTo(
            labelRight,
            labelBottom,
            labelRight - borderRadius,
            labelBottom,
            borderRadius
        );
        ctx.lineTo(labelLeft + borderRadius, labelBottom);
        ctx.arcTo(
            labelLeft,
            labelBottom,
            labelLeft,
            labelBottom - borderRadius,
            borderRadius
        );
        ctx.lineTo(labelLeft, labelTop + borderRadius);
        ctx.fill();
        ctx.stroke();

        let baselineX = labelLeft + outerPadding;
        this.signalLabelBoxes = [];

        for (const column of pointColumns) {
            const lineSampleX = baselineX + column.maxNameWidth + innerHPadding;
            const valueX = lineSampleX + lineSampleLength + innerHPadding;
            if (column !== pointColumns[0]) {
                ctx.strokeStyle = this.styles.zeroGridlineColor;
                ctx.lineWidth = 1;
                const dividerX =
                    Math.round(baselineX - innerHColPadding / 2 + 0.5) - 0.5;
                ctx.beginPath();
                ctx.moveTo(
                    dividerX,
                    labelTop + outerPadding + fontSize + headerPadding / 2
                );
                ctx.lineTo(dividerX, labelBottom);
                ctx.stroke();
            }
            let baselineY =
                labelTop + outerPadding + fontSize + headerPadding + fontSize;
            ctx.textBaseline = "bottom";
            const columnWidth =
                column.maxNameWidth +
                innerHPadding +
                lineSampleLength +
                innerHPadding +
                column.maxValueWidth;
            for (const point of column.points) {
                this.signalLabelBoxes.push({
                    top: `${baselineY - fontSize - innerVPadding / 2}px`,
                    bottom: `${
                        canvasRect.height - (baselineY + innerVPadding / 2)
                    }px`,
                    left: `${baselineX - innerHColPadding / 2}px`,
                    right: `${
                        canvasRect.width -
                        (baselineX + columnWidth + innerHColPadding / 2)
                    }px`,
                    onClicked: () => this.onSignalToggled(point.signal),
                });

                ctx.textAlign = "right";
                ctx.fillStyle = point.signal.hidden
                    ? `rgba(${this.styles.textColorRgb},0.5)`
                    : this.styles.textColor;
                ctx.fillText(
                    point.signal.name,
                    baselineX + column.maxNameWidth,
                    baselineY
                );

                ctx.textAlign = "left";
                ctx.font = `bold ${font}`;
                ctx.fillText(point.labelValue, valueX, baselineY);
                ctx.font = font;

                const lineSampleY = baselineY - fontSize * 0.6;
                ctx.strokeStyle = point.signal.hidden
                    ? `rgba(${colorToRgb(point.signal.color)},0.5)`
                    : point.signal.color;
                ctx.lineWidth = 1.5;
                ctx.setLineDash(
                    point.signal.dash.map((v) => v * lineSampleRatio)
                );
                ctx.beginPath();
                ctx.moveTo(lineSampleX, lineSampleY);
                ctx.lineTo(lineSampleX + lineSampleLength, lineSampleY);
                ctx.stroke();
                ctx.setLineDash([]);
                baselineY += fontSize + innerVPadding;
            }

            if (this.signalsNoData?.group.length) {
                const noDataSignals = this.signalsNoData.group;
                let i = column.points.length;
                noDataSignals.forEach(() => {
                    const legendSignal = this.legendSignals[i];
                    this.signalLabelBoxes.push({
                        top: `${baselineY - fontSize - innerVPadding / 2}px`,
                        bottom: `${
                            canvasRect.height - (baselineY + innerVPadding / 2)
                        }px`,
                        left: `${baselineX - innerHColPadding / 2}px`,
                        right: `${
                            canvasRect.width -
                            (baselineX + columnWidth + innerHColPadding / 2)
                        }px`,
                        onClicked: () =>
                            this.onNoDataSignalToggled(legendSignal),
                    });
                    ctx.textAlign = "right";
                    ctx.fillStyle = legendSignal.hidden
                        ? `rgba(${this.styles.textColorRgb},0.5)`
                        : this.styles.textColor;
                    ctx.fillText(
                        this.legendSignals[i].name,
                        baselineX + column.maxNameWidth,
                        baselineY
                    );

                    const lineSampleY = baselineY - fontSize * 0.6;
                    ctx.strokeStyle = legendSignal.hidden
                        ? `rgba(${colorToRgb(this.legendSignals[i].color)},0.5)`
                        : this.legendSignals[i].color;
                    ctx.lineWidth = 1.5;
                    ctx.setLineDash(
                        this.legendSignals[i].dash.map(
                            (v) => v * lineSampleRatio
                        )
                    );
                    ctx.beginPath();
                    ctx.moveTo(lineSampleX, lineSampleY);
                    ctx.lineTo(lineSampleX + lineSampleLength, lineSampleY);
                    ctx.stroke();
                    ctx.setLineDash([]);
                    baselineY += fontSize + innerVPadding;
                    i++;
                });
                baselineX += columnWidth + innerHColPadding;
            }
        }

        ctx.textAlign = "center";
        ctx.fillStyle = this.styles.textColor;
        ctx.font = `bold ${ctx.font}`;
        let cursorPosStr = (this.xAxisMgr.cursorPos / 1000).toFixed(3);
        if (!cursorPosStr.startsWith("-")) {
            cursorPosStr = "+" + cursorPosStr;
        }
        ctx.fillText(
            `${cursorPosStr} sec`,
            (labelLeft + labelRight) / 2,
            labelTop + outerPadding + fontSize
        );
    }

    private getCursorPoints(chart: Chart, cursorPos: number): CursorPoint[] {
        const userLimits = this.xAxisMgr.userFineLimits;
        const area = chart.chartArea;
        const points: CursorPoint[] = [];
        const group = this.signals?.group || [];
        for (const [iSignal, signal] of group.entries()) {
            const legendSignal = this.legendSignals[iSignal];
            const onePast = signal.data.findIndex((v) => v[0] > cursorPos);
            let iPoint = onePast - 1;
            if (onePast === -1) {
                // cursor is to the right of all points
                iPoint = signal.data.length - 1;
            } else if (onePast === 0) {
                // cursor is to the left of all points
                iPoint = 0;
            }
            const point = signal.data[iPoint];
            const x =
                area.left +
                ((point[0] - userLimits[0]) * (area.right - area.left)) /
                    (userLimits[1] - userLimits[0]);
            const y = (chart as any).scales[
                legendSignal.yAxisID
            ].getPixelForValue(point[1], iPoint, iSignal);
            const engrString = signal.slot.engrToEngrString(point[1]);
            let labelValue = "?";
            if (engrString) {
                if (signal.unit) {
                    labelValue = `${engrString} ${signal.unit}`;
                } else {
                    labelValue = engrString;
                }
            }
            points.push({
                signal: legendSignal,
                pixelX: x,
                pixelY: y,
                labelValue,
            });
        }
        return points;
    }

    private onDarkModeChange = () => {
        this.getStyles();
        this.updateChart();
    };

    private updateChart() {
        if (this.isChartUpdating) {
            this.isChartUpdateRequested = true;
        } else {
            this.chart?.update();
        }
    }
}
