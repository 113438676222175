import { AdditionalOperation, RulesLogic } from 'json-logic-js';

export const paramDefVersion = 3;

export interface ParamSystemDef {
    params: { [key: string]: ParamDef | ParamDef[] };
    slots: { [key: string]: SlotDef };
    slotLocalizations: SlotLocalizationDef[];
    slotLocalizationSets: SlotLocalizationSetDef[];
    paramTranslations?: { [key: string]: ParamTranslationDef };
    menus: MenuDef;
    dtcs?: { [key: string]: DtcDef | DtcDef[] };
    logging?: LoggingDef;
    configurableGaugesEmbedded?: GaugeDef[];
}

export interface ParamAclDef {
    read: [boolean, boolean];
    write: [boolean, boolean];
    save: boolean;
    mutating?: boolean;
    saveWithCalib?: boolean;
}

export type ParamEffectiveAcl = Omit<ParamAclDef, 'read' | 'write'> & { read: boolean; write: boolean };

export interface ParamDef {
    id: number | string;
    length?: number;
    minLength?: number;
    acl: ParamAclDef;
    slot?: string;
    advice?: 'none' | 'resetAdvised' | 'confirmThenReset' | 'confirmThenResetVariation';
    fullReload?: boolean;
}

export const flattenParamDefs = (params: ParamSystemDef['params']): { [key: string]: ParamDef } => {
    const out: { [key: string]: ParamDef } = {};
    for (const key of Object.keys(params)) {
        const def = params[key];
        if (Array.isArray(def)) {
            for (let i = 0; i < def.length; ++i) {
                out[key + '[' + i.toString() + ']'] = def[i];
            }
        } else {
            out[key] = def;
        }
    }
    return out;
};

export interface EncodingSlotDef {
    encoding: [number, string][];
}

export interface ParamSlotScalerDef {
    param: string;
    slot: string;
    exponent: number;
}

export type SlotScalerDef = number | ParamSlotScalerDef;

export interface LinearSlotDef {
    linear: {
        raw: [number, number];
        engr: [number, number];
        precision: number;
        unit: string;
        scalers?: SlotScalerDef[];
        engrStep?: number;
    };
}

export interface HexSlotDef {
    hex: {
        digits: number;
    };
}

export interface J2012SlotDef {
    j2012: Record<string, never>;
}

export type SlotDef = EncodingSlotDef | LinearSlotDef | HexSlotDef | J2012SlotDef;

export interface SingleSlotLocalizationDef {
    name: string;
    base: string;
    embeddedSaveParam?: string;
    embeddedSlot?: string;
    selections: {
        name: string;
        to: string;
    }[];
}

export interface MultiSlotLocalizationDef {
    name: string;
    base: string[];
    embeddedSaveParam?: string;
    embeddedSlot?: string;
    selections: {
        name: string;
        to: string[];
    }[];
}

export type SlotLocalizationDef = SingleSlotLocalizationDef | MultiSlotLocalizationDef;

export const isSingleSlotLocalizationDef = (def: SlotLocalizationDef): def is SingleSlotLocalizationDef => !(def.base instanceof Array);

export const slotLocalizationDefToMulti = (def: SlotLocalizationDef) => {
    if (isSingleSlotLocalizationDef(def)) {
        return {
            name: def.name,
            base: [def.base],
            selections: def.selections.map((s) => ({ name: s.name, to: [s.to] })),
        };
    } else {
        return def;
    }
};

export interface SlotLocalizationSetDef {
    name: string;
    codes?: string[];
    map: { [key: string]: string };
}

export interface ParamTranslationDef {
    param: string;
    default: string;
    map: { [key: number]: string };
}

export interface ParamGaugeControlDef {
    type: 'ParamGauge' | 'LargeGauge';
    args: {
        param: string;
        slot?: string;
        label: string;
        tooltip: string;
        doc: string;
        range?: [number, number];
        showWhenNotOnTarget?: boolean;
        autorefresh?: boolean;
    };
}

export interface ReadoutControlDef {
    type: 'DigitalGauge' | 'LargeDigitalGauge';
    args: {
        param: string;
        slot?: string;
        label: string;
        tooltip: string;
        doc: string;
        showWhenNotOnTarget?: boolean;
        autorefresh?: boolean;
    };
}

export interface BooleanGaugeControlDef {
    type: 'BooleanGauge';
    args: {
        param: string;
        label: string;
        tooltip: string;
        doc: string;
        showWhenNotOnTarget?: boolean;
        autorefresh?: boolean;
    };
}

export interface ScalarEditControlDef {
    type: 'ScalarEdit' | 'EncodingEdit';
    args: {
        param: string;
        slot?: string;
        label: string;
        tooltip: string;
        doc: string;
        writeOnDestroy?: string;
        showWhenNotOnTarget?: boolean;
        autorefresh?: boolean;
        readOnly?: boolean;
        min?: string;
        max?: string;
        limitSlot?: string;
        showForParams?: ShowForParamsSpec[];
    };
}

export interface BooleanEditControlDef {
    type: 'BooleanEdit';
    args: {
        param: string;
        label: string;
        tooltip: string;
        doc: string;
        writeOnDestroy?: boolean;
        showWhenNotOnTarget?: boolean;
        autorefresh?: boolean;
        readOnly?: boolean;
        showForParams?: ShowForParamsSpec[];
    };
}

export interface TableEditControlDef {
    type: 'TableEdit';
    args: {
        xParam?: string | string[];
        xModel?: {
            count: number;
            min: number;
            stride: number;
        };
        xSlot?: string;
        xPolicy?: string;
        yParam: string | string[];
        ySlot?: string;
        yPolicy?: string;
        title?: string;
        label: string;
        xLabel: string;
        yLabel: string;
        traceLabels?: string[];
        xColWidth?: string;
        yColWidth?: string;
        tooltip: string;
        doc: string;
        showWhenNotOnTarget?: boolean;
        chart?: boolean;
        autorefresh?: boolean;
        readOnly?: boolean;
        showForParams?: ShowForParamsSpec[];
    };
}

export interface MultiTableEditSlotColumnDef {
    count?: number;
    label: string;
    min?: number;
    stride?: number;
    slot: string;
    width?: string;
}

export interface MultiTableEditParamColumnDef {
    param: string;
    label: string;
    slot?: string;
    autorefresh?: boolean;
    width?: string;
    readOnly?: boolean;
    policy?: string;
}

export type MultiTableEditColumnDef = MultiTableEditSlotColumnDef | MultiTableEditParamColumnDef;

export interface MultiTableEditControlDef {
    type: 'MultiTableEdit';
    args: {
        columns: MultiTableEditColumnDef[];
        label: string;
        title?: string;
        tooltip: string;
        doc: string;
        showWhenNotOnTarget?: boolean;
        showForParams?: ShowForParamsSpec[];
    };
}

export interface ShiftTableEditControlDef {
    type: 'ShiftTableEdit';
    args: {
        label: string;
        title?: string;
        tooltip: string;
        doc: string;
        showForParams?: ShowForParamsSpec[];
    };
}

interface MeshEditAxisBase {
    label: string; // if SLOT has a non-empty unit, will be suffixed with SLOT unit in parentheses
    colWidth?: string; // column width in CSS units
    dependentIndex?: boolean; // if true, this axis indexes a list of dependent variable parameters
}

interface MeshEditModelAxis extends MeshEditAxisBase {
    slot: string; // SLOT key
    count?: number; // integer, # entries in the axis; defaults to rawMax - rawMin + 1
    min?: number; // raw value for the first entry; defaults to rawMin
    stride?: number; // spacing between entries; defaults to 1
    policy?: CategoryAxisPolicy;
}

export type ContinuousAxisPolicy =
    | 'fixedEnds' // first and last entry are not editable
    | 'strictIncr' // entries must be strictly increasing
    | 'readOnly'; // axis is not editable
export type CategoryAxisPolicy = 'category'; // axis corresponds to a row of tabs, each entry gets a tab, and axis is not editable

export interface MeshEditParamAxis extends MeshEditAxisBase {
    param: string; // param key
    slot?: string; // SLOT key, defaults to param's canonical SLOT
    policy?: CategoryAxisPolicy | ContinuousAxisPolicy | ContinuousAxisPolicy[]; // defaults to ['fixedEnds', 'strictIncr']
}

export type MeshEditAxis = MeshEditModelAxis | MeshEditParamAxis;

export const isMeshEditParamAxis = (axis: MeshEditAxis): axis is MeshEditParamAxis => 'param' in axis;

export type CategoryAxis = MeshEditAxis & { policy: CategoryAxisPolicy };

interface MeshEditParamDependent {
    label: string;
    colWidth?: string; // column width in CSS units
    param: string | string[] | string[][] | string[][][]; // param key
    slot?: string; // SLOT key, defaults to param's canonical SLOT
    readonly?: boolean; // defaults to false
    plot?: boolean; // show on 2D chart; defaults to true
}

interface MeshEditCalcDependent {
    label: string;
    colWidth?: string; // column width in CSS units
    expr: string; // expression to evaluate
    params: string[]; // param keys to make available in the math evaluator context
    slot: string; // SLOT key
    plot?: boolean; // show on 2D chart; defaults to false
}

export type MeshEditDependent = MeshEditCalcDependent | MeshEditParamDependent;

export const isMeshEditParamDependent = (dep: MeshEditDependent): dep is MeshEditParamDependent => 'param' in dep;

type MeshEditVisualization =
    | null // no visualization; default if no non-category axis exists
    | 'chart' // default when using exactly one non-category axis; supported only for that case
    | 'heatmap'; // default when using multiple non-category axes

export interface MeshEditControlDef {
    type: 'MeshEdit';
    args: {
        label: string; // label and chart title
        title?: string; // chart title
        tooltip: string;
        doc: string;
        showForParams?: ShowForParamsSpec[];
        visual?: MeshEditVisualization;
        axes: MeshEditAxis[];
        dependents: MeshEditDependent[];
    };
}

export interface ParamSetterControlDef {
    type: 'ParamSetter';
    args: {
        items: Array<[number | string | Array<number | string>, string]>;
        warning?: string;
        label: string;
        tooltip: string;
        doc: string;
        showWhenNotOnTarget?: boolean;
        showForParams?: ShowForParamsSpec[];
    };
}

export interface ParamCopierControlDef {
    type: 'ParamCopier';
    args: {
        items: Array<[string, string]>;
        warning?: string;
        label: string;
        tooltip: string;
        doc: string;
        showWhenNotOnTarget?: boolean;
        showForParams?: ShowForParamsSpec[];
    };
}

export interface CalibScreenControlDef {
    type: 'CalibScreen';
    args: {
        inhibitParam: string;
        startParam: string;
        progressParam: string;
        minParam?: string;
        maxParam?: string;
        valueParam?: string;
        slot?: string;
        label: string;
        tooltip: string;
        doc: string;
        showForParams?: ShowForParamsSpec[];
    };
}

export interface FaultCodeListControlDef {
    type: 'FaultCodeList';
    args: {
        label: string;
        tooltip: string;
        doc: string;
        showForParams?: ShowForParamsSpec[];
    };
}

export interface TextDisplayControlDef {
    type: 'TextDisplay';
    args: {
        param: string;
        label: string;
        tooltip: string;
        doc: string;
        showWhenNotOnTarget?: boolean;
        autorefresh?: boolean;
        showForParams?: ShowForParamsSpec[];
    };
}

export interface TextGaugeControlDef {
    type: 'TextGauge';
    args: {
        param: string;
        label: string;
        tooltip: string;
        doc: string;
        showWhenNotOnTarget?: boolean;
        autorefresh?: boolean;
    };
}

export interface HalfScreenTextGaugeControlDef {
    type: 'HalfScreenTextGauge';
    args: {
        param: string;
        tooltip: string;
        doc: string;
    };
}

export interface SectionDividerControlDef {
    type: 'SectionDivider';
    args: {
        text?: string;
        headerStyle?: boolean;
        showForParams?: ShowForParamsSpec[];
    };
}

export type GaugeDef =
    | ParamGaugeControlDef
    | ReadoutControlDef
    | BooleanGaugeControlDef
    | TextGaugeControlDef
    | HalfScreenTextGaugeControlDef;

export interface GaugeGroupDef {
    type: 'GaugeGroup';
    args: {
        title?: string;
        tooltip: string;
        doc: string;
        children: GaugeDef[];
        showForParams?: ShowForParamsSpec[];
    };
}

export interface ConfigurableGaugeGroupDef {
    type: 'ConfigurableGaugeGroup';
    args: {
        title?: string;
        tooltip: string;
        doc: string;
        index: number;
        default: string[];
        showForParams?: ShowForParamsSpec[];
    };
}

export interface V1ConfigurableGaugeGroupDef {
    type: 'ConfigurableGaugeGroup';
    args: {
        title?: string;
        tooltip: string;
        doc: string;
        index: number;
        default: number[];
        showForParams?: ShowForParamsSpec[];
    };
}

export type ShowForParamsSpec =
    | string
    | { key: string }
    | { key: string; include: string[] | string[][] }
    | { key: string; exclude: string[] | string[][] };

export interface MenuDef<ExtraChildTypes = never> {
    type: 'Menu';
    args: {
        title: string;
        label?: string;
        tooltip: string;
        doc: string;
        children: (MenuChildDef<ExtraChildTypes> | ExtraChildTypes)[];
        showForParams?: ShowForParamsSpec[];
    };
}

export type ItemDef<ExtraChildTypes = never> =
    | ParamGaugeControlDef
    | ReadoutControlDef
    | BooleanGaugeControlDef
    | ScalarEditControlDef
    | BooleanEditControlDef
    | MeshEditControlDef
    | TableEditControlDef
    | MultiTableEditControlDef
    | ShiftTableEditControlDef
    | ParamSetterControlDef
    | ParamCopierControlDef
    | CalibScreenControlDef
    | FaultCodeListControlDef
    | TextDisplayControlDef
    | TextGaugeControlDef
    | HalfScreenTextGaugeControlDef
    | SectionDividerControlDef
    | GaugeGroupDef
    | ConfigurableGaugeGroupDef
    | V1ConfigurableGaugeGroupDef
    | MenuDef<ExtraChildTypes>
    | PersonalityMenuDef;

export type MenuChildDef<ExtraChildTypes = never> =
    | ScalarEditControlDef
    | BooleanEditControlDef
    | MeshEditControlDef
    | TableEditControlDef
    | MultiTableEditControlDef
    | ShiftTableEditControlDef
    | ParamSetterControlDef
    | ParamCopierControlDef
    | CalibScreenControlDef
    | FaultCodeListControlDef
    | TextDisplayControlDef
    | SectionDividerControlDef
    | GaugeGroupDef
    | ConfigurableGaugeGroupDef
    | V1ConfigurableGaugeGroupDef
    | MenuDef<ExtraChildTypes>
    | PersonalityMenuDef;

export const nullMenu: MenuDef = {
    type: 'Menu',
    args: {
        title: '',
        tooltip: '',
        doc: '',
        children: [],
    },
};

export type PersonalityMenuChildDef = ScalarEditControlDef | BooleanEditControlDef;

/**
 * fetches a param's canonical-SLOT engineering value, taking its key as a parameter;
 * this will appear in the rules as `{ param: "foo" }`, so `ValidParamKeys` will check it
 */
interface ConditionalInitParamOp extends AdditionalOperation {
    param: string;
}

interface ConditionalInitInterp1dOp extends AdditionalOperation {
    interp1d: [any, any, any];
    // TODO: should be a triple of RulesLogic<ConditionalInitParamOp & ConditionalInitInterp1dOp> but this breaks somewhere
}

export type ConditionalInitLogic = RulesLogic<ConditionalInitParamOp | ConditionalInitInterp1dOp>;
export type ConditionalInitValue = number | number[];

export const isConditionalInitValue = (v: any): v is ConditionalInitValue =>
    typeof v === 'number' || (Array.isArray(v) && v.every((e) => typeof e === 'number'));

export interface ConditionalInitAlias {
    alias: string;
    value: ConditionalInitValue | ConditionalInitLogic;
}

interface ConditionalInitBase {
    value: ConditionalInitValue | ConditionalInitLogic;
}

export type ConditionalSingleInit = ConditionalInitBase & { param: string };
export type ConditionalMultiInit = ConditionalInitBase & { params: string[] };
export interface ConditionalInitGroup {
    cond: ConditionalInitLogic;
    inits: Array<ConditionalSingleInit | ConditionalMultiInit>;
}

export interface ConditionalInitSentinel {
    param: string;
    setValue?: ConditionalInitValue | ConditionalInitLogic;
    clearValue?: ConditionalInitValue | ConditionalInitLogic;
    rebootAfterSet?: boolean;
}

export interface ConditionalParamInits {
    aliases: Array<ConditionalInitAlias>;
    inits: Array<ConditionalInitGroup>;
    sentinel?: ConditionalInitSentinel;
}

export interface PersonalityMenuDef {
    type: 'PersonalityMenu';
    args: {
        title: string;
        label?: string;
        tooltip: string;
        doc: string;
        children: PersonalityMenuChildDef[];
        showForParams?: ShowForParamsSpec[];
        paramInits?: ConditionalParamInits;
    };
}

export interface LoggingParamDef {
    param: string;
    label: string;
    header?: string;
    name?: string;
    gaugeType?: GaugeDef['type'];
    gaugeSlot?: string;
    tooltip?: string;
    doc?: string;
}

export interface LoggingParamGroupDef {
    label: string;
    params: LoggingParamDef[];
}

export interface LoggingSetDef {
    label: string;
    params: string[];
}

export interface LoggingDef {
    params: LoggingParamGroupDef[];
    sets: LoggingSetDef[];
}

export const isMenuDef = (a: any): a is MenuDef =>
    a.type === 'Menu' &&
    typeof a.args === 'object' &&
    typeof a.args.title === 'string' &&
    a.args.children instanceof Array &&
    a.args.children.every((child: any) => typeof child.type === 'string' && typeof child.args === 'object');

type DtcPrefix = 'P' | 'C' | 'B' | 'U';
type QuatDigit = '0' | '1' | '2' | '3';
type HexDigit = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F';

export interface DtcDef {
    code: `${DtcPrefix}${QuatDigit}${HexDigit}${HexDigit}${HexDigit}`;
}

export type DeepReadonly<T> = T extends Record<string, any> ? { readonly [K in keyof T]: DeepReadonly<T[K]> } : T;

/**
 * traverses the tree `T` and enforces that any object member called `slot` must conform to the type `SlotKey`
 */
export type ValidSlotKeys<T, SlotKey extends string> =
    T extends Record<string, any> ? { [K in keyof T]: K extends 'slot' ? SlotKey : ValidSlotKeys<T[K], SlotKey> } : T;

type ScalarWrapperSpec<ParamKey extends string> = ParamKey | `${ParamKey}[${number}]`;

type ScalarWrapperKey = 'param';

/**
 * traverses the tree `T` and enforces that any object member called `param`
 * must have a valid scalar-wrapper spec for its value, based on the keys included in `ParamKey`
 */
export type ValidParamKeys<T, ParamKey extends string> =
    T extends Record<string, any>
        ? { [K in keyof T]: K extends ScalarWrapperKey ? ScalarWrapperSpec<ParamKey> : ValidParamKeys<T[K], ParamKey> }
        : T;

// below seems like it should work, but fails weirdly
// type RawHostMenuChildDef<T extends { type: any, args: any }> =
//     ({ type: T['type'] } | { h_type: T['type'] }) & ({ args: T['args'] } | { h_args: T['args'] });
// type RawEmbeddedMenuChildDef<T extends { type: any, args: any }> =
//     ({ type: T['type'] } | { e_type: T['type'] }) & ({ args: T['args'] } | { e_args: T['args'] });
// export type RawMenuChildDef<T> = T extends { type: any, args: any } ? RawHostMenuChildDef<T> | RawEmbeddedMenuChildDef<T> : T;

type RawMenuChildDef<T> = T extends { type: any; args: any }
    ? {
          type?: T['type'];
          h_type?: T['type']; // eslint-disable-line @typescript-eslint/naming-convention
          e_type?: T['type']; // eslint-disable-line @typescript-eslint/naming-convention
          args?: T['args'];
          h_args?: T['args']; // eslint-disable-line @typescript-eslint/naming-convention
          e_args?: T['args']; // eslint-disable-line @typescript-eslint/naming-convention
      }
    : T;

export type ReadonlySlotLocalizationDefs = DeepReadonly<SlotLocalizationDef[]>;
export type ReadonlySlotLocalizationSetDefs = DeepReadonly<SlotLocalizationSetDef[]>;
export type ReadonlyEncodingSlotDef = DeepReadonly<EncodingSlotDef>;
export type ReadonlyDtcsDef = DeepReadonly<ParamSystemDef['dtcs']>;

// Recursively turn all MenuChildDef into their raw equivalents
export type MenuChildDefsRaw<T extends Record<string, any>> = {
    [K in keyof T]: RawMenuChildDef<T[K] extends Record<string, any> ? MenuChildDefsRaw<T[K]> : T[K]>;
};
type RawMenuParamSystemDef<ExtraChildTypes> = MenuChildDefsRaw<Omit<ParamSystemDef, 'menus'> & { menus: MenuDef<ExtraChildTypes> }>;

type DefSlotKey<Slots, SlotL10ns extends DeepReadonly<Array<SlotLocalizationDef>>> = (
    | keyof Slots
    | SlotL10ns[number]['embeddedSaveParam']
) &
    string;
type DefParamKey<Params> = keyof Params & string;

export type ValidParamSystemDef<
    Slots extends Record<string, any>,
    Params extends Record<string, any>,
    SlotL10ns extends DeepReadonly<Array<SlotLocalizationDef>>,
    ExtraChildTypes,
> = DeepReadonly<ValidParamKeys<ValidSlotKeys<RawMenuParamSystemDef<ExtraChildTypes>, DefSlotKey<Slots, SlotL10ns>>, DefParamKey<Params>>>;
