import { Slot } from './slot';

export class NullSlot extends Slot {
    constructor() {
        super('', 0, 10);
    }

    toEngrFloat = (value: number | undefined): number | undefined => value;

    toEngrString = (value: number | undefined): string | undefined => {
        if (value === undefined || isNaN(value)) {
            return undefined;
        }
        return value.toString();
    };

    toRawFloat = (value: number | string | undefined): number | undefined => {
        let engrConv: number | undefined;
        if (typeof value === 'string') {
            engrConv = parseFloat(value);
        } else if (typeof value === 'number' && !isNaN(value)) {
            engrConv = value;
        }
        return engrConv;
    };

    rawInRange = (value: number | undefined): boolean => value !== undefined && !isNaN(+value);

    engrInRange = (value: number | string | undefined): boolean => value !== undefined && !isNaN(+value);

    engrNumOrStringToNum = (value: number | string | undefined): any => this.toRaw(value);

    get rawMin() {
        return Number.MIN_VALUE;
    }
    get rawMax() {
        return Number.MAX_VALUE;
    }
    get rawStep() {
        return 1;
    }
    get engrMin() {
        return Number.MIN_VALUE;
    }
    get engrMax() {
        return Number.MAX_VALUE;
    }
    get engrStep() {
        return 1;
    }
    get rawIsInt() {
        return false;
    }
}

export const nullSlot = new NullSlot();
