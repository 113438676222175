import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, NEVER, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { truthyOnly } from '../../util/core';
import { LOGGER, Logger } from '../../util/logger';
import { Slot } from '../core/slot';
import { ParamSystemDefRecord, SlotLocalizationDef, SlotLocalizationSetDef, slotLocalizationDefToMulti } from '../util/param-system-def';
import { DisplaySlotLocalizer } from './display-slot-localizer';
import { SlotRegistry } from './slot-registry';

class SlotL10n {
    name: string;
    base: Slot[];
    selections: { name: string; to: Slot[] }[];

    private indexSubj = new BehaviorSubject<number>(0);

    constructor(reg: SlotRegistry, def: SlotLocalizationDef) {
        const { name, base, selections } = slotLocalizationDefToMulti(def);

        if (!selections.every((s) => s.to.length === base.length)) {
            throw new Error('SLOT localization ' + name + ' has inconsistent canonical and display array lengths');
        }

        this.name = name;
        this.base = base.map(reg.slotByKey);
        this.selections = selections.map((selDef) => ({
            name: selDef.name,
            to: selDef.to.map(reg.slotByKey),
        }));
    }

    get index() {
        return this.indexSubj.value;
    }

    set index(value) {
        this.indexSubj.next(value);
    }

    get indexObs() {
        return this.indexSubj.asObservable();
    }
}

class SlotL10nSet {
    name: string;
    codes: string[];
    selections = new Map<SlotL10n, number>();

    constructor(reg: SlotRegistry, l10ns: Array<SlotL10n>, def: SlotLocalizationSetDef) {
        this.name = def.name;
        this.codes = def.codes || [];

        for (const key of Object.keys(def.map)) {
            const canonicalSlot = reg.slotByKey(key);
            const localizedSlot = reg.slotByKey(def.map[key]);

            // now find a SLOT localization with this mapping
            const l10n = l10ns.find((l) => l.base[0] === canonicalSlot);
            if (!l10n) {
                throw new Error('SLOT localization not found for ' + key + ' in localization set ' + this.name);
            }
            const index = l10n.selections.findIndex((s) => s.to[0] === localizedSlot);
            if (index < 0) {
                throw new Error('SLOT localization index not found for ' + def.map[key] + ' in localization set ' + this.name);
            }

            this.selections.set(l10n, index);
        }
    }
}

@Injectable({ providedIn: 'root' })
export class ParamDefDisplaySlotLocalizer implements DisplaySlotLocalizer {
    private l10ns = new Array<SlotL10n>();
    private l10nSets = new Array<SlotL10nSet>();
    private change = new Subject<void>();
    private slotMap = new Map<Slot, { l10n: SlotL10n; index: number }>();
    private readySubj = new BehaviorSubject<ParamSystemDefRecord | undefined>(undefined);
    private indicesInt: Record<string, number> = {};

    constructor(
        @Inject(LOGGER) private logger: Logger,
        reg: SlotRegistry,
    ) {
        reg.ready().subscribe((rec) => {
            this.l10ns = rec.def.slotLocalizations.map((l10nDef) => new SlotL10n(reg, l10nDef));
            this.l10nSets = rec.def.slotLocalizationSets.map((setDef) => new SlotL10nSet(reg, this.l10ns, setDef));
            for (const l10n of this.l10ns) {
                for (let i = 0; i < l10n.base.length; ++i) {
                    const slot = l10n.base[i];
                    if (this.slotMap.has(slot)) {
                        throw new Error(`Multiple localizations for canonical slot ${reg.keyFromSlot(slot)}`);
                    }
                    this.slotMap.set(slot, { l10n, index: i });
                }
            }

            if (this.l10nSets.length === 0) {
                this.logger.info(`No localization sets available in param def`);
            }
            try {
                const languages = (navigator.languages || []).length ? navigator.languages : [navigator.language];
                const countryCode = languages
                    .map((lang) => {
                        const match = /^[a-z]{2}[-_]([a-z]{2})$/i.exec(lang);
                        return match ? match[1] : undefined;
                    })
                    .filter((v) => v)[0];
                if (!countryCode) {
                    throw `No country code extracted from [${languages.join(', ')}], cannot select localization set`;
                }
                let matchingSet = this.l10nSets.find((set) => set.codes.includes(countryCode.toLowerCase()));
                if (matchingSet) {
                    this.logger.info(`Matched country code ${countryCode}, selecting localization set ${matchingSet.name}`);
                } else {
                    matchingSet = this.l10nSets.find((set) => set.codes.includes('default'));
                    if (matchingSet) {
                        this.logger.info(
                            `No match found for country code ${countryCode}, falling back to default localization set ${matchingSet.name}`,
                        );
                    } else {
                        throw `No match found for country code ${countryCode} and no default found, not selecting any localization set`;
                    }
                }
                matchingSet.selections.forEach((index, l10n) => (l10n.index = index));
            } catch (err) {
                this.logger.warn(err);
            }

            this.updateFromIndices();
            this.readySubj.next(rec);
            this.change.next();
        });
    }

    ready() {
        return this.readySubj.pipe(truthyOnly());
    }

    get localizations(): { name: string; entries: string[]; index: number }[] {
        return this.l10ns.map((l) => ({
            name: l.name,
            entries: l.selections.map((s) => s.name),
            index: l.index,
        }));
    }

    get localizationSets(): string[] {
        return this.l10nSets.map((s) => s.name);
    }

    get localizationSet(): string | undefined {
        const activeMap = new Map<SlotL10n, number>();
        for (const l10n of this.l10ns) {
            activeMap.set(l10n, l10n.index);
        }

        for (const set of this.l10nSets) {
            const l10ns = Array.from(set.selections.keys());
            if (l10ns.every((l10n) => activeMap.get(l10n) === set.selections.get(l10n))) {
                return set.name;
            }
        }

        return undefined;
    }

    set localizationSet(name: string | undefined) {
        const set = this.l10nSets.find((s) => s.name === name);
        if (set) {
            let changed = false;
            set.selections.forEach((index, l10n) => {
                if (l10n.index !== index) {
                    changed = true;
                    l10n.index = index;
                    this.indicesInt[l10n.name] = index;
                }
            });
            if (changed) {
                this.change.next();
            }
        }
    }

    get localizationsChangeObs() {
        // used to save user selections to persistent store
        return this.change.asObservable();
    }

    get indices() {
        return this.indicesInt;
    }

    set indices(value) {
        this.indicesInt = value;
        this.updateFromIndices();
    }

    setIndex(name: string, index: number) {
        const l10n = this.l10ns.find((l) => l.name === name);

        if (l10n && index >= 0 && index < l10n.selections.length && index !== l10n.index) {
            l10n.index = index;
            this.indicesInt[name] = index;
            this.change.next();
        }
    }

    localize(canonicalSlot: Slot): Slot {
        const entry = this.slotMap.get(canonicalSlot);
        if (entry) {
            const selection = entry.l10n.selections[entry.l10n.index];
            return selection.to[entry.index];
        } else {
            return canonicalSlot;
        }
    }

    changeObs(canonicalSlot: Slot): Observable<void> {
        const entry = this.slotMap.get(canonicalSlot);
        if (entry) {
            return entry.l10n.indexObs.pipe(map((_) => undefined));
        } else {
            return NEVER;
        }
    }

    private updateFromIndices() {
        for (const key of Object.keys(this.indicesInt)) {
            const index = this.indicesInt[key];
            if (typeof key === 'string' && typeof index === 'number') {
                this.setIndex(key, this.indicesInt[key]);
            }
        }
    }
}
