import {
    Component,
    HostListener,
    Input,
    ViewChildren,
    QueryList,
} from "@angular/core";
import { Platform } from "@ionic/angular";
import { ResizeEvent } from "angular-resizable-element";
import { BehaviorSubject, combineLatest, of, Subscription } from "rxjs";
import { delayWhen, distinctUntilChanged, filter, map } from "rxjs/operators";
import { delayToFrame } from "../../hgm-setup/src/module/util/core";
import { DataProvider } from "../../providers/data-provider";
import {
    GroupSignal,
    SignalGroupProvider,
} from "../../providers/signal-group-provider";
import {
    RangeMap,
    SignalSettingsProvider,
    StyleMap,
} from "../../providers/signal-settings-provider";
import { XAxisManager, XLimits } from "../../providers/x-axis-manager";
import { SignalNoData } from "../../providers/data-provider";
import { SignalChartComponent } from "./signal-chart.component";

class StyledGroup {
    style: { [key: string]: any };
    isUpdating = true;

    private heightScaleInt = 1;
    private nominalHeightInt: number;

    constructor(
        public group: GroupSignal[] | SignalNoData[],
        public styles: StyleMap,
        public ranges: RangeMap,
        nominalHeight: number
    ) {
        this.nominalHeightInt = nominalHeight;
        this.makeStyle();
    }

    get heightScale() {
        return this.heightScaleInt;
    }

    set heightScale(value) {
        this.heightScaleInt = value;
        this.makeStyle();
    }

    get nominalHeight() {
        return this.nominalHeightInt;
    }

    set nominalHeight(value) {
        this.nominalHeightInt = value;
        this.makeStyle();
    }

    get height() {
        return this.nominalHeight * this.heightScale;
    }

    set height(value) {
        this.heightScaleInt = value / this.nominalHeight;
        this.makeStyle();
    }

    private makeStyle() {
        const height = Math.floor(this.nominalHeight * this.heightScale);
        this.style = {
            height: `${height}px`, // eslint-disable-line quote-props
            "max-height": `${height}px`,
        };
    }
}

const minNominalHeight = 240;

@Component({
    selector: "app-tab-charts",
    templateUrl: "tab-charts.page.html",
})
export class TabChartsPageComponent {
    isMobile = false;
    styledGroups: StyledGroup[] = [];
    styledGroupsNoData: StyledGroup[] = [];

    private subscriptions = new Array<Subscription>();
    private tabHeightSubj = new BehaviorSubject(0);
    private isUpdatingSubj = new BehaviorSubject(true);
    private signalsAreUpdatingSubj = new BehaviorSubject(true);
    private viewfinderIsUpdatingSubj = new BehaviorSubject(true);
    private deferredKey: KeyboardEvent | undefined;

    constructor(
        private dataProvider: DataProvider,
        platform: Platform,
        private groupProvider: SignalGroupProvider,
        private signalSetter: SignalSettingsProvider,
        private xAxisManager: XAxisManager
    ) {
        this.isMobile = platform.is("mobile");
    }

    ngOnInit() {
        const styledGroupsNoDataObs =
            this.groupProvider.styledGroupsNoDataNonNullObs.pipe(
                distinctUntilChanged()
            );
        this.subscriptions.push(
            styledGroupsNoDataObs.subscribe(({ groups, styles }) => {
                this.styledGroupsNoData = groups.map(
                    (group) =>
                        new StyledGroup(
                            group,
                            styles,
                            this.signalSetter.ranges,
                            this.nominalHeight(groups.length)
                        )
                );
            })
        );
        const styledGroupsObs = this.groupProvider.styledGroupsNonNullObs.pipe(
            distinctUntilChanged()
        );
        this.subscriptions.push(
            styledGroupsObs.subscribe(({ groups, styles }) => {
                this.xAxisManager.cursorPos = 0;
                this.deferredKey = undefined;
                this.styledGroups = groups.map(
                    (group) =>
                        new StyledGroup(
                            group,
                            styles,
                            this.signalSetter.ranges,
                            this.nominalHeight(groups.length)
                        )
                );
            })
        );
        this.subscriptions.push(
            this.tabHeightSubj.subscribe(() => {
                const nominalHeight = this.nominalHeight(
                    this.styledGroups.length
                );
                this.styledGroups.forEach(
                    (styledGroup) => (styledGroup.nominalHeight = nominalHeight)
                );
            })
        );
        const isUpdatingObs = combineLatest([
            this.signalsAreUpdatingSubj,
            this.viewfinderIsUpdatingSubj,
        ]).pipe(map(([signals, charts]) => signals || charts));
        this.subscriptions.push(
            isUpdatingObs
                .pipe(delayWhen((value) => (value ? of(null) : delayToFrame())))
                .subscribe(this.isUpdatingSubj)
        );
        this.subscriptions.push(
            this.isUpdatingSubj
                .pipe(
                    distinctUntilChanged(),
                    filter((v) => !v)
                )
                .subscribe(() => {
                    if (this.deferredKey) {
                        const deferredKey = this.deferredKey;
                        this.deferredKey = undefined;
                        this.handleKey(deferredKey);
                    }
                })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        this.subscriptions = [];
    }

    @Input() get tabHeight() {
        return this.tabHeightSubj.value;
    }

    set tabHeight(value) {
        if (value !== this.tabHeightSubj.value) {
            this.tabHeightSubj.next(value);
            this.styledGroups.forEach(
                (styledGroup) =>
                    (styledGroup.nominalHeight = this.nominalHeight(
                        this.styledGroups.length
                    ))
            );
        }
    }

    get dataError() {
        return this.dataProvider.result?.error;
    }

    get isLoaded() {
        return !!this.groupProvider.styledGroups;
    }

    @Input() get viewfinderIsUpdating() {
        return this.viewfinderIsUpdatingSubj.value;
    }

    set viewfinderIsUpdating(value) {
        if (value !== this.viewfinderIsUpdatingSubj.value) {
            this.viewfinderIsUpdatingSubj.next(value);
        }
    }

    onChartResizing(event: ResizeEvent, styledGroup: StyledGroup) {
        if (event.rectangle.height === undefined) {
            console.error(`Chart resize event's rectangle had no height`);
            return;
        }
        styledGroup.height = event.rectangle.height;
    }

    onChartResizeEnd(event: ResizeEvent, styledGroup: StyledGroup) {
        this.onChartResizing(event, styledGroup);
    }

    onChartIsUpdating(isUpdating: boolean, styledGroup: StyledGroup) {
        styledGroup.isUpdating = isUpdating;
        this.signalsAreUpdatingSubj.next(
            this.styledGroups.some((group) => group.isUpdating)
        );
    }

    @HostListener("window:keydown", ["$event"])
    onKeydown(event: KeyboardEvent) {
        if (this.isUpdatingSubj.value) {
            // if we already have a deferred key, discard this one
            this.deferredKey = this.deferredKey || event;
        }
        if (!this.deferredKey) {
            this.handleKey(event);
        }
    }

    private handleKey(event: KeyboardEvent) {
        const span =
            this.xAxisManager.userFineLimits[1] -
            this.xAxisManager.userFineLimits[0];
        const step = event.shiftKey
            ? span
            : event.ctrlKey
            ? span / 50
            : span / 10;
        let delta = 0;
        switch (event.key) {
            case "ArrowRight":
            case " ":
                delta = Math.min(
                    step,
                    this.xAxisManager.userCoarseLimits[1] -
                        this.xAxisManager.userFineLimits[1]
                );
                break;
            case "ArrowLeft":
                delta = Math.max(
                    -step,
                    this.xAxisManager.userCoarseLimits[0] -
                        this.xAxisManager.userFineLimits[0]
                );
                break;
        }
        if (delta !== 0) {
            this.xAxisManager.userFineLimits =
                this.xAxisManager.userFineLimits.map(
                    (l) => l + delta
                ) as XLimits;
        }
    }

    private nominalHeight(nGroups: number) {
        const nCharts = Math.max(nGroups, 1);
        return Math.max(this.tabHeight / nCharts, minNominalHeight);
    }
}
