import { Injectable } from '@angular/core';
import {
    ChartDataset,
    LinearScaleOptions,
    ScatterDataPoint,
} from 'chart.js';
import { DeepPartial } from 'chart.js/types/utils';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { arrayMax } from '../hgm-setup/src/module/util/core';
import { LogFileProvider } from '../hgm-setup/src/module/util/log-file-provider';
import { DataProvider, Signal } from './data-provider';
import { GlobalSettingsProvider } from './global-settings-provider';

export type XLimits = [number, number];

const nullLimits: XLimits = [0, 1];

export interface VfParams {
    datasets: ChartDataset<'scatter', ScatterDataPoint[]>[];
    yAxes: { [key: string]: DeepPartial<LinearScaleOptions> & { type: 'linear' } };
}

export interface XPixelInset {
    left: number;
    right: number;
}

@Injectable({ providedIn: 'root' })
export class XAxisManager {
    private dataLimitsSubj = new BehaviorSubject<XLimits>(nullLimits);
    private userCoarseLimitsSubj = new BehaviorSubject<XLimits>(nullLimits);
    private userFineLimitsSubj = new BehaviorSubject<XLimits>(nullLimits);
    private cursorPosSubj = new BehaviorSubject<number | undefined>(undefined);
    private vfParamsSubj = new BehaviorSubject<VfParams | undefined>(undefined);
    private vfMap = new Map<number, VfParams>();
    private chartInsetSubj = new BehaviorSubject({ left: 0, right: 0 });
    private chartMinInsets = new Map<number, XPixelInset>();

    constructor(
        private dataProvider: DataProvider,
        logProvider: LogFileProvider,
        private globalSettings: GlobalSettingsProvider,
    ) {
        logProvider.regReadyObs.subscribe({ next: this.onFile });
    }

    get dataLimits(): XLimits {
        return this.dataLimitsSubj.value.slice() as XLimits;
    }

    get dataLimitsObs() {
        return this.dataLimitsSubj.asObservable().pipe(map(v => v.slice() as XLimits));
    }

    get userCoarseLimits(): XLimits {
        return this.userCoarseLimitsSubj.value.slice() as XLimits;
    }

    set userCoarseLimits(v) {
        if (v[0] !== this.userCoarseLimitsSubj.value[0] || v[1] !== this.userCoarseLimitsSubj.value[1]) {
            this.userCoarseLimitsSubj.next(v);
        }
    }

    get userCoarseLimitsObs() {
        return this.userCoarseLimitsSubj.asObservable().pipe(map(v => v.slice() as XLimits));
    }

    get userFineLimits(): XLimits {
        return this.userFineLimitsSubj.value.slice() as XLimits;
    }

    set userFineLimits(v) {
        if (v[0] !== this.userFineLimitsSubj.value[0] || v[1] !== this.userFineLimitsSubj.value[1]) {
            if (this.cursorPosSubj.value !== undefined) {
                const prev = this.userFineLimitsSubj.value;
                const cursorFrac = (this.cursorPosSubj.value - prev[0]) / (prev[1] - prev[0]);
                this.cursorPosSubj.next(cursorFrac * (v[1] - v[0]) + v[0]);
            }
            this.userFineLimitsSubj.next(v);
        }
    }

    get userFineLimitsObs() {
        return this.userFineLimitsSubj.asObservable().pipe(map(v => v.slice() as XLimits));
    }

    get cursorPos(): number | undefined {
        return this.cursorPosSubj.value;
    }

    set cursorPos(v) {
        if (v !== this.cursorPosSubj.value) {
            this.cursorPosSubj.next(v);
        }
    }

    get cursorPosObs() {
        return this.cursorPosSubj.asObservable();
    }

    get vfParams() {
        return this.vfParamsSubj.value;
    }

    get vfParamsObs() {
        return this.vfParamsSubj.asObservable();
    }

    get chartInset() {
        return this.chartInsetSubj.value;
    }

    get chartInsetObs() {
        return this.chartInsetSubj.asObservable();
    }

    setVf(id: number, p: VfParams) {
        this.vfMap.set(id, p);
        this.updateVf();
    }

    setMinInset(id: number, minInset: XPixelInset) {
        const existing = this.chartMinInsets.get(id);
        if (existing && Math.abs(existing.left - minInset.left) < 0.001 && Math.abs(existing.right - minInset.right) < 0.001) {
            return;
        }
        this.chartMinInsets.set(id, minInset);
        this.updateInsets();
    }

    clearChart(id: number) {
        this.vfMap.delete(id);
        this.chartMinInsets.delete(id);
        this.updateVf();
        this.updateInsets();
    }

    private updateVf() {
        const ids = Array.from(this.vfMap.keys()).sort();
        const params = ids.map(id => this.vfMap.get(id)!);
        this.vfParamsSubj.next({
            datasets: new Array().concat(...params.map(p => p.datasets)),
            yAxes: params.reduce((a, b) => ({ ...a, ...b.yAxes }), {}),
        });
    }

    private updateInsets() {
        const minInsets = Array.from(this.chartMinInsets.values());
        const inset = minInsets.reduce((prev, cur) => ({
            left: Math.max(prev.left, cur.left),
            right: Math.min(prev.right, cur.right)
        }), { left: 0, right: 0 });
        this.chartInsetSubj.next(inset);
    }

    private onFile = () => {
        let dataLimits = nullLimits;
        if (this.dataProvider.data) {
            const signals = (Object.values(this.dataProvider.data.signals) as Signal[]).filter(sig => sig.data.length);
            const starts = signals.map(sig => sig.data[0][0]);
            const ends = signals.map(sig => sig.data[sig.data.length - 1][0]);
            // Set left limit to zero if no data precedes zero
            dataLimits = [Math.min(0, ...starts), arrayMax(ends)];
        }
        this.dataLimitsSubj.next(dataLimits);

        const preferredCoarseWidth = this.globalSettings.preferredCoarseWidth;
        const coarseLeft = dataLimits[0];
        const coarseRight = Math.min(dataLimits[1], coarseLeft + preferredCoarseWidth);
        this.userCoarseLimitsSubj.next([coarseLeft, coarseRight]);

        const preferredFineWidth = this.globalSettings.preferredFineWidth;
        const fineLeft = coarseLeft;
        const fineRight = Math.min(coarseRight, fineLeft + preferredFineWidth);
        this.userFineLimitsSubj.next([fineLeft, fineRight]);

        this.cursorPosSubj.next(undefined);
    };
}
