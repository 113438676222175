import { Injectable } from '@angular/core';
import { OperationOptions } from 'retry';
import { PromiseTaskQueue, arrayBuffersEqual } from './core';

export { OperationOptions } from 'retry';

export interface Metadata {
    modificationTime: Date;
    size: number;
}

// note: API is not reentrant, wait for promises to complete before starting another
export interface FileIoWriter {
    open: (pathOrUrl: string) => Promise<void>;
    write: (data: string) => Promise<void>;
    writeBinary: (data: ArrayBuffer) => Promise<void>;
    close: () => Promise<void>;
}

/** A file with queued operations, useful for when multiple async tasks access the same file */
export class QueuedFile {
    private queue = new PromiseTaskQueue();
    constructor(
        public path: string,
        private fileIo: FileIo,
    ) {}

    read = async () => this.queue.add(() => this.fileIo.read(this.path));
    readBinary = async () => this.queue.add(() => this.fileIo.readBinary(this.path));
    write = async (data: string) => this.queue.add(() => this.fileIo.write(this.path, data));
    writeBinary = async (data: ArrayBuffer) => this.queue.add(() => this.fileIo.writeBinary(this.path, data));
    delete = async () => this.queue.add(() => this.fileIo.delete(this.path));
    rename = async (to: string) =>
        this.queue.add(async () => {
            await this.fileIo.rename(this.path, to);
            this.path = to;
        });
    copy = async (to: string) => this.queue.add(() => this.fileIo.copy(this.path, to)); /** `to` is not tracked after this call */
    readDirectory = async () => this.queue.add(() => this.fileIo.readDirectory(this.path));
    getMetadata = async () => this.queue.add(() => this.fileIo.getMetadata(this.path));
    exists = async () => this.queue.add(() => this.fileIo.exists(this.path));
    isFile = async () => this.queue.add(() => this.fileIo.isFile(this.path));
    isWritable = async () => this.queue.add(() => this.fileIo.isWritable(this.path));
    createDirRecursive = async () => this.queue.add(() => this.fileIo.createDirRecursive(this.path));
}

/**
 * Like a QueuedFile, but with memory caching, so that:
 * * Reads succeed immediately if the data is already in memory
 * * Writes are deduplicated
 */
export class MemCachedFile {
    locking: OperationOptions | undefined;
    private queue = new PromiseTaskQueue();

    private contents: ArrayBuffer | undefined;

    constructor(
        private pathInt: string,
        private fileIo: FileIo,
    ) {}

    get path() {
        return this.pathInt;
    }

    read = () =>
        this.enqueue(async () => {
            const binary = await this.readBinary();
            return new TextDecoder().decode(binary);
        });

    readBinary = () =>
        this.enqueue(async () => {
            if (!this.contents) {
                this.contents = await this.fileIo.readBinary(this.pathInt);
            }
            return this.contents;
        });

    write = (data: string) =>
        this.enqueue(async () => {
            const binary = new TextEncoder().encode(data);
            await this.writeBinary(binary);
        });

    writeBinary = (data: ArrayBuffer) =>
        this.enqueue(async () => {
            if (this.contents && arrayBuffersEqual(this.contents, data)) {
                return;
            }
            await this.fileIo.writeBinary(this.pathInt, data);
            this.contents = data;
        });

    delete = () =>
        this.enqueue(async () => {
            await this.fileIo.delete(this.pathInt);
            this.contents = undefined;
        });

    rename = async (to: string) =>
        this.enqueue(async () => {
            await this.fileIo.rename(this.pathInt, to);
            this.pathInt = to;
        });

    copy = async (to: string) => this.queue.add(() => this.fileIo.copy(this.pathInt, to));

    private enqueue = <T>(inner: () => Promise<T>) => this.queue.add(() => this.maybeLocked(inner));

    private maybeLocked = async <T>(inner: () => Promise<T>) => {
        if (this.locking) {
            const release = await this.fileIo.lock(this.pathInt, this.locking);
            try {
                return await inner();
            } finally {
                await release();
            }
        } else {
            return await inner();
        }
    };
}

@Injectable({ providedIn: 'root' })
export abstract class FileIo {
    waitReady: () => Promise<void>;

    abstract appCacheDir: string;
    abstract logDir: string;
    abstract userDir: string;

    resolveAppAsset: (relative: string) => Promise<string>;

    read: (pathOrUrl: string) => Promise<string>;
    readBinary: (pathOrUrl: string) => Promise<ArrayBuffer>;

    write: (pathOrUrl: string, data: string) => Promise<void>;
    writeBinary: (pathOrUrl: string, data: ArrayBuffer) => Promise<void>;

    createWriter: () => Promise<FileIoWriter>;

    delete: (pathOrUrl: string) => Promise<void>;

    rename: (from: string, to: string) => Promise<void>;

    copy: (from: string, to: string) => Promise<void>;

    readDirectory: (pathOrUrl: string) => Promise<string[]>;

    getMetadata: (pathOrUrl: string) => Promise<Metadata>;
    exists: (pathOrUrl: string) => Promise<boolean>;
    isFile: (pathOrUrl: string) => Promise<boolean>;
    isWritable: (pathOrUrl: string) => Promise<boolean>;

    createDirRecursive: (pathOrUrl: string) => Promise<void>;

    abstract basename(pathOrUrl: string, ext?: string): string;

    abstract joinPath(...paths: string[]): string;

    private queuedFiles = new Map<string, QueuedFile>();
    private memCachedFiles = new Map<string, MemCachedFile>();

    getQueued = (pathOrUrl: string) => {
        const file = this.queuedFiles.get(pathOrUrl) || new QueuedFile(pathOrUrl, this);
        this.queuedFiles.set(pathOrUrl, file);
        return file;
    };
    getMemCached = (pathOrUrl: string, locking?: OperationOptions) => {
        const file = this.memCachedFiles.get(pathOrUrl) || new MemCachedFile(pathOrUrl, this);
        file.locking = locking;
        this.memCachedFiles.set(pathOrUrl, file);
        return file;
    };

    abstract lock(file: string, options?: OperationOptions): Promise<() => Promise<void>>;

    abstract isNotFoundError(err: any): boolean;
}
