export interface RawItemDef {
    type?: string;
    e_type?: string; // eslint-disable-line @typescript-eslint/naming-convention
    h_type?: string; // eslint-disable-line @typescript-eslint/naming-convention
    args?: any;
    e_args?: any; // eslint-disable-line @typescript-eslint/naming-convention
    h_args?: any; // eslint-disable-line @typescript-eslint/naming-convention
}

const embeddedItemTypes = [
    'GaugeGroup',
    'ConfigurableGaugeGroup',
    'Menu',
    'PersonalityMenu',
    'ParamGauge',
    'LargeGauge',
    'GearMultiGauge',
    'LargeGearRangeGauge',
    'TCCMultiGauge',
    'DigitalGauge',
    'ScalarEdit',
    'EncodingEdit',
    'BooleanEdit',
    'TableEdit',
    'ShiftTableEdit',
    'ParamSetter',
    'ParamCopier',
    'CalibScreen',
    'FaultCodeList',
    'TextDisplay',
    'TextGauge',
    'HalfScreenTextGauge',
    'TextScreen',
];

export const hostifyItemDef = (def: RawItemDef, validTypes: string[]): any => {
    const type = def.h_type || def.type;

    if (!type) {
        return undefined;
    }

    if (validTypes.length && validTypes.indexOf(type) < 0) {
        throw new Error(type + ' is not a host item type');
    }

    const args = def.args || {};
    if (typeof def.h_args === 'object') {
        for (const key of Object.keys(def.h_args)) {
            args[key] = def.h_args[key];
        }
    }

    if (args.children && args.children.map) {
        // looks like a container
        args.children = args.children.map((d: RawItemDef) => hostifyItemDef(d, validTypes)).filter((v: any) => Boolean(v));
    }

    return {
        type,
        args,
    };
};

export const embeddifyItemDef = (def: RawItemDef): any => {
    const type = def.e_type || def.type;

    if (!type) {
        return undefined;
    }

    if (embeddedItemTypes.indexOf(type) < 0) {
        throw new Error(type + ' is not an embedded item type');
    }

    const args = def.args || {};
    if (typeof def.e_args === 'object') {
        for (const key of Object.keys(def.e_args)) {
            args[key] = def.e_args[key];
        }
    }

    if (args.children && args.children.map) {
        // looks like a container
        args.children = args.children.map(embeddifyItemDef).filter((v: any) => Boolean(v));
    }

    return {
        type,
        args,
    };
};
