import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { interval, Subscription } from "rxjs";
import { take } from "rxjs/operators";

@Component({
    selector: "app-configuration-save",
    templateUrl: "configuration-save.component.html",
})
export class ConfigurationSaveComponent implements OnDestroy, OnInit {
    fileNameToSave = "";
    fileNameUsed = false;
    @Input() chartConfigurationNames: string[] = [];
    @ViewChild("filenameForm", { static: true }) filenameForm: NgForm;
    private subscription: Subscription;

    constructor(private modalCtrlr: ModalController) {}

    ngOnInit() {
        const formSub = interval(200)
            .pipe(take(20))
            .subscribe({
                next: () => {
                    if (this.filenameForm) {
                        this.subscription =
                            this.filenameForm.form.valueChanges.subscribe({
                                next: (value) => {
                                    this.fileNameUsed =
                                        this.chartConfigurationNames.includes(
                                            value.fileNameToSave
                                        );
                                },
                            });
                        formSub.unsubscribe();
                    }
                },
            });
    }

    onBack() {
        this.modalCtrlr.dismiss().catch(console.warn);
    }

    saveConfiguration() {
        if (this.fileNameToSave && !this.fileNameUsed) {
            this.modalCtrlr.dismiss({ filename: this.fileNameToSave });
        }
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
}
