import { padStart } from 'lodash';
import { Slot } from './slot';

export class RadixSlot extends Slot {
    // range tuples are min, max
    constructor(
        private digits: number,
        private max: number,
        radix: number,
    ) {
        super('', 0, radix);
    }

    toEngrFloat = (value: number | undefined): number | undefined => value;

    toEngrString = (value: number | undefined): string | undefined => {
        if (value === undefined || isNaN(value)) {
            return undefined;
        }
        return padStart(value.toString(this.base), this.digits, '0').toUpperCase();
    };

    toRawFloat = (value: number | string | undefined): number | undefined => {
        if (typeof value === 'undefined' || (typeof value === 'number' && isNaN(value))) {
            return undefined;
        }
        return this.engrNumOrStringToNum(value);
    };

    rawInRange = (value: number | undefined): boolean => typeof value === 'number' && value >= 0 && value <= this.max;

    engrInRange = (value: number | string | undefined): boolean => {
        const engrConv: number | undefined = this.engrNumOrStringToNum(value);
        return engrConv !== undefined && engrConv >= 0 && engrConv <= this.max;
    };

    engrNumOrStringToNum = (value: number | string | undefined): any => {
        let engrConv: number | undefined;
        if (typeof value === 'string') {
            engrConv = parseInt(value, this.base);
        } else if (typeof value === 'number') {
            engrConv = value;
        }
        return engrConv;
    };

    get rawMin() {
        return 0;
    }
    get rawMax() {
        return this.max;
    }
    get rawStep() {
        return 1;
    }
    get engrMin() {
        return 0;
    }
    get engrMax() {
        return this.max;
    }
    get engrStep() {
        return 1;
    }
    get rawIsInt() {
        return true;
    }
}
