import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Log, LogFile } from '../param/core/logging-types';
import { pakoInflate } from './pako-wrapper';
import { ReadableStreamObservable } from './readable-stream-observable';
import { decodeText, splitLines } from './string-observable';

export interface MockFile {
    data: ArrayBuffer;
    name: string;
}

const asLog = () => (source: Observable<string>) =>
    new Observable<Log>((observer) => {
        const accum: Log = { header: undefined as any, kvt: [] };
        let done: boolean;

        return source.subscribe({
            next: (line) => {
                if (done) {
                    return;
                }
                const obj = JSON.parse(line);
                if (!accum.header) {
                    // look for a header, do some basic validation...
                    if (
                        !obj ||
                        typeof obj.paramDef !== 'object' ||
                        typeof obj.paramDef.params !== 'object' ||
                        typeof obj.paramDef.slots !== 'object' ||
                        typeof obj.logDate !== 'string' ||
                        typeof obj.logTime !== 'number'
                    ) {
                        observer.error({ err: 'invalidHeader', line });
                        done = true;
                    }
                    accum.header = obj;
                } else {
                    if (
                        !obj ||
                        typeof obj.key !== 'string' ||
                        (typeof obj.value !== 'string' && typeof obj.value !== 'undefined') ||
                        typeof obj.time !== 'string'
                    ) {
                        observer.error({ err: 'invalidKVT', line });
                        done = true;
                    }
                    accum.kvt.push(obj);
                }
            },
            error: (err) => {
                if (!done) {
                    if (accum.header) {
                        observer.next(accum);
                    }
                    observer.error(err);
                    done = true;
                }
            },
            complete: () => {
                if (!done) {
                    if (accum.header) {
                        observer.next(accum);
                        observer.complete();
                    } else {
                        observer.error('noHeaderPresent');
                    }
                    done = true;
                }
            },
        });
    });

const isMockFile = (file: File | MockFile): file is MockFile => !!(file as any).data;

@Injectable({ providedIn: 'root' })
export class BrowserLogFileReader {
    read = async (src: File | MockFile): Promise<LogFile> => {
        let file: LogFile['file'];
        let obs: Observable<ArrayBuffer>;
        if (isMockFile(src)) {
            obs = of(src.data);
            file = { name: src.name };
        } else {
            if (src.stream) {
                // workaround: TS confuses DOM Blob with unexported Node Blob
                const stream = src.stream() as unknown as ReadableStream<ArrayBuffer>;
                obs = new ReadableStreamObservable(stream.getReader());
            } else {
                const reader = new FileReader();
                const data = await new Promise<ArrayBuffer>((res, rej) => {
                    reader.onload = () => res(reader.result as ArrayBuffer);
                    reader.onerror = () => rej(reader.error);
                    reader.readAsArrayBuffer(src);
                });
                obs = of(data);
            }
            file = src;
        }

        const log = await obs.pipe(pakoInflate(), decodeText(), splitLines(), asLog()).toPromise();

        return { log, file };
    };
}
