import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Components } from '@ionic/core';
import Fuse from 'fuse.js';
import { sum } from 'lodash';
import { LOGGER, Logger } from '../../util/logger';
import { verticalCollapseAnimation } from '../../util/vertical-collapse-animation';

export interface CategorySelectModalItem {
    key: any;
    name: string;
    disabled?: boolean;
}

export interface CategorySelectModalCategory {
    name: string;
    items: CategorySelectModalItem[];
    expanded?: boolean;
}

export interface CategorySelectModalArgs {
    title: string;
    cancelButton?: string;
    choices: CategorySelectModalCategory[];
    initialValue?: string;
    dismissOnClick?: boolean;
    mode: 'ios' | 'md' | undefined;
    canSearch?: boolean;
}

interface SearchableItem {
    item: CategorySelectModalItem;
    category: CategorySelectModalCategory;
}

@Component({
    templateUrl: 'category-select-modal.html',
    animations: [verticalCollapseAnimation],
})
export class CategorySelectModalComponent implements OnInit {
    @Input() title: string;
    @Input() cancelButton = '';
    @Input() choices: CategorySelectModalCategory[] = [];
    @Input() canSearch: boolean;
    @Input() initialValue: string;
    @Input() dismissOnClick = false;
    @Input() mode: 'ios' | 'md' | undefined;

    visibleChoices: CategorySelectModalCategory[] = [];

    private valueInt: any;
    private searchBarActiveInt = false;
    private searchQueryInt = '';
    private fuse: Fuse<SearchableItem>;
    @ViewChild('theSearchbar', { static: true }) private searchbar: Components.IonSearchbar & { el: HTMLElement };

    constructor(
        @Inject(LOGGER) private logger: Logger,
        private modalCtrlr: ModalController,
    ) {}

    ngOnInit() {
        this.valueInt = this.initialValue;

        for (const category of this.choices) {
            if (typeof category.expanded === 'undefined') {
                category.expanded = this.choices.length === 1 || this.isSelectedCategory(category);
            }
        }

        this.canSearch = this.canSearch ?? sum(this.choices.map((c) => c.items.length)) >= 6;
        if (this.canSearch) {
            const fuseItems = this.choices
                .map((category) => category.items.map((item) => ({ item, category })))
                .reduce((a, b) => a.concat(b), []);
            this.fuse = new Fuse(fuseItems, {
                shouldSort: true,
                findAllMatches: true,
                threshold: 0.6,
                location: 0,
                distance: 100,
                minMatchCharLength: 1,
                keys: [
                    { name: 'item.name', weight: 0.7 },
                    { name: 'category.name', weight: 0.3 },
                ],
            });
        }
        this.visibleChoices = this.choices;
    }

    get searchBarActive() {
        return this.searchBarActiveInt;
    }

    set searchBarActive(value) {
        if (this.searchBarActiveInt !== value) {
            this.searchBarActiveInt = value;
            if (value) {
                let attempts = 30; // on Electron, doesn't work the first few times
                const focus = () => {
                    this.searchbar.setFocus();
                    const isFocused = this.searchbar.el.contains(document.activeElement);
                    if (!isFocused && attempts > 0) {
                        --attempts;
                        window.requestAnimationFrame(focus);
                    }
                };
                window.requestAnimationFrame(focus);
            }
        }
    }

    get value() {
        return this.valueInt;
    }

    set value(v) {
        this.valueInt = v;
        if (this.dismissOnClick) {
            this.modalCtrlr.dismiss(v, undefined, 'CategorySelectModalComponent').catch(this.logger.warn);
        }
    }

    get searchQuery() {
        return this.searchQueryInt;
    }

    set searchQuery(value) {
        if (value === this.searchQueryInt) {
            return;
        }

        this.searchQueryInt = value;

        if (!value) {
            this.visibleChoices = this.choices;
        } else {
            this.visibleChoices = [
                {
                    name: '',
                    expanded: true,
                    items: this.fuse.search(value).map((result) => ({
                        ...result.item.item,
                        name: result.item.item.name + (result.item.category.name ? ` (${result.item.category.name})` : ''),
                    })),
                },
            ];
        }
    }

    onSearchBarToggled = () => {
        if (this.searchBarActive) {
            this.searchQuery = '';
        }
        this.searchBarActive = !this.searchBarActive;
    };

    onSearchBarCancel = () => {
        this.searchQuery = '';
        this.searchBarActive = false;
    };

    onSearchBarKeyDown = (ev: KeyboardEvent) => {
        if (ev && ev.key === 'Escape') {
            this.searchQuery = '';
            this.searchBarActive = false;
        }
    };

    getCategoryLines(category: CategorySelectModalCategory) {
        if (category.expanded && category.items.length) {
            return 'inset';
        }
        if (this.choices.indexOf(category) < this.choices.length - 1) {
            return 'full';
        }
        return 'none';
    }

    getItemLines(category: CategorySelectModalCategory, item: CategorySelectModalItem) {
        if (category.items.indexOf(item) < category.items.length - 1) {
            return 'inset';
        }
        if (this.choices.indexOf(category) < this.choices.length - 1) {
            return 'full';
        }
        return 'none';
    }

    isSelectedCategory(category: CategorySelectModalCategory) {
        return category.items.some((item) => item.key === this.valueInt);
    }

    cancel() {
        this.modalCtrlr.dismiss(undefined, undefined, 'CategorySelectModalComponent').catch(this.logger.warn);
    }
}
