import { Component, ElementRef, NgZone, ViewChild } from "@angular/core";
import {
    AlertController,
    LoadingController,
    Platform,
    ToastController,
} from "@ionic/angular";
import { filter, first } from "rxjs/operators";
import packageInfo from "../../../package.json";
import { LogFile } from "../../hgm-setup/src/module/param/core/logging-types";
import { BrowserLogFileReader } from "../../hgm-setup/src/module/util/browser-log-file-reader";
import {
    safeErrorString,
    truthyOnly,
} from "../../hgm-setup/src/module/util/core";
import { LogFileProvider } from "../../hgm-setup/src/module/util/log-file-provider";
import { DataProvider } from "../../providers/data-provider";
import { SignalGroupProvider } from "../../providers/signal-group-provider";
import { UpdateProvider } from "../../providers/update-provider";
import { TabChartsPageComponent } from "../tab-charts/tab-charts.page";
import { sampleData } from "./sample-data";

const tabNames = ["charts", "signals", "configurations", "settings"] as const;
type TabNameType = (typeof tabNames)[number];

@Component({
    selector: "app-tabs",
    templateUrl: "tabs.page.html",
})
export class TabsPageComponent {
    isMobile = false;
    isBottomShrunk = false;
    isLoading = false;
    fileInputAccept: string;
    chartsTabHeight = 0;
    bottomPaddingHeight = 0;

    private tabNameInt: TabNameType = "charts";
    @ViewChild(TabChartsPageComponent, { static: true })
    private chartsTab: TabChartsPageComponent;
    @ViewChild("fileInput", { static: true })
    private fileInput: ElementRef<HTMLInputElement>;
    @ViewChild("bottomInner", { static: true })
    private bottomInner: ElementRef<HTMLElement>;
    private resizeObserver: ResizeObserver;

    constructor(
        private alertCtrlr: AlertController,
        private dataProvider: DataProvider,
        private signalGroupProvider: SignalGroupProvider,
        private element: ElementRef<HTMLElement>,
        private loadingController: LoadingController,
        private logFileProvider: LogFileProvider,
        private logReader: BrowserLogFileReader,
        private zone: NgZone,
        platform: Platform,
        private toastController: ToastController,
        private updateProvider: UpdateProvider
    ) {
        this.isMobile = platform.is("mobile");
        this.fileInputAccept = platform.is("ios")
            ? "application/octet-stream"
            : ".hjl"; // WKWebView still doesn't support extensions, wtf?
        this.resizeObserver = new ResizeObserver(this.onResize);
    }

    ngOnInit() {
        this.resizeObserver.observe(this.element.nativeElement);
        this.resizeObserver.observe(this.bottomInner.nativeElement);
        window.addEventListener("resize", this.onResize); // iOS doesn't fire resize observer on screen rotation
    }

    ngOnDestroy() {
        this.resizeObserver.disconnect();
        window.removeEventListener("resize", this.onResize);
    }

    get showViewfinder() {
        return this.tabName === "charts" && !!this.dataProvider.data;
    }

    get tabName() {
        return this.tabNameInt;
    }

    set tabName(v) {
        if (v === this.tabNameInt) {
            return;
        }
        this.tabNameInt = v;
    }

    ngAfterViewInit() {
        if (sampleData) {
            this.logReader
                .read({ data: sampleData, name: "sample" })
                .then(
                    (result) => this.logFileProvider.load(result),
                    console.error
                );
        }
    }

    onDragover(event: DragEvent) {
        event.preventDefault();
    }

    onDrop(event: DragEvent) {
        event.preventDefault();
        if (event.dataTransfer) {
            this.onFileList(event.dataTransfer.files).catch(console.error);
        }
    }

    onFileClick() {
        this.fileInput.nativeElement.value = "";
        this.fileInput.nativeElement.click();
    }

    onFileInputChange(_: Event) {
        this.signalGroupProvider.appliedConfigurationFileName = undefined;
        this.onFileList(this.fileInput.nativeElement.files).catch(
            console.error
        );
    }

    applyChartConfiguration() {
        this.tabName = "charts";
    }

    onViewfinderIsUpdating(isUpdating: boolean) {
        this.chartsTab.viewfinderIsUpdating = isUpdating;
    }

    get versionString() {
        return `HGM Charts ${packageInfo.version}`;
    }

    get updateAvailable() {
        return (
            this.updateProvider.available?.available.hash !==
            this.updateProvider.available?.current.hash
        );
    }

    async showAbout() {
        const alert = await this.alertCtrlr.create({
            header: "About HGM Charts",
            message: `Version ${packageInfo.version}<br><br>Copyright &copy; 2020 HGM Automotive Electronics, Inc.<br>All rights reserved`,
        });
        await alert.present();
    }

    async onFileList(files: FileList | null) {
        if (!files || files.length === 0) {
            return;
        }
        if (files.length > 1) {
            const toast = await this.toastController.create({
                buttons: [{ role: "cancel", text: "OK" }],
                duration: 3000,
                message: "Loading multiple files at once is not supported.",
                translucent: true,
            });
            await toast.present();
            return;
        }

        const loading = await this.loadingController.create({
            spinner: null,
            message: "Please wait, loading…",
        });
        await loading.present();
        this.isLoading = true;
        let file: LogFile;
        try {
            file = await this.logReader.read(files[0]);
            await this.logFileProvider.load(file);
        } catch (err) {
            console.error(err);
            await loading.dismiss();
            const message = safeErrorString(err);
            const toast = await this.toastController.create({
                buttons: [{ role: "cancel", text: "OK" }],
                header: `Error loading ${files[0].name}`,
                message,
                translucent: true,
            });
            await toast.present();
            this.isLoading = false;
            return;
        }

        const dataResult = await this.dataProvider.resultObs
            .pipe(
                truthyOnly(),
                filter((r) => r.file === file),
                first()
            )
            .toPromise();
        await loading.dismiss();
        if (!dataResult.paramSet) {
            console.error(dataResult.error);
            const message = safeErrorString(dataResult.error);
            const toast = await this.toastController.create({
                buttons: [{ role: "cancel", text: "OK" }],
                header: `Error loading ${files[0].name}`,
                message,
                translucent: true,
            });
            await toast.present();
        }
        this.isLoading = false;
    }

    get fileName() {
        return this.logFileProvider.file &&
            this.logFileProvider.parseResult &&
            !this.logFileProvider.parseResult.err
            ? this.logFileProvider.file.file.name
            : undefined;
    }

    get appliedConfigurationFileName() {
        return this.signalGroupProvider.appliedConfigurationFileName;
    }

    private onResize = () =>
        this.zone.run(() => {
            this.bottomPaddingHeight =
                this.bottomInner.nativeElement.offsetHeight;
            this.chartsTabHeight =
                this.element.nativeElement.offsetHeight -
                this.bottomPaddingHeight;
        });
}
