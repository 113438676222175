import { animate, state, style, transition, trigger } from '@angular/animations';

export const verticalCollapseAnimation = trigger('verticalCollapse', [
    state(
        'no',
        style({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'transform-origin': 'top',
            height: '*',
            transform: 'scaleY(1)',
        }),
    ),
    state(
        'yes',
        style({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'transform-origin': 'top',
            height: 0,
            transform: 'scaleY(0)',
        }),
    ),
    transition('* => *', window.matchMedia('(prefers-reduced-motion').matches ? [] : [animate('250ms ease-in-out')]),
]);
