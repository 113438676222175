import { Component } from '@angular/core';
import { LogFileProvider } from '../../hgm-setup/src/module/util/log-file-provider';
import { GlobalSettingsProvider } from '../../providers/global-settings-provider';
import { UpdateProvider } from '../../providers/update-provider';
import { safeErrorString } from '../../hgm-setup/src/module/util/core';
import { AlertController } from '@ionic/angular';

@Component({
    selector: 'app-tab-settings',
    templateUrl: 'tab-settings.page.html'
})
export class TabSettingsPageComponent {
    constructor(
        private alertCtrlr: AlertController,
        public settings: GlobalSettingsProvider,
        private fileProvider: LogFileProvider,
        private updateProvider: UpdateProvider,
    ) { }

    get isUnitSelectionReady() {
        return this.fileProvider.parseResult && !this.fileProvider.parseResult.err;
    }

    onUnitSelectionChange() {
        this.settings.onUnitSelectionChange();
    }

    get preferredCoarseWidth() {
        return this.settings.preferredCoarseWidth / 1000;
    }

    set preferredCoarseWidth(v) {
        if (v >= 1) {
            this.settings.preferredCoarseWidth = v * 1000;
        }
        else {
            this.settings.preferredCoarseWidth = 1000;
        }
    }

    get preferredFineWidth() {
        return this.settings.preferredFineWidth / 1000;
    }

    set preferredFineWidth(v) {
        if (v >= 1) {
            this.settings.preferredFineWidth = v * 1000;
        }
        else {
            this.settings.preferredFineWidth = 1000;
        }
    }

    get alwaysShowCoarse() {
        return this.settings.alwaysShowCoarse;
    }

    set alwaysShowCoarse(v) {
        this.settings.alwaysShowCoarse = v;
    }

    get updatesSupported() {
        return this.updateProvider.isSupported;
    }

    get updateCheckError() {
        if (this.updateProvider.checkResult && this.updateProvider.checkResult.err) {
            return safeErrorString(this.updateProvider.checkResult.err);
        }
        return '';
    }

    get lastCheckTime() {
        if (this.updateProvider.checkResult) {
            return this.updateProvider.checkResult.time.toLocaleString();
        }
        return '';
    }

    get lastSuccessfulCheckTime() {
        if (this.updateProvider.lastSuccessfulCheck) {
            return this.updateProvider.lastSuccessfulCheck.toLocaleString();
        }
        return '';
    }

    get updateAvailable() {
        return this.updateProvider.available?.available.hash !== this.updateProvider.available?.current.hash;
    }

    async applyUpdate() {
        if (!this.updateProvider.checkInProgress) {
            try {
                await this.updateProvider.checkForUpdate();
            }
            catch (err) {
                console.warn(err);
            }
        }

        try {
            await this.updateProvider.restartToApply();
        }
        catch (err) {
            const alert = await this.alertCtrlr.create({
                header: 'Applying update failed',
                message: `${safeErrorString(err)}<br>Try closing and reopening the app.`,
            });
            await alert.present();
        }
    }

    get updateCheckInProgress() {
        return this.updateProvider.checkInProgress;
    }

    checkForUpdate() {
        this.updateProvider.checkForUpdate().catch(() => { }); // will be notified separately
    }
}
