import { Injectable, ApplicationRef } from "@angular/core";
import {
    SwUpdate,
    UpdateAvailableEvent,
    UpdateActivatedEvent,
} from "@angular/service-worker";
import { BehaviorSubject, Subscription, timer } from "rxjs";
import { first } from "rxjs/operators";

export interface UpdateCheckResult {
    time: Date;
    err?: any;
}

@Injectable({ providedIn: "root" })
export class UpdateProvider {
    checkInterval = 3600 * 1000;
    checkInProgress = false;
    isSupported = true;

    private availableSubj = new BehaviorSubject<
        UpdateAvailableEvent | undefined
    >(undefined);
    private activatedSubj = new BehaviorSubject<
        UpdateActivatedEvent | undefined
    >(undefined);
    private checkResultSubj = new BehaviorSubject<
        UpdateCheckResult | undefined
    >(undefined);
    private lastSuccessfulCheckSubj = new BehaviorSubject<Date | undefined>(
        undefined
    );
    private updateCheckSub: Subscription | undefined;

    constructor(appRef: ApplicationRef, private swUpdate: SwUpdate) {
        swUpdate.available.subscribe({
            next: (v) => this.availableSubj.next(v),
        });
        swUpdate.activated.subscribe({
            next: (v) => this.activatedSubj.next(v),
        });

        appRef.isStable
            .pipe(first((v) => v))
            .toPromise()
            .then(this.checkForUpdate, console.error);
    }

    get available() {
        return this.availableSubj.value;
    }
    get availableObs() {
        return this.availableSubj.asObservable();
    }

    get activated() {
        return this.activatedSubj.value;
    }
    get activatedObs() {
        return this.activatedSubj.asObservable();
    }

    get checkResult() {
        return this.checkResultSubj.value;
    }
    get checkResultObs() {
        return this.checkResultSubj.asObservable();
    }

    get lastSuccessfulCheck() {
        return this.lastSuccessfulCheckSubj.value;
    }
    get lastSuccessfulCheckObs() {
        return this.lastSuccessfulCheckSubj.asObservable();
    }

    checkForUpdate = async () => {
        if (this.checkInProgress) {
            return;
        }

        if (this.updateCheckSub) {
            this.updateCheckSub.unsubscribe();
            this.updateCheckSub = undefined;
        }
        this.checkInProgress = true;
        try {
            await this.swUpdate.checkForUpdate();
            const time = new Date();
            this.lastSuccessfulCheckSubj.next(time);
            this.checkResultSubj.next({ time });
            this.isSupported = true;
        } catch (err) {
            this.isSupported =
                !err ||
                err.message !==
                    "Service workers are disabled or not supported by this browser";
            console.warn(err);
            this.checkResultSubj.next({ time: new Date(), err });
        }
        this.checkInProgress = false;
        this.updateCheckSub = timer(this.checkInterval).subscribe({
            next: this.checkForUpdate,
        });
    };

    async restartToApply() {
        await this.swUpdate.activateUpdate();
        document.location.reload();
    }
}
