import { Slot } from './slot';

export class EncodingSlot extends Slot {
    private rawToEngr: Map<number, string>;
    private engrToRaw: Map<string, number> = new Map();
    private engrToIndex: Map<string, number> = new Map();
    private encodingStringListInt: Array<string>;
    private rawMinInt = Infinity;
    private rawMaxInt = -Infinity;

    constructor(
        encodingList: Array<[number, string]>,
        private unencodedSlot: Slot | void,
        public oorRaw: number | undefined,
        public oorFloat: number = NaN,
        public oorString: string = '',
    ) {
        super(
            typeof unencodedSlot === 'undefined' ? '' : unencodedSlot.unit,
            typeof unencodedSlot === 'undefined' ? 0 : unencodedSlot.precision,
            typeof unencodedSlot === 'undefined' ? 10 : unencodedSlot.base,
        );
        this.rawToEngr = new Map(encodingList);
        this.encodingStringListInt = new Array(encodingList.length);
        let i = 0;
        for (const entry of encodingList) {
            this.engrToRaw.set(entry[1], entry[0]);
            this.encodingStringListInt[i] = entry[1];
            this.engrToIndex.set(entry[1], i);
            this.rawMinInt = Math.min(this.rawMinInt, entry[0]);
            this.rawMaxInt = Math.max(this.rawMaxInt, entry[0]);
            ++i;
        }
    }

    get encodingStringList(): Array<string> {
        return this.encodingStringListInt;
    }

    toEngrFloat = (value: number | undefined): number | undefined => {
        if (typeof value === 'number') {
            if (this.rawToEngr.has(value) || !this.unencodedSlot) {
                return value;
            } else {
                return this.unencodedSlot.toEngrFloat(value);
            }
        } else {
            return undefined;
        }
    };

    toEngrString = (value: number | undefined): string | undefined => {
        if (typeof value === 'number') {
            if (this.rawToEngr.has(value)) {
                return this.rawToEngr.get(value);
            } else if (this.unencodedSlot) {
                return this.unencodedSlot.toEngrString(value);
            } else {
                return this.oorString;
            }
        } else {
            return undefined;
        }
    };

    toRawFloat = (value: number | string | undefined): number | undefined => {
        if (typeof value === 'string' && this.engrToRaw.has(value)) {
            return this.engrToRaw.get(value);
        } else if (this.unencodedSlot) {
            return this.unencodedSlot.toRaw(value);
        } else if (value !== undefined && !isNaN(+value)) {
            return +value;
        } else {
            return this.oorRaw;
        }
    };

    rawInRange = (value: number | undefined): boolean => {
        if (typeof value === 'number') {
            if (this.rawToEngr.has(value)) {
                return true;
            } else if (this.unencodedSlot) {
                return this.unencodedSlot.rawInRange(value);
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    engrInRange = (value: number | string | undefined): boolean => {
        if (typeof value === 'number') {
            if (this.rawToEngr.has(value)) {
                return true;
            } else if (this.unencodedSlot) {
                return this.unencodedSlot.engrInRange(value);
            } else {
                return false;
            }
        } else if (typeof value === 'string') {
            if (this.engrToRaw.has(value)) {
                return true;
            } else if (this.unencodedSlot) {
                return this.unencodedSlot.engrInRange(value);
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    get rawMin() {
        if (this.unencodedSlot) {
            return Math.min(this.unencodedSlot.rawMin, this.rawMinInt);
        } else {
            return this.rawMinInt;
        }
    }
    get rawMax() {
        if (this.unencodedSlot) {
            return Math.max(this.unencodedSlot.rawMax, this.rawMaxInt);
        } else {
            return this.rawMaxInt;
        }
    }
    get rawStep() {
        if (this.unencodedSlot) {
            return Math.min(this.unencodedSlot.rawStep, 1); // NOTE: could fail if unencoded slot step not an integer fraction of 1
        } else {
            return 1;
        }
    }
    get engrMin() {
        if (typeof this.unencodedSlot !== 'undefined') {
            return Math.min(this.unencodedSlot.engrMin, this.rawMinInt);
        } else {
            return this.rawMinInt;
        }
    }
    get engrMax() {
        if (this.unencodedSlot) {
            return Math.max(this.unencodedSlot.engrMax, this.rawMaxInt);
        } else {
            return this.rawMaxInt;
        }
    }
    get engrStep() {
        if (this.unencodedSlot) {
            return Math.min(this.unencodedSlot.engrStep, 1); // NOTE: could fail if unencoded slot step not an integer fraction of 1
        } else {
            return 1;
        }
    }
    get rawIsInt() {
        return true;
    }
}
