import { range } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { arrayLikeEqual } from '../../util/core';
import { ParamLayerParam } from '../services/param-layer-param';
import { ParamAclDef, ParamDef } from '../util/param-system-def';
import { nullSlot } from './null-slot';
import { DataType, Slot, getDataTypeLength, getTypedViewData, setTypedViewData } from './slot';
// A Param represents a single range of memory on the target. It tracks a version known to exist on the target (i.e. cache)
// and a version presented to the user.

// Example of creating a params list from TS:
// const PARAMS: Param[] = [
//     new ScalarParam('fnordInfundibulationSpeed', true, true, true, SLOTS['FurlongsPerFortnight'].subscribe, 0x12345678, DataType.Int32)
// ]
// The SLOT subscription must give an initial value (BehaviorSubject)

export type ParamAcl = ParamAclDef;
export { ParamEffectiveAcl } from '../util/param-system-def';

export type ParamAdvice = ParamDef['advice'];

export abstract class Param implements ParamLayerParam {
    abstract minSize: number;
    abstract maxSize: number;
    abstract size: number | undefined;
    abstract targetSize: number | undefined;
    abstract value: unknown;
    abstract valueObs: Observable<unknown>;

    private dataTypeSizeInt: number;
    private hostBuffer: ArrayBuffer;
    private targetBuffer: ArrayBuffer;
    private hostByteArray: Uint8Array;
    private targetByteArray: Uint8Array;
    private hostDataView: DataView;
    private hostBytesLoaded: Array<boolean>;
    private targetBytesLoaded: Array<boolean>;
    private numHostBytesLoadedInt: number;
    private numHostBytesLoadedSubject: BehaviorSubject<number>;
    protected numTargetBytesLoaded: number;
    private writeCacheDirtySubject: BehaviorSubject<boolean>;
    private existsOnHostSubject: BehaviorSubject<boolean>;
    private existsOnTargetSubject: BehaviorSubject<boolean | undefined>;

    constructor(
        public key: string,
        public acl: ParamAcl,
        public advice: ParamAdvice | undefined,
        public fullReload: boolean,
        private slotInt: Slot | void,
        public addr: any,
        public dataType: [DataType, boolean], // dataType second member is isLittleEndian
        private maxBytes: number,
    ) {
        this.dataTypeSizeInt = getDataTypeLength(dataType[0]);
        this.hostBuffer = new ArrayBuffer(maxBytes);
        this.targetBuffer = new ArrayBuffer(maxBytes);
        this.hostByteArray = new Uint8Array(this.hostBuffer);
        this.targetByteArray = new Uint8Array(this.targetBuffer);
        this.hostDataView = new DataView(this.hostBuffer);
        this.hostBytesLoaded = new Array<boolean>(maxBytes);
        this.hostBytesLoaded.fill(false);
        this.targetBytesLoaded = new Array<boolean>(maxBytes);
        this.targetBytesLoaded.fill(false);
        this.numHostBytesLoadedInt = 0;
        this.numHostBytesLoadedSubject = new BehaviorSubject(this.numHostBytesLoadedInt);
        this.numTargetBytesLoaded = 0;
        this.writeCacheDirtySubject = new BehaviorSubject<boolean>(false);
        this.existsOnHostSubject = new BehaviorSubject<boolean>(false);
        this.existsOnTargetSubject = new BehaviorSubject<boolean | undefined>(undefined);
    }

    get numHostBytesLoaded(): number {
        return this.numHostBytesLoadedInt;
    }

    get numHostBytesLoadedObs(): Observable<number> {
        return this.numHostBytesLoadedSubject.asObservable();
    }

    get slot(): Slot {
        return this.slotInt || nullSlot;
    }

    abstract getSerializableValue(): any;
    abstract setSerializableValue(value: any): boolean;
    abstract getSerializableStringValue(): any;
    abstract getSerializableRawValue(): any;
    abstract setSerializableRawValue(value: any): boolean;

    get writeCacheDirtyObs(): Observable<boolean> {
        return this.writeCacheDirtySubject.asObservable();
    }
    get writeCacheDirty(): boolean {
        return this.writeCacheDirtySubject.getValue();
    }

    get existsOnHostObs(): Observable<boolean> {
        return this.existsOnHostSubject.asObservable();
    }
    get existsOnHost(): boolean {
        return this.existsOnHostSubject.getValue();
    }
    set existsOnHost(value: boolean) {
        if (value === this.existsOnHostSubject.getValue()) {
            return;
        }

        if (value) {
            // forcing param to exist on host, probably for testing
            this.setHostBytes(new Uint8Array(this.minSize), 0, true); // make min size and fill with zeros
        } else {
            this.setHostBytes(new Uint8Array(0), 0, true);
        }
        this.existsOnHostSubject.next(value);
    }

    get existsOnTargetObs(): Observable<boolean | void> {
        return this.existsOnTargetSubject.asObservable();
    }
    get existsOnTarget(): boolean | undefined {
        return this.existsOnTargetSubject.getValue();
    }

    get hostBytes(): Uint8Array {
        return this.hostByteArray;
    }

    get hostArrayBuffer(): ArrayBuffer {
        return this.hostBuffer;
    }

    get changedBytes(): [number, number] {
        let begin = 0;
        let end: number = this.maxBytes;

        while (
            begin !== end &&
            (!this.hostBytesLoaded[end - 1] ||
                (this.hostBytesLoaded[end - 1] &&
                    this.targetBytesLoaded[end - 1] &&
                    this.hostByteArray[end - 1] === this.targetByteArray[end - 1]))
        ) {
            --end;
        }

        while (
            begin !== end &&
            (!this.hostBytesLoaded[begin] ||
                (this.hostBytesLoaded[begin] && this.targetBytesLoaded[begin] && this.hostByteArray[begin] === this.targetByteArray[begin]))
        ) {
            ++begin;
        }

        return [
            Math.floor(begin / this.dataTypeSizeInt) * this.dataTypeSizeInt,
            Math.ceil(end / this.dataTypeSizeInt) * this.dataTypeSizeInt,
        ];
    }

    setWriteCacheDirty() {
        if (!this.writeCacheDirtySubject.value) {
            this.writeCacheDirtySubject.next(true);
        }
    }

    // param layer calls this
    setTargetBytes(
        data: Uint8Array,
        offset: number,
        eraseExisting: boolean = false,
        preserveHost: boolean = false,
        forceMutating: boolean = false,
    ): void {
        if (data.length + offset > this.maxBytes) {
            throw new Error('Supplied data to setTargetBytes overflows array');
        }

        const preserveHostMask = range(offset, offset + data.length).map(
            (i) => this.targetBytesLoaded[i] && this.hostBytesLoaded[i] && this.targetByteArray[i] !== this.hostByteArray[i],
        );

        this.targetByteArray.set(data, offset);

        this.targetBytesLoaded.fill(true, offset, offset + data.length);
        if (eraseExisting) {
            this.targetBytesLoaded.fill(false, offset + data.length, this.maxBytes);
            this.numTargetBytesLoaded = offset + data.length; // heuristic to minimize work by loop below
        }
        if (this.numTargetBytesLoaded >= offset && this.numTargetBytesLoaded < offset + data.length) {
            this.numTargetBytesLoaded = offset + data.length; // heuristic to minimize work by loop below
        }
        while (this.numTargetBytesLoaded < this.maxBytes && this.targetBytesLoaded[this.numTargetBytesLoaded]) {
            ++this.numTargetBytesLoaded;
        }

        const newExistsOnTarget: boolean = this.numTargetBytesLoaded >= this.minSize;
        if (newExistsOnTarget !== this.existsOnTargetSubject.getValue()) {
            this.existsOnTargetSubject.next(newExistsOnTarget);
        }

        if (preserveHost) {
            const hostByteSlice = this.hostByteArray.slice(0, this.numHostBytesLoadedInt);
            const targetByteSlice = this.targetByteArray.slice(0, this.numTargetBytesLoaded);
            this.writeCacheDirtySubject.next(hostByteSlice.length > 0 && !arrayLikeEqual(hostByteSlice, targetByteSlice));
        } else {
            this.setHostBytes(data, offset, eraseExisting, this.acl.mutating || forceMutating ? undefined : preserveHostMask);
        }
    }

    clearTargetBytes(preserveHost: boolean = false) {
        this.targetBytesLoaded.fill(false, 0, this.maxBytes);
        this.numTargetBytesLoaded = 0;
        this.existsOnTargetSubject.next(undefined);
        if (!preserveHost) {
            this.setHostBytes(new Uint8Array(0), 0, true);
        }
    }

    get dataTypeSize(): number {
        return this.dataTypeSizeInt;
    }

    get isResetAdvised() {
        return this.advice === 'resetAdvised' || this.advice === 'confirmThenReset' || this.advice === 'confirmThenResetVariation';
    }

    get isPersonalityParam() {
        return this.advice === 'confirmThenReset' || this.advice === 'confirmThenResetVariation';
    }

    protected abstract updateFromHostBytes(begin: number, end: number): void;

    protected getHostData(offset: number): number | undefined {
        if ((offset + 1) * this.dataTypeSizeInt > this.numHostBytesLoadedInt) {
            return undefined;
        }
        return this.getArrayData(this.hostDataView, offset);
    }

    protected setHostData(value: number, offset: number = 0): boolean {
        if ((offset + 1) * this.dataTypeSizeInt > this.maxSize) {
            return false;
        }
        this.setHostBytes(this.makeArrayData([value]), offset * this.dataTypeSizeInt);
        return true;
    }

    protected setHostDataArray(data: Array<number>, offset: number = 0): boolean {
        if ((data.length + offset) * this.dataTypeSizeInt > this.maxSize) {
            return false;
        }
        this.setHostBytes(this.makeArrayData(data), offset * this.dataTypeSizeInt);
        return true;
    }

    protected clearHostDataFrom(offset: number): boolean {
        if (offset * this.dataTypeSizeInt > this.maxSize) {
            return false;
        }
        this.hostBytesLoaded.fill(false, offset * this.dataTypeSizeInt, this.maxBytes);
        const prevHostBytesLoaded: number = this.numHostBytesLoadedInt;
        this.numHostBytesLoadedInt = Math.min(this.numHostBytesLoadedInt, offset * this.dataTypeSizeInt);
        if (prevHostBytesLoaded !== this.numHostBytesLoadedInt) {
            this.updateFromHostBytes(prevHostBytesLoaded, this.numHostBytesLoadedInt);
            this.numHostBytesLoadedSubject.next(this.numHostBytesLoadedInt);
        }
        return true;
    }

    // preserveMask members must only be true if corresponding host byte is already loaded
    private setHostBytes(data: Uint8Array, offset: number, eraseExisting: boolean = false, preserveMask?: Array<boolean>): void {
        if (data.length + offset > this.maxBytes) {
            throw new Error('Supplied data to setHostBytes overflows array');
        }
        if (preserveMask && preserveMask.length !== data.length) {
            throw new Error('Mismatched data and preserveMask');
        }

        const prevHostBytesLoaded = this.numHostBytesLoadedInt;

        let changed = false;

        if (eraseExisting) {
            if (this.numHostBytesLoadedInt > 0) {
                changed = true;
            }
            this.hostBytesLoaded.fill(false);
            this.numHostBytesLoadedInt = 0;
        }

        if (!arrayLikeEqual(data, this.hostByteArray.slice(offset, offset + data.length))) {
            changed = true;
            if (preserveMask) {
                for (let i = 0; i < data.length; ++i) {
                    if (!preserveMask[i]) {
                        this.hostByteArray[i + offset] = data[i];
                    }
                }
            } else {
                this.hostByteArray.set(data, offset);
            }
        }

        for (let i = offset; i !== offset + data.length; ++i) {
            if (!this.hostBytesLoaded[i]) {
                changed = true;
                this.hostBytesLoaded[i] = true;
            }
        }

        let newExistsOnHost = false;
        if (changed) {
            if (this.numHostBytesLoadedInt >= offset && this.numHostBytesLoadedInt < offset + data.length) {
                this.numHostBytesLoadedInt = offset + data.length; // heuristic to minimize work by loop below
            }
            while (this.numHostBytesLoadedInt < this.maxBytes && this.hostBytesLoaded[this.numHostBytesLoadedInt]) {
                ++this.numHostBytesLoadedInt;
            }
            newExistsOnHost = this.numHostBytesLoadedInt >= this.minSize;
        }

        if (changed && newExistsOnHost !== this.existsOnHostSubject.getValue()) {
            this.existsOnHostSubject.next(newExistsOnHost);
            this.updateFromHostBytes(0, Math.max(offset + data.length, prevHostBytesLoaded));
        } else {
            // always update, in case UI has non-canonical representation
            this.updateFromHostBytes(offset, offset + data.length);
        }

        const hostByteSlice = this.hostByteArray.slice(0, this.numHostBytesLoadedInt);
        const targetByteSlice = this.targetByteArray.slice(0, this.numTargetBytesLoaded);
        this.writeCacheDirtySubject.next(hostByteSlice.length > 0 && !arrayLikeEqual(hostByteSlice, targetByteSlice));
        this.numHostBytesLoadedSubject.next(this.numHostBytesLoadedInt);
    }

    private getArrayData(dataView: DataView, offset: number): number {
        return getTypedViewData(dataView, this.dataType[0], this.dataType[1], offset);
    }

    private makeArrayData(value: Array<number>): Uint8Array {
        const array = new ArrayBuffer(value.length * this.dataTypeSizeInt);
        const dataView = new DataView(array);
        for (let offset = 0; offset < value.length; ++offset) {
            setTypedViewData(dataView, this.dataType[0], this.dataType[1], offset, value[offset]);
        }
        return new Uint8Array(array);
    }
}
